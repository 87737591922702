import React, { useEffect, useState } from "react";
import TrainMessage from "../Components/TrainMessage";
import LoadingSpinner from "../Components/LoadingSpinner";
import { getFilter, saveFilter, TRV_APIURL } from "../Utils/Common";
import AdComponent from "../Components/AdComponent";

export default function TrainMessages({ locationList }) {
  const [messages, setMessages] = useState([]);
  const [showAllMessages, setshowAllMessages] = useState(
    getFilter() === "false" ? false : true
  );
  const [isLoading, setLoading] = useState(false);
  const [hasErrors, setErrors] = useState();

  document.title = `Aktuella trafikmeddelanden`;

  
  useEffect(() => {
    let intervalId;
    async function fetchMessages() {
      setLoading(true);
  
      const messagesQuery = `
        <REQUEST>
          <LOGIN authenticationkey="${process.env.REACT_APP_TRV_APIKEY}" />
          <QUERY objecttype="TrainMessage" schemaversion="1.6" orderby="LastUpdateDateTime desc" sseurl="true">
            <EXCLUDE>CountyNo</EXCLUDE>
          </QUERY>
        </REQUEST>
      `;
  
      try {
        const response = await fetch(TRV_APIURL, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "text/xml",
          },
          redirect: "follow",
          body: messagesQuery,
        });
  
        const json = await response.json();
  
        const data = json["RESPONSE"]["RESULT"]
          ? json["RESPONSE"]["RESULT"][0]["TrainMessage"]
          : null;
  
        if (!showAllMessages) {
          let filteredArray = data?.filter((msg) => {
            return msg?.ReasonCode[0]?.Description !== "banarbete";
          });
          setMessages(filteredArray);
        } else {
          setMessages(data);
        }
  
        setErrors(null);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setErrors(error);
      }
    }

    fetchMessages();
    intervalId = setInterval(() => {
      fetchMessages();
    }, 300000);

    return () => {
      clearInterval(intervalId);
    };
  }, [locationList, showAllMessages]);

  return (
    <>
      {hasErrors ? <div className="warning">{hasErrors}</div> : ""}
      <section className="card">
          <form className="flex flex-row items-center">
            <input
              type="checkbox"
              id="messageFilter"
              checked={showAllMessages}
              disabled={isLoading}
              onChange={(e) => {
                saveFilter(e.target.checked);
                setshowAllMessages(e.target.checked);
              }}
            />
            <label className="ml-2" htmlFor="messageFilter">Visa banarbeten</label>
          </form>
      </section>
      <AdComponent />
      {messages?.map((item) => (
        <TrainMessage
          key={item.EventId}
          msg={item}
          locationList={locationList}
        />
      ))}
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
}
