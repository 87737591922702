import React from "react";
import TrainListItem from "../Components/TrainListItem";

const items = [
    {
        "activityType": "Ankomst",
        "advertisedTrainIdent": "10179",
        "advertisedTimeAtLocation": "2020-08-07T17:45:00.000+02:00",
        "canceled": null,
        "isDelayed": false,
        "locationSignature": "Hpbg",
        "technicalTrainIdent": "10179",
        "timeAtLocation": "2020-08-07T17:44:00.000+02:00",
        "trainState": {
            "minutes": 1,
            "status": "",
            "color": "green"
        }
    },
    {
        "activityType": "Avgang",
        "advertisedTrainIdent": "7046",
        "advertisedTimeAtLocation": "2020-08-07T17:40:00.000+02:00",
        "canceled": null,
        "isDelayed": true,
        "locationSignature": "Aä",
        "technicalTrainIdent": "7047",
        "timeAtLocation": "2020-08-07T17:43:00.000+02:00",
        "trainState": {
            "minutes": 3,
            "status": "+",
            "color": "orange"
        }
    },
    {
        "activityType": "Ankomst",
        "advertisedTrainIdent": "10694",
        "advertisedTimeAtLocation": "2020-08-07T17:40:00.000+02:00",
        "canceled": null,
        "isDelayed": true,
        "locationSignature": "Khn",
        "technicalTrainIdent": "10694",
        "timeAtLocation": "2020-08-07T17:48:00.000+02:00",
        "trainState": {
            "minutes": 8,
            "status": "+",
            "color": "red"
        }
    },
    {
        "activityType": "Avgang",
        "advertisedTrainIdent": "20643",
        "advertisedTimeAtLocation": "2020-08-07T17:47:00.000+02:00",
        "canceled": null,
        "isDelayed": true,
        "locationSignature": "B",
        "technicalTrainIdent": "20643",
        "timeAtLocation": "2020-08-07T17:56:00.000+02:00",
        "trainState": {
            "minutes": 9,
            "status": "+",
            "color": "red"
        }
    },
    {
        "activityType": "Avgang",
        "advertisedTrainIdent": "28034",
        "advertisedTimeAtLocation": "2020-08-07T17:57:00.000+02:00",
        "canceled": true,
        "isDelayed": false,
        "locationSignature": "K",
        "technicalTrainIdent": "28033",
        "timeAtLocation": "2020-08-07T17:55:00.000+02:00",
        "trainState": {
            "minutes": 2,
            "status": "",
            "color": "green"
        }
    }
]

export default function TrainList() {
  return (
    <section className="card">
      Tåglista
      
        <p>Visa en lista över tågnummer att bevaka</p>
        <form>
          <div>
            <label className="block font-semibold" htmlFor="">Tågnummer</label>
            <input type="numeric" placeholder="Tågnummer (00000)" />
          </div>
        </form>
        <h5>Bevakningslista</h5>
        {items.map((item) => (
            <TrainListItem item={item} />
        ))}
      
    </section>
  );
}
