import React, { useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, CircleMarker, Polyline, Tooltip } from "react-leaflet";
import { getThemeMode } from "../Utils/Common"

// Set default map center
const mapDefaultCenter = {
  lat: 62.3875,
  lng: 16.325556,
};

const mapDefaultZoom = 6;

// Page
export default function TrainMap({
  mapBounds,
  mapCenter,
  trainBearing,
  trainStatus,
  pathCoordinates,
  trainMarker,
}) {
  const [setMap] = useState(null);
  const lightModeMapStyle = "https://api.mapbox.com/styles/v1/jewenson/ckfi5gu4c0hlh19nvehdvrgqf/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiamV3ZW5zb24iLCJhIjoiY2tidGp3Y2xmMGFyNDJybGNuZGZqZW1uNyJ9.yXdCo-Spk2F-YSSbmCKCBg";
  const darkModeMapStyle = "https://api.mapbox.com/styles/v1/jewenson/cljq4g3f900z401pjhb1i5hbs/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiamV3ZW5zb24iLCJhIjoiY2tidGp3Y2xmMGFyNDJybGNuZGZqZW1uNyJ9.yXdCo-Spk2F-YSSbmCKCBg";
  let theme = getThemeMode()

  const mapStyle = theme === 'dark' ? darkModeMapStyle : lightModeMapStyle;

  return pathCoordinates && mapBounds ? (
    <div className="map" style={{ height: 536 }}>
      <MapContainer
        center={mapCenter ? mapCenter : mapDefaultCenter}
        zoom={mapDefaultZoom}
        scrollWheelZoom={true}
        zoomControl={true}
        whenCreated={setMap}
        whenReady={e => {
          e.target.flyTo(mapCenter);
          e.target.fitBounds(mapBounds)
        }}
        style={{ height: 536 }}
      >
        <TileLayer
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
          url={mapStyle}
        />
        <Polyline positions={pathCoordinates} />
        {trainMarker && (
          <>
            <CircleMarker center={trainMarker} pathOptions={{ color: trainStatus?.textColor ?? "grey" }} radius={5}>
              <Tooltip permanent direction={trainBearing < 90 || trainBearing > 270 ? "bottom" : "top"} offset={trainBearing < 90 || trainBearing > 270 ? [0,10] : [0, -10]}>
                <div className="infoWindow">
                  <span>
                    {trainStatus.activity === "Ankomst" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke={trainStatus?.textColor}
                        className="infoWindowIcon"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="infoWindowIcon"
                        viewBox="0 0 20 20"
                        fill={trainStatus?.textColor}
                        style={{ transform: `rotate(${trainBearing}deg)` }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                    <p className="leaflet-container-text">
                      {trainStatus?.activity === "Ankomst" ? "Ankom" : "Avgick"}&nbsp;
                      {trainStatus?.locationName}&nbsp;
                      {trainStatus?.minutes < 0 ? trainStatus?.minutes : `+${trainStatus?.minutes}`}
                      <br />
                      {new Date(trainStatus?.timeAtLocation).toLocaleTimeString()}
                    </p>
                  </span>
                </div>
              </Tooltip>
            </CircleMarker>
          </>
        )}
      </MapContainer>
    </div>
  ) : (
    <div className="overflow-hidden">
      <div className="mapWindowHeader">
        <h3>Hittade ingen kartdata.</h3>
      </div>
    </div>
  );
}
