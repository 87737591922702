import React from "react";
import { Link } from "react-router-dom";
import AdComponent from "../Components/AdComponent";

export default function Calculations() {
  document.title = `Uträkningar`;
  return (
    <>
    <section className="card">
        <h1>Uträkningar</h1>
        <Link to="/uppgift" className="not-prose font-semibold">
          Uppgift till förare
        </Link>
        <p className="mt-0">Använd tågvikt, bromsvikt, tåglängd och information om EP-broms och tågsättets största tillåtna hastighet för att få fram bromsprocent, retardationstal samt STH för samtliga bantyper.</p>
        <Link to="/brpsth" className="not-prose font-semibold">
          Bromsprocent till STH
        </Link>
        <p className="mt-0">Använd bromsprocent och tåglängd för att få fram retardationstal samt STH för samtliga bantyper.</p>
        <Link to="/retsth" className="not-prose font-semibold">
          Retardationstal till STH
        </Link>
        <p className="mt-0">Använd retardationstal och tåglängd för att få fram bromsprocent samt STH för samtliga bantyper.</p>
    </section>
    <AdComponent />
    </>
  );
}
