import React from "react";
import { useRef } from "react";
import { useState } from "react";
import Results from "../Components/Results";
import AdComponent from "../Components/AdComponent";

export default function Utf() {
  const [isSubmitted, setSubmitted] = useState(false);
  const [userInput, setUserInput] = useState({});

  const trainWeightRef = useRef();
  const brakeWeightRef = useRef();
  const trainLengthRef = useRef();
  const trainVMaxRef = useRef();
  const epBrakeRef = useRef();

  async function submitHandler(event) {
    event.preventDefault();
    const input = {
      dataType: "utf",
      trainWeight: Number(trainWeightRef.current.value),
      brakeWeight: Number(brakeWeightRef.current.value),
      trainLength: Number(trainLengthRef.current.value),
      vMax:
        trainVMaxRef.current.value !== null || trainVMaxRef.current.value !== ""
          ? Number(trainVMaxRef.current.value)
          : 0,
      epBrake: epBrakeRef.current.checked,
    };

    setUserInput(input);
    setSubmitted(true);
    window.scrollTo(0, 0);
  }

  // Set document title
  document.title = `Räkna fram uppgift till förare`;

  return (
    <>
      {isSubmitted && (
        <>
          <Results data={userInput} />
          <AdComponent />
        </>
      )
      }
      <section className="card">
        <h1 className="mb-0">Uppgift till förare</h1>
        <p className="text-xs mt-1 mb-3">Skriv in dina tåguppgifter nedanför, endast rena siffror.</p>

        <form onSubmit={submitHandler} className="flex flex-col">
          <label className="block font-semibold" htmlFor="">
            Tågvikt:
          </label>
          <input
            type="number"
            name="trainWeight"
            id="trainWeight"
            className="form-control"
            placeholder="Tågvikt i ton (endast siffror)"
            ref={trainWeightRef}
            required
          />
          <label className="block font-semibold" htmlFor="">
            Bromsvikt:
          </label>
          <input
            type="number"
            name="brakeWeight"
            id="brakeWeight"
            className="form-control"
            placeholder="Bromsvikt i ton (endast siffror)"
            ref={brakeWeightRef}
            required
          />
          <label className="block font-semibold" htmlFor="">
            Tåglängd:
          </label>
          <input
            type="number"
            name="trainLength"
            id="trainLength"
            className="form-control"
            placeholder="Tåglängd i meter (endast siffror)"
            ref={trainLengthRef}
            required
          />
          <label className="block font-semibold" htmlFor="">
            Långsammaste fordon (km/h):
          </label>
          <input
            type="number"
            name="vMax"
            id="vMax"
            className="form-control"
            placeholder="Hastighet i km/h (endast siffror)"
            ref={trainVMaxRef}
          />
          <div className="flex items-center mx-3 mt-3 w-full">
            <input type="checkbox" name="epBrake" id="epBrake" ref={epBrakeRef} />
            <label htmlFor="epBrake" className="ml-2 font-semibold">
              EP-broms eller motsvarande
            </label>
          </div>
          <button type="submit" className="">
            Räkna ut!
          </button>
        </form>
      </section>
      <AdComponent />
    </>
  );
}
