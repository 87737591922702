import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TrainMessage from "../Components/TrainMessage";
import LoadingSpinner from "../Components/LoadingSpinner";
import { TRV_APIURL } from "../Utils/Common";
import AdComponent from "../Components/AdComponent";

function SingleEvent({ locationList }) {
  let { event } = useParams();
  const [eventId] = useState(event);

  // Set up data fetching
  const [isLoading, setLoading] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({ messages: [] });

  async function fetchData(event) {
    setLoading(true);
    const messagesQuery = `
      <REQUEST>
        <LOGIN authenticationkey="${process.env.REACT_APP_TRV_APIKEY}" />
        <QUERY objecttype="TrainMessage" schemaversion="1.6" orderby="LastUpdateDateTime desc" sseurl="true">
          <FILTER>
            <EQ name="EventId" value="${event}" />
          </FILTER>
          <EXCLUDE>CountyNo</EXCLUDE>
        </QUERY>
      </REQUEST>
    `;

    try {
      const response = await fetch(TRV_APIURL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "text/xml",
        },
        redirect: "follow",
        body: messagesQuery,
      });

      const json = await response.json();

      const data = json["RESPONSE"]["RESULT"]
        ? json["RESPONSE"]["RESULT"][0]["TrainMessage"]
        : null;

      setData({ messages: data });

      setErrors(null);
    } catch (error) {
      console.error(error);
      setErrors(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData(eventId);
  }, [eventId]);

  document.title = `Trafikmeddelande ${eventId}`;

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : ""}
      <AdComponent />
      {data.messages.map((item) => (
        <TrainMessage
          key={item.EventId}
          msg={item}
          locationList={locationList}
        />
      ))}
      {hasError ? <p>{hasError.message}</p> : ""}
    </div>
  );
}

export default SingleEvent;
