import React from 'react'
import { getLongDateFormat } from '../Utils/Common'

export default function LastUpdateInfo({dateTime}) {
    return (
        <div className="text-gray-500 dark:text-gray-500">
            <small>Senast uppdaterat: {getLongDateFormat(dateTime)}</small>
        </div>
    )
}
