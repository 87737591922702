import React, { useEffect, useState } from "react";

export default function LocationListView({ locationList }) {
  const [locations, setLocations] = useState([]);
  const [hasErrors, setErrors] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLocations(locationList);
    setErrors("");
    setLoading(false);
    return () => {};
  }, [locationList]);
  return (
    <>
      {hasErrors ? (
        <div className="warning">
          {hasErrors.message}
        </div>
      ) : (
        ""
      )}
      <form className="my-3">
        <div className="align-items-center">
          <label className="block font-semibold" htmlFor="">Plats</label>
          <input
            type="search"
            id="locationSearchBar"
            className="mr-sm-2"
            placeholder="Sök drifplats"
            disabled={isLoading}
          />
        </div>
      </form>
      <section className="card">
        <table borderless responsive>
          <thead>
            <tr>
              <th>Signatur</th>
              <th>Fullständigt namn</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((row) => (
              <tr key={row.locationSignature}>
                <td>{row.locationSignature}</td>
                <td>{row.advertisedLocationName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  );
}
