import React, { useEffect, useState } from "react";
import ContactForm from "../Components/ContactForm";

function AboutPage() {
  const [pageContent, setPageContent] = useState([]);

  useEffect(() => {
    // Fetch page content from admin.utf.nu
    async function fetchContent() {
      try {
        const response = await fetch(
          "https://admin.utf.nu/wp-json/wp/v2/pages/63",
        );
        const data = await response.json();
        if (data) {
          setPageContent(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchContent();
  }, []);

  document.title = `Om Uppgift till förare`;

  return pageContent?.title ? (
    <>
      <section className="card">
        <h1>{pageContent?.title?.rendered}</h1>
        <article
          dangerouslySetInnerHTML={{ __html: pageContent?.content?.rendered }}
        ></article>
      </section>
      <ContactForm />
    </>
  ) : (
    <section className="card">
      <h1>Om UTF.nu</h1>
      <p>Fel uppstod vid hämtning av innehåll.</p>
    </section>
  );
}

export default AboutPage;
