import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getThemeMode, saveThemeMode } from "../Utils/Common";

export default function PageNav() {
  const [showMenu, setShowMenu] = useState(false);
  const [theme, setTheme] = useState(getThemeMode());

  function setDarkMode() {
    saveThemeMode("dark");
    setTheme("dark");
    document.documentElement.classList.add("dark");
  }

  function setLightMode() {
    saveThemeMode("light");
    setTheme("light");
    document.documentElement.classList.remove("dark");
  }

  // Handle dark mode toggler
  function darkModeHandler() {
    if (document.documentElement.classList.contains("dark")) {
      setLightMode();
    } else {
      setDarkMode();
    }
  }

  useEffect(() => {
    if (theme === "dark") {
      setDarkMode();
    } else {
      setLightMode();
    }
  }, [theme]);

  return (
    <>
      <a href="#search-box" className="skip-to-search bg-violet-700 hover:bg-violet-800 text-white">Till sökrutan</a>
      <nav className="px-4 py-2">
        <div className="container flex flex-wrap justify-between items-center content-center max-w-6xl mx-auto">
          <Link
            to="/"
            onClick={() => setShowMenu(false)}
            className="text-lg lg:text-2xl font-semibold text-violet-900 hover:text-violet-700 dark:text-white dark:hover:text-violet-300 transition ease-in-out duration-100 whitespace-nowrap"
          >
            Uppgift till Förare
          </Link>
          <button
            id="menuButton"
            data-collapse-toggle="mobile-menu"
            type="button"
            className="inline-flex justify-center items-center ml-3 text-gray-900 rounded-lg md:hidden hover:text-gray-600 focus:outline-none focus:ring-0 focus:ring-transparent dark:text-gray-200 dark:hover:text-gray-400 dark:focus:ring-transparent"
            aria-controls="mobile-menu"
            aria-expanded="false"
            onClick={() => setShowMenu(!showMenu)}
          >
            <span className="sr-only">Öppna menyn</span>
            {showMenu ? (
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                id="closeButton"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            )}
          </button>
          <div
            className={
              showMenu
                ? "w-full md:block md:w-auto transition ease-in-out duration-100"
                : "hidden w-full md:block md:w-auto transition ease-in-out duration-100"
            }
            id="menu"
          >
            <ul className="flex flex-col mt-4 md:flex-row space-x-0 md:space-x-2 lg:space-x-6 space-y-4 md:space-y-0 md:mt-0 md:text-sm md:font-medium">
              <li>
                <Link
                  to="/rakna"
                  className="block py-2 pr-4 pl-3 md:pl-0 font-semibold text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                  onClick={() => setShowMenu(false)}
                >
                  Uträkningar
                </Link>
                <ul className="md:hidden pl-3 space-y-4">
                  <li>
                    <Link
                      to="/uppgift"
                      className="block py-2 pr-4 pl-3 md:pl-0 text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                      onClick={() => setShowMenu(false)}
                    >
                      Uppgift till förare
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/brpsth"
                      className="block py-2 pr-4 pl-3 md:pl-0 text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                      onClick={() => setShowMenu(false)}
                    >
                      Bromsprocent till STH
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/retsth"
                      className="block py-2 pr-4 pl-3 md:pl-0 text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                      onClick={() => setShowMenu(false)}
                    >
                      Retardationstal till STH
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/trafikinfo"
                  className="block py-2 pr-4 pl-3 md:pl-0 font-semibold text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                  onClick={() => setShowMenu(false)}
                >
                  Trafikinformation
                </Link>
                <ul className="md:hidden pl-6 space-y-4">
                  <li>
                    <Link
                      to="/tl"
                      className="block py-2 pr-4 pl-3 md:pl-0 text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                      onClick={() => setShowMenu(false)}
                    >
                      Sök station
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ts"
                      className="block py-2 pr-4 pl-3 md:pl-0 text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                      onClick={() => setShowMenu(false)}
                    >
                      Sök tåg
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/msg"
                      className="block py-2 pr-4 pl-3 md:pl-0 text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                      onClick={() => setShowMenu(false)}
                    >
                      Trafikmeddelanden
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/om"
                  className="block py-2 pr-4 pl-3 md:pl-0 font-semibold text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                  onClick={() => setShowMenu(false)}
                >
                  Om tjänsten
                </Link>
              </li>
              <li>
                <Link
                  to="/kontakt"
                  className="block py-2 pr-4 pl-3 md:pl-0 font-semibold text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white transition ease-in-out duration-100"
                  onClick={() => setShowMenu(false)}
                >
                  Kontakt
                </Link>
              </li>
              <li>
                <Link to="/swish">
                  <div className="block w-full md:w-auto py-2 px-3 text-center bg-violet-700 hover:bg-violet-800 dark:bg-violet-700 dark:hover:bg-violet-600 text-white font-semibold rounded-xl transition ease-in-out duration-200">
                    Donera
                  </div>
                </Link>
              </li>
              <li>
                <button
                  className="flex flex-row content-center items-center justify-center text-gray-700 hover:text-violet-700 dark:text-gray-400 dark:hover:text-white w-full md:w-auto px-2 py-2 bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 md:bg-transparent md:dark:bg-transparent md:dark:hover:bg-transparent rounded-xl md:rounded-full transition ease-in-out duration-200"
                  id="darkModeButton"
                  onClick={darkModeHandler}
                >
                  {theme === "dark" ? (
                    <svg
                      id="sunIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                  ) : (
                    <svg
                      id="moonIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                      />
                    </svg>
                  )}
                  <span className="ml-3 md:hidden">Ljust/Mörkt läge</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
