export default function VMaxGM(trainLength, brakePercentage) {
  // Set up vMax (Maximum velocity) as 0 as a fall back
  let vMax = 0;

  // Brake table GM, used by Malmtågen
  if (trainLength <= 880) {
    if (brakePercentage < 25) {
      vMax = 0;
    } else if (brakePercentage >= 25 && brakePercentage <= 30) {
      vMax = 40;
    } else if (brakePercentage >= 31 && brakePercentage <= 38) {
      vMax = 50;
    } else if (brakePercentage >= 39 && brakePercentage <= 63) {
      vMax = 60;
    } else if (brakePercentage >= 64 && brakePercentage <= 81) {
      vMax = 70;
    } else if (brakePercentage >= 82) {
      vMax = 80;
    }
  }

  return vMax;
}
