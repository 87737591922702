import React from "react";

export default function NewsPost({ news }) {
  const postDate = new Date(news.date);
  return (
    <article>
      <h3 className="mb-0">{news?.title?.rendered}</h3>
      <small className="text-gray-700 dark:text-gray-300">Uppdaterat {postDate.toLocaleDateString()}</small>
      <div dangerouslySetInnerHTML={{__html: news?.content?.rendered}}></div>
      {news?.author === 1 ? (
        <p><em>&#47;&#47;	Joakim</em></p>
      ) : (<></>)
      }
      <hr />
    </article>
  );
}
