import React from "react";
import { getShortDateFormat } from "../Utils/Common";

export default function TrainMessageCard({ msg }) {
  return (
    <div className="card my-3">
      <h4 className="m-0 text-lg sm:text-xl">{msg?.Header}</h4>
      <p className="leading-tight"><small>Starttid: {getShortDateFormat(msg?.StartDateTime)}
      {msg?.LastUpdateDateTime && (
        <>
          <br />
          Senast uppdaterat: {getShortDateFormat(msg?.LastUpdateDateTime)}
        </>
      )}
      {msg?.PrognosticatedEndDateTimeTrafficImpact && (
        <>
          <br />
          Beräknat klart: {getShortDateFormat(msg?.PrognosticatedEndDateTimeTrafficImpact)}
        </>
      )}
      </small></p>
      <p className="text-sm sm:text-base">{msg?.ExternalDescription}</p>
    </div>
  );
}
