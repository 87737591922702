import React from "react";
import { Link } from "react-router-dom";
import { datetimeOptions, fetchLocationName } from "../Utils/Common";

export default function TrainMessage({ msg, locationList }) {
  let affectedLocations = [];

  for (var i in msg.TrafficImpact) {
    const output = {
      FromLocation: msg.TrafficImpact[i].FromLocation ? msg.TrafficImpact[i].FromLocation[0] : null,
      ToLocation: msg.TrafficImpact[i].ToLocation ? msg.TrafficImpact[i].ToLocation[0] : null,
    };
    affectedLocations.push(output);
  }

  return (
    <section className="card">
      
        <h4 className="font-bold">
          <Link to={`/msg/event/${msg.EventId}`} className="trainMessageLink">
            {msg.Header}
          </Link>
        </h4>
        <p>{msg.ExternalDescription}</p>
        <p>
          <small>
            <b>Händelseid:</b> {msg.EventId}
            <br />
            <b>Starttid:</b>&nbsp;
            {new Intl.DateTimeFormat("sv-SE", datetimeOptions).format(new Date(msg.StartDateTime))}
            <br />
            <b>Uppdaterat:</b>&nbsp;
            {new Intl.DateTimeFormat("sv-SE", datetimeOptions).format(new Date(msg.LastUpdateDateTime))}
          </small>
        </p>
        {affectedLocations && (
          <>
            <p className="mb-0">
              <b>Berör:</b>
            </p>
            {affectedLocations
              ? affectedLocations.map((loc) => (
                  <div className="bg-violet-600 text-white rounded-xl text-xs px-2 py-1 m-1 inline-block" key={loc.FromLocation + loc.ToLocation}>
                    {loc.FromLocation && loc.ToLocation
                      ? `${fetchLocationName(locationList, loc.FromLocation)} - ${fetchLocationName(
                          locationList,
                          loc.ToLocation
                        )}`
                      : loc.FromLocation
                      ? `${fetchLocationName(locationList, loc.FromLocation)}`
                      : ""}
                  </div>
                ))
              : ""}
          </>
        )}
      
    </section>
  );
}
