function VMaxCitybanan(trainLength,brakePercent,epBrake)
{
    // Initiate empty vMax value
    let vMax = 0;

    // Check if EP-brake is available
    if (epBrake === true)
    {
        trainLength = 99;
    }

    // Brake chart Citybanan
    if (trainLength <= 100)
    {
        if (brakePercent <= 79)
        {
            vMax = 0;
        }
        else if (brakePercent >= 80 && brakePercent <= 87)
        {
            vMax = 50;
        }
        else if (brakePercent >= 88 && brakePercent <= 91)
        {
            vMax = 60;
        }
        else if (brakePercent >= 92 && brakePercent <= 100)
        {
            vMax = 70;
        }
        else if (brakePercent >= 101 && brakePercent <=109)
        {
            vMax = 110;
        }
        else if (brakePercent >= 110 && brakePercent <= 129)
        {
            vMax = 120;
        }
        else if (brakePercent >= 130 && brakePercent <= 134)
        {
            vMax = 160;
        }
        else if (brakePercent >= 135 && brakePercent <= 143)
        {
            vMax = 170;
        }
        else if (brakePercent >= 144)
        {
            vMax = 200;
        }
    }
    else if (trainLength >= 101 && trainLength <= 200) 
    {
        if (brakePercent <= 79)
        {
            vMax = 0;
        }
        else if (brakePercent >= 80 && brakePercent <= 87)
        {
            vMax = 50;
        }
        else if (brakePercent >= 88 && brakePercent <= 91)
        {
            vMax = 60;
        }
        else if (brakePercent >= 92 && brakePercent <= 100)
        {
            vMax = 70;
        }
        else if (brakePercent >= 101 && brakePercent <= 109)
        {
            vMax = 110;
        }
        else if (brakePercent >= 110 && brakePercent <= 129)
        {
            vMax = 120;
        }
        else if (brakePercent >= 130)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }
    else if (trainLength >= 201 && trainLength <=300)
    {
        if (brakePercent <= 79)
        {
            vMax = 0;
        }
        else if (brakePercent >= 80 && brakePercent <= 87)
        {
            vMax = 50;
        }
        else if (brakePercent >= 88 && brakePercent <= 91)
        {
            vMax = 60;
        }
        else if (brakePercent >= 92 && brakePercent <= 100)
        {
            vMax = 70;
        }
        else if (brakePercent >= 101 && brakePercent <= 109)
        {
            vMax = 110;
        }
        else if (brakePercent >= 110 && brakePercent <= 129)
        {
            vMax = 120;
        }
        else if (brakePercent >= 130 && brakePercent <= 134)
        {
            vMax = 150;
        }
        else if (brakePercent >= 135)
        {
            vMax = 160;
        }
    }
    else if (trainLength >= 301 && trainLength <= 400)
    {
        if (brakePercent <= 79)
        {
            vMax = 0;
        }
        else if (brakePercent >= 80 && brakePercent <= 81)
        {
            vMax = 40;
        }
        else if (brakePercent >= 82 && brakePercent <= 89)
        {
            vMax = 50;
        }
        else if (brakePercent >= 90 && brakePercent <= 93)
        {
            vMax = 60;
        }
        else if (brakePercent >= 94 && brakePercent <= 103)
        {
            vMax = 70;
        }
        else if (brakePercent >= 104 && brakePercent <= 109)
        {
            vMax = 110;
        }
        else if (brakePercent >= 110 && brakePercent <= 129)
        {
            vMax = 120;
        }
        else if (brakePercent >= 130 && brakePercent <= 134)
        {
            vMax = 140;
        }
        else if (brakePercent >= 135)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }
    else if (trainLength >= 401 && trainLength <= 500)
    {
        if (brakePercent <= 81) 
        {
            vMax = 0;
        }
        else if (brakePercent >= 82 && brakePercent <= 90)
        {
            vMax = 50;
        }
        else if (brakePercent >= 91 && brakePercent <= 94)
        {
            vMax = 60;
        }
        else if (brakePercent >= 95 && brakePercent <= 104)
        {
            vMax = 70;
        }
        else if (brakePercent >= 105 && brakePercent <=109)
        {
            vMax = 110;
        }
        else if (brakePercent >= 110 && brakePercent <= 129)
        {
            vMax = 120;
        }
        else if (brakePercent >= 130 && brakePercent <= 143)
        {
            vMax = 140;
        }
        else if (brakePercent >= 144)
        {
            vMax = 160;
        }
        else {
            vMax = 0;
        }
    }
    else if (trainLength >= 501 && trainLength <= 600)
    {
        if (brakePercent <= 84)
        {
            vMax = 0;
        }
        else if (brakePercent >= 85 && brakePercent <= 89)
        {
            vMax = 40;
        }
        else if (brakePercent >= 90 && brakePercent <= 94)
        {
            vMax = 50;
        }
        else if (brakePercent >= 95 && brakePercent <= 97)
        {
            vMax = 60;
        }
        else if (brakePercent >= 98 && brakePercent <= 111)
        {
            vMax = 70;
        }
        else if (brakePercent >= 112 && brakePercent <=118)
        {
            vMax = 110;
        }
        else if (brakePercent >= 119 && brakePercent <= 121)
        {
            vMax = 120;
        }
        else 
        {
            vMax = 0;
        }
    }
    else if (trainLength >= 601 && trainLength <= 730)
    {
        if (brakePercent <= 94)
        {
            vMax = 0;
        }
        else if (brakePercent >= 95 && brakePercent <= 115)
        {
            vMax = 40;
        }
        else if (brakePercent === 116)
        {
            vMax = 50;
        }
        else if (brakePercent >= 117 && brakePercent <= 121)
        {
            vMax = 110;
        }
        else {
            vMax = 0;
        }
    }

    else
    {
        vMax = 0;
    }

    // Return vMax from the function
    return vMax;
}

export default VMaxCitybanan;