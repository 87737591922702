function DecelerationToBrakePercent(inputDecelerationFigure) {
    // Set up empty int for the brake percentage
    let brakePercentage = 0;
    let decelerationFigure = parseInt(inputDecelerationFigure);

    // Use switch to translate deceleration number (m/s2) to brake percentage
    // as per defined tables
    switch (decelerationFigure)
    {
        case 21:
            brakePercentage = 18;
            break;
        case 22:
            brakePercentage = 19;
            break;
        case 23:
            brakePercentage = 20;
            break;
        case 24:
            brakePercentage = 22;
            break;
        case 25:
            brakePercentage = 23;
            break;
        case 26:
            brakePercentage = 25;
            break;
        case 27:
            brakePercentage = 26;
            break;
        case 28:
            brakePercentage = 28;
            break;
        case 29:
            brakePercentage = 29;
            break;
        case 30:
            brakePercentage = 31;
            break;
        case 31:
            brakePercentage = 32;
            break;
        case 32:
            brakePercentage = 33;
            break;
        case 33:
            brakePercentage = 35;
            break;
        case 34:
            brakePercentage = 36;
            break;
        case 35:
            brakePercentage = 38;
            break;
        case 36:
            brakePercentage = 40;
            break;
        case 37:
            brakePercentage = 41;
            break;
        case 38:
            brakePercentage = 42;
            break;
        case 39:
            brakePercentage = 44;
            break;
        case 40:
            brakePercentage = 45;
            break;
        case 41:
            brakePercentage = 47;
            break;
        case 42:
            brakePercentage = 48;
            break;
        case 43:
            brakePercentage = 50;
            break;
        case 44:
            brakePercentage = 51;
            break;
        case 45:
            brakePercentage = 53;
            break;
        case 46:
            brakePercentage = 54;
            break;
        case 47:
            brakePercentage = 55;
            break;
        case 48:
            brakePercentage = 57;
            break;
        case 49:
            brakePercentage = 58;
            break;
        case 50:
            brakePercentage = 59;
            break;
        case 51:
            brakePercentage = 61;
            break;
        case 52:
            brakePercentage = 63;
            break;
        case 53:
            brakePercentage = 65;
            break;
        case 54:
            brakePercentage = 66;
            break;
        case 55:
            brakePercentage = 67;
            break;
        case 56:
            brakePercentage = 69;
            break;
        case 57:
            brakePercentage = 70;
            break;
        case 58:
            brakePercentage = 72;
            break;
        case 59:
            brakePercentage = 73;
            break;
        case 60:
            brakePercentage = 75;
            break;
        case 61:
            brakePercentage = 76;
            break;
        case 62:
            brakePercentage = 78;
            break;
        case 63:
            brakePercentage = 79;
            break;
        case 64:
            brakePercentage = 80;
            break;
        case 65:
            brakePercentage = 82;
            break;
        case 66:
            brakePercentage = 83;
            break;
        case 67:
            brakePercentage = 85;
            break;
        case 68:
            brakePercentage = 86;
            break;
        case 69:
            brakePercentage = 88;
            break;
        case 70:
            brakePercentage = 90;
            break;
        case 71:
            brakePercentage = 91;
            break;
        case 72:
            brakePercentage = 92;
            break;
        case 73:
            brakePercentage = 94;
            break;
        case 74:
            brakePercentage = 95;
            break;
        case 75:
            brakePercentage = 97;
            break;
        case 76:
            brakePercentage = 98;
            break;
        case 77:
            brakePercentage = 100;
            break;
        case 78:
            brakePercentage = 101;
            break;
        case 79:
            brakePercentage = 103;
            break;
        case 80:
            brakePercentage = 104;
            break;
        case 81:
            brakePercentage = 105;
            break;
        case 82:
            brakePercentage = 107;
            break;
        case 83:
            brakePercentage = 108;
            break;
        case 84:
            brakePercentage = 110;
            break;
        case 85:
            brakePercentage = 112;
            break;
        case 86:
            brakePercentage = 113;
            break;
        case 87:
            brakePercentage = 114;
            break;
        case 88:
            brakePercentage = 116;
            break;
        case 89:
            brakePercentage = 117;
            break;
        case 90:
            brakePercentage = 119;
            break;
        case 91:
            brakePercentage = 120;
            break;
        case 92:
            brakePercentage = 122;
            break;
        case 93:
            brakePercentage = 123;
            break;
        case 94:
            brakePercentage = 125;
            break;
        case 95:
            brakePercentage = 126;
            break;
        case 96:
            brakePercentage = 127;
            break;
        case 97:
            brakePercentage = 129;
            break;
        case 98:
            brakePercentage = 130;
            break;
        case 99:
            brakePercentage = 132;
            break;
        case 100:
            brakePercentage = 133;
            break;
        case 101:
            brakePercentage = 135;
            break;
        case 102:
            brakePercentage = 137;
            break;
        case 103:
            brakePercentage = 138;
            break;
        case 104:
            brakePercentage = 139;
            break;
        case 105:
            brakePercentage = 141;
            break;
        case 106:
            brakePercentage = 142;
            break;
        case 107:
            brakePercentage = 144;
            break;
        case 108:
            brakePercentage = 145;
            break;
        case 109:
            brakePercentage = 147;
            break;
        case 110:
            brakePercentage = 148;
            break;
        case 111:
            brakePercentage = 150;
            break;
        case 112:
            brakePercentage = 151;
            break;
        case 113:
            brakePercentage = 152;
            break;
        case 114:
            brakePercentage = 154;
            break;
        case 115:
            brakePercentage = 155;
            break;
        case 116:
            brakePercentage = 157;
            break;
        case 117:
            brakePercentage = 159;
            break;
        case 118:
            brakePercentage = 160;
            break;
        case 119:
            brakePercentage = 161;
            break;
        case 120:
            brakePercentage = 163;
            break;
        case 121:
            brakePercentage = 164;
            break;
        case 122:
            brakePercentage = 166;
            break;
        case 123:
            brakePercentage = 167;
            break;
        case 124:
            brakePercentage = 169;
            break;
        case 125:
            brakePercentage = 170;
            break;
        default:
            brakePercentage = 0;
            break;
        }

    return brakePercentage;
}

export default DecelerationToBrakePercent;