import React from "react";

export default function TrainNextStop({ nextStopData }) {
  const { data } = nextStopData;
  console.log(data)
  return (
    <>
      {data?.LocationSignature && (
        <>
          <h3 className="m-0">Nästa uppehåll</h3>
          <p className="text-sm sm:text-base">
            {data.ActivityType === "Ankomst" ? "Ankommer " : "Avgår "}
            <span title={data.LocationSignature}>{data?.LocationName?.AdvertisedLocationName}</span>
            {data.TrackAtLocation ? ` spår ${data.TrackAtLocation}, ` : ""}
            {data.EstimatedTimeAtLocation
              ? ` kl ${new Date(Date.parse(data.EstimatedTimeAtLocation)).toLocaleTimeString("sv-SE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`
              : ` kl ${new Date(Date.parse(data.AdvertisedTimeAtLocation)).toLocaleTimeString("sv-SE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`}
            {data.EstimatedTimeAtLocation
              ? ` (ordinarie tid. ${new Date(Date.parse(data.AdvertisedTimeAtLocation)).toLocaleTimeString("sv-SE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })})`
              : ""}
            {data?.TrainComposition ? `` : ""}
          </p>
        </>
      )}
    </>
  );
}
