import React from "react";
import AdSense from "react-adsense";

export default function AdComponent() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return (
      <section
        style={{
          background: "pink",
          border: "5px solid red",
          borderRadius: "5px",
          width: "100%",
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>Reklamyta</p>
      </section>
    );
  } else {
    return (
      <section className="invisible-card">
        <AdSense.Google
          client="ca-pub-8866676491715179"
          slot="3880759025"
          format="auto"
          responsive="true"
        />
      </section>
    );
  }
}
