import React from "react";

export default function TrainListItem({ item }) {
  return (
    <section className="card"
      style={
        item.canceled
          ? { borderColor: "black" }
          : { borderColor: item.trainState.color }
      }
    >
      <main>
        <div className="row">
          <div className="col">
            {item.canceled ? (
              <i
                className="fas fa-exclamation-circle"
                style={{ color: "red" }}
              ></i>
            ) : item.isDelayed ? (
              <i
                className="fas fa-history"
                style={{ color: item.trainState.color }}
              ></i>
            ) : (
              <i
                className="fas fa-clock"
                style={{ color: item.trainState.color }}
              ></i>
            )}
          </div>
          <div className="col"
            style={{
              fontWeight: "bolder",
              ...(item.canceled
                ? {
                    fontStyle: "italic",
                    textDecoration: "line-through",
                    color: "red",
                  }
                : {}),
            }}
          >
            {item.advertisedTrainIdent}
          </div>
          <div className="col"
            style={{ color: item.trainState.color }}
          >
            {item.locationSignature}&nbsp;
            {item.activityType === "Ankomst" ? "*" : ""}
            {item.trainState.status}
            {item.trainState.minutes}
          </div>
          <div className="col"
            onClick={() => (alert('Hola!'))}
            style={{
              cursor: "pointer",
              ...(item.canceled
                ? { backgroundColor: "black" }
                : { backgroundColor: item.trainState.color }),
            }}
          >
            <i className="fas fa-trash" style={{ color: "#fff" }}></i>
          </div>
        </div>
      </main>
    </section>
  );
}
