import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import QR_Swish from "../Images/QR_Swish.png";
import NewsPost from "../Components/NewsPost";
import AdComponent from "../Components/AdComponent";

function Home() {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    // Fetch news from admin.utf.nu
    async function fetchNews() {
      try {
        const response = await fetch(
          "https://admin.utf.nu/wp-json/wp/v2/posts",
        );
        const data = await response.json();
        if (data) {
          setNewsList(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchNews();
  }, []);

  document.title = `Uppgift till Förare`;
  return (
    <>
      <section className="card">
        <h1>Välkommen</h1>
        <p>
          Uppgift till Förare är en tjänst som ska underlätta livet för
          lokförare, ombordpersonal och depåpersonal som arbetar i det svenska
          järnvägsnätet.
        </p>
        <p>
          Här hittar du uträkningar för att få fram det som behövs till en
          Uppgift till förare (
          <a href="https://www.trafikverket.se/for-dig-i-branschen/Arbetsmiljo-och-sakerhet/sakerhet-pa-jarnvag/trafikbestammelser-for-jarnvag--ttj/blanketter---trafikbestammelser-for-jarnvag/">
            Trafikverket blankett 26/27
          </a>
          ) samt hastighetsuppgifter för olika bantyper. Utöver det hittar du
          omvandlingar från retardationstal samt bromsprocent till
          hastighetsuppgifter för olika bantyper.
        </p>
        <p>
          Utöver uträkningar finns här även vissa trafikinformationstjänster som
          är anpassade för livet på järnvägen och som förutsätter vissa
          grundkunskaper i driftplatssignaturer med mera. Tanken är att utveckla
          dessa mer med tiden och har du som användare några tips, förslag eller
          önskemål, då kan du använda{" "}
          <Link to="/kontakt">kontaktformuläret</Link> för att förmedla dessa.
        </p>
      </section>
      <section className="card">
        <h2>Stötta Uppgift till Förare</h2>
        <Link to="/swish">
          <img
            src={QR_Swish}
            className="mx-auto d-block dark:rounded-xl"
            width="200"
            height="200"
            alt="QR-kod för att stötta UTF.nu via Swish"
          />
        </Link>
        <p>
          Vill du bidra ekonomiskt till utvecklingen av{" "}
          <b>Uppgift till Förare</b> som webbtjänst? Hobbyutvecklare behöver
          kaffe! Swisha en kopp med QR-koden ovan! Skulle det vara så att du
          föredrar <a href="https://www.paypal.me/jewenson/">Paypal</a> så går
          det fint med!
        </p>
        <p>
          Alla bidrag tas tacksamt emot och kommer{" "}
          <Link to="/swish">redovisas i sin helhet</Link> snarast möjligt.
        </p>
        <h3 className="mb-0">Utvecklare?</h3>
        <p>
          Vill du hellre bidra till utvecklingen av sidan och/eller app? Hör av
          dig via <Link to="/kontakt">kontaktformuläret</Link> och berätta om
          vad du kan/vill hjälpa till med!
        </p>
      </section>
      <AdComponent />
      <section className="card">
        <h2>Nyheter</h2>
        {newsList?.map((news) => <NewsPost key={news?.id} news={news} />) ?? (
          <p>Hittade inga nyheter att läsa in...</p>
        )}
      </section>
      <AdComponent />
      <section className="card">
        <h2>Kakor</h2>
        Webbsidan UTF.nu i sig använder sig inte av kakor i någon form. Däremot
        så gör annonstjänsten Google Adsense det så länge du inte aktivt väljer
        att inte tillåta det i din webbläsare. Vill du läsa mer om hur de
        hanterar sina kakor så gör du det enklast{" "}
        <a href="https://support.google.com/adsense/answer/7549925?hl=sv">
          via deras infosida
        </a>
        <br />
        <br />
        Kakor i sig är inget farligt och är du obekväm med det så går det att
        stänga av spårningskakor i din webbläsares inställningar.
      </section>
    </>
  );
}

export default Home;
