import React from "react";
import { Link } from "react-router-dom";
import { getDateFormat, getShortTime } from "../Utils/Common";
import DeviationTextSwitcher from "./DeviationTextSwitcher";

export default function StationBoard({ locationName, data, type }) {
  if (data !== null) {
    return (
      <section className="card my-3 overflow-auto">
        {type === "arrivals" ? (
          <h4 className="m-0">Ankomster {locationName}</h4>
        ) : (
          <h4 className="m-0">Avgångar {locationName}</h4>
        )}
        <table className="table-auto mt-3 text-xs md:text-sm xl:text-base">
          <thead>
            <tr>
              <th className="py-1 whitespace-nowrap">Tåg</th>
              <th className="py-1 px-2 whitespace-nowrap">{type === "arrivals" ? 'Från' : 'Till'}</th>
              <th className="p-1 whitespace-nowrap">Tid</th>
              <th className="p-1 whitespace-nowrap">Spår</th>
              <th className="p-1 whitespace-nowrap">Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => (
              <tr
                className="py-1 whitespace-nowrap"
                key={
                  type + row.TechnicalTrainIdent
                    ? `${row.TechnicalTrainIdent} - ${row.AdvertisedTrainIdent}`
                    : row.AdvertisedTrainIdent
                }
                style={!row.Advertised ? { fontWeight: "lighter" } : {}}
              >
                <td className="p-1 whitespace-nowrap">
                  <Link to={`/train/${row.AdvertisedTrainIdent}/${getDateFormat(row.ScheduledDepartureDateTime)}`}>
                    {row.AdvertisedTrainIdent}
                  </Link>
                  <br />
                  <small className="hidden sm:block">{row.InformationOwner}</small>&nbsp;
                </td>
                {type === "arrivals" ? (
                  <td title={row.FromLocationName.AdvertisedLocationName} className="py-1 px-2 whitespace-nowrap">
                    {row.FromLocationName !== null && row.FromLocationName !== undefined ? (
                      <Link to={`/station/${row.FromLocation[0]?.LocationName}`}>
                        {row.FromLocationName?.AdvertisedLocationName}
                      </Link>
                    ) : (
                      <Link to={`/station/${row.FromLocation[0]?.LocationName}`}>
                        {row.FromLocation[0]?.LocationName}
                      </Link>
                    )}
                  </td>
                ) : (
                  <td title={row.ToLocationName?.AdvertisedLocationName} className="py-1 px-2 whitespace-nowrap">
                    {row.FromLocationName !== null && row.FromLocationName !== undefined ? (
                      <Link to={`/station/${row.ToLocation[0]?.LocationName}`}>
                        {row.ToLocationName?.AdvertisedLocationName}
                      </Link>
                    ) : (
                      <Link to={`/station/${row.ToLocation[0]?.LocationName}`}>{row.ToLocation[0]?.LocationName}</Link>
                    )}
                  </td>
                )}
                <td className="p-1 whitespace-nowrap">
                  <div style={row.Canceled || row.EstimatedTimeAtLocation ? { textDecoration: "line-through" } : {}}>
                    {getShortTime(row.AdvertisedTimeAtLocation)}
                  </div>
                  {row.EstimatedTimeAtLocation && (
                    <div>
                      <em>{getShortTime(row.EstimatedTimeAtLocation)}</em>
                    </div>
                  )}
                </td>
                <td className="p-1 whitespace-nowrap">{row.TrackAtLocation}</td>
                <td className="p-1 whitespace-nowrap w-96">
                  <DeviationTextSwitcher
                    deviations={row.Deviation}
                    estimatedTimeAtLocation={row.EstimatedTimeAtLocation}
                    trainStatus={row.TrainStatus}
                    trainIdent={row.AdvertisedTrainIdent}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    );
  }
  return (
    <div>
      <div className="loading">
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
      </div>
    </div>
  );
}
