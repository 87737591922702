import React from "react";
import ContactForm from "../Components/ContactForm";

export default function NotFound() {
  document.title = `Sidan du sökte hittades inte`;
  return (
    <>
      <section className="card">
        <h1>Nu är någon vilse...</h1>
        <p>Det kan vara du, det kan vara jag, men det du sökte fanns visst inte?!</p>
        <p>
          Prova någon av länkarna i menyn och se om du har bättre tur? Saknas något som borde finnas, använd formuläret
          nedan för att felanmäla det hela!
        </p>
      </section>
      <ContactForm />
    </>
  );
}
