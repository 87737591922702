import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Import styles
import "./App.css";
// Import page items
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import AboutPage from "./Pages/AboutPage";
import TrainMessages from "./Pages/TrainMessages";
import Utf from "./Pages/Utf";
import RetSth from "./Pages/RetSth";
import BrpSth from "./Pages/BrpSth";
import Schedule from "./Pages/Schedule";
import Swish from "./Pages/Swish";
import SingleEvent from "./Pages/SingleEvent";
import TrainList from "./Pages/TrainList";
import NotFound from "./Pages/NotFound";
import temporaryLocations from "./Data/locations.js";
// Import Common.js
import LocationList from "./Pages/LocationListView";
import Calculations from "./Pages/Calculations";
import TrafficInfo from "./Pages/TrafficInfo";
import PageNav from "./Components/PageNav";
import TrainPage from "./Pages/TrainPage";
import StationPage from "./Pages/StationPage";
import ContactPage from "./Pages/ContactPage";

function App() {
  const [locationList, setLocationList] = useState();

  useEffect(() => {
    // Load Matomo Tag Manager
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = 'https://matomo.ewenson.se/js/container_nhL6H21J.js'; s.parentNode.insertBefore(g, s);
    // Fetch a fresh list of locations for later use
    async function fetchLocations() {
      /*
      let list = await fetchLocationList();
      setLocationList(list);
      */
      setLocationList(temporaryLocations);
    }

    fetchLocations();
    return () => { };
  }, []);

  return (
    <div>
      <BrowserRouter>
        <PageNav />
        <main>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/rakna" element={<Calculations />} />
            <Route path="/trafikinfo" element={<TrafficInfo />} />
            <Route path="/uppgift" element={<Utf />} />
            <Route path="/retsth" element={<RetSth />} />
            <Route path="/brpsth" element={<BrpSth />} />
            <Route exact path="/tl" element={<StationPage />} />
            <Route exact path="/ts" element={<TrainPage />} />
            <Route exact path="/station/:locationId/:limit?" element={<StationPage />} />
            <Route path="/train/:trainId/:searchDate?" element={<TrainPage />} />
            <Route path="/schedule/:trainId/:searchDate?" element={<Schedule />} />
            <Route path="/trainlist" element={<TrainList locationList={locationList} />} />
            <Route exact path="/msg" element={<TrainMessages locationList={locationList} />} />
            <Route exact path="/msg/event/:event" element={<SingleEvent locationList={locationList} />} />
            <Route exact path="/platser" element={<LocationList locationList={locationList} />} />
            <Route exact path="/om" element={<AboutPage />} />
            <Route exact path="/kontakt" element={<ContactPage />} />
            <Route exact path="/swish" element={<Swish />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
