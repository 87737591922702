import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TrainSchedule from "../Components/TrainTable";
import { TRV_APIURL } from "../Utils/Common";
import { scheduleCleanup } from "../Utils/ScheduleCleanup";


export default function Schedule({ locationList }) {
  // Fetch train id from url params
  let { trainId, searchDate } = useParams();

  // Set up search data state
  const [trainIdent, setTrainIdent] = useState(trainId);
  const [date, setDate] = useState(searchDate);

  // Set up data fetching
  const [isLoading, setLoading] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({ rows: [] });

  const submitHandler = (event) => {
    event.preventDefault();
    searchDate = searchDate
      ? searchDate
      : new Date().toLocaleDateString("sv-SE");

    if (trainId && searchDate) {
      window.history.pushState(
        {},
        null,
        "/train/" + trainId + "/" + searchDate
      );
    } else {
      window.history.pushState({}, null, "/train/" + trainId);
    }
    setTrainIdent(trainId);
    setDate(searchDate);
  };

  const trainIdHandler = (event) => {
    trainId = event.target.value;
  };

  const dateHandler = (event) => {
    searchDate = event.target.value;
  };

  async function newFetchData(searchTrain, searchDate) {
    setLoading(true);
    searchDate = searchDate
      ? searchDate
      : new Date().toLocaleDateString("sv-SE");

    const trainScheduleQuery = `
    <REQUEST>
      <LOGIN authenticationkey="${process.env.REACT_APP_TRV_APIKEY}" />
      <QUERY objecttype="TrainAnnouncement" schemaversion="1.6" orderby="AdvertisedTimeAtLocation, ActivityType asc">
            <FILTER>
                <AND>
                  <OR>
                      <EQ name="AdvertisedTrainIdent" value="${searchTrain}" />
                      <EQ name="TechnicalTrainIdent" value="${searchTrain}" />
                  </OR>
                  <EQ name="Advertised" value="true" />
                  <EQ name="ScheduledDepartureDateTime" value="${searchDate}" />
                </AND>
            </FILTER>
      </QUERY>
    </REQUEST>
    `;

    try {
      const response = await fetch(TRV_APIURL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "text/xml",
        },
        redirect: "follow",
        body: trainScheduleQuery,
      });
      const json = await response.json();
      const schedule = scheduleCleanup(json);

      setData({ rows: schedule });
      setErrors(null);
    } catch (error) {
      console.error(error);
      setErrors(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    let interval;
    if (trainIdent) {
      //fetchData(trainIdent, date);
      newFetchData(trainIdent, date);
      interval = setInterval(() => {
        //fetchData(trainIdent, date);
        newFetchData(trainIdent, date);
      }, 60000);
    }

    return function cleanup() {
      clearInterval(interval);
    };
  }, [trainIdent, date]);

  return (
    <div>
      {hasError ? (
        <div className="warning">
          {hasError.message}
        </div>
      ) : (
        ""
      )}
      {trainIdent && (
        <>
          <TrainSchedule
            trainId={trainIdent}
            schedule={data.rows}
            isLoading={isLoading}
            locationList={locationList}
          />
        </>
      )}
      <section className="card">
        
          <i className="fas fa-train"></i>&nbsp;Sök information om
          tåg
        
        
          <form onSubmit={submitHandler}>
            <div>
              <label htmlFor="tr">Tågnummer</label>
              <input
                type="tel"
                id="trainIdent"
                name="trainIdent"
                placeholder="Tågnummer (00000)"
                defaultValue={trainIdent}
                onChange={trainIdHandler}
                required
              />
            </div>
            <div>
              <label htmlFor="date">Datum</label>
              <input
                type="date"
                id="date"
                name="date"
                placeholder="Datum (YYYY-MM-DD)"
                defaultValue={
                  date ? date : new Date().toLocaleDateString("sv-SE")
                }
                onChange={dateHandler}
                required
              />
            </div>
            <div className="text-center mt-3">
              <button type="submit" className="" disabled={isLoading}>
                Sök tågstatus
              </button>
            </div>
          </form>
        
      </section>
    </div>
  );
}
