function VMaxA17(trainLength, brakePercentage, epBrake) {
    // Set up vMax (Maximum velocity) as 0 as a fall back
    let vMax = 0;

    // Check if EP-brake is available
    if (epBrake === true)
    {
        trainLength = 99;
    }

    // Brake table  A17, lengths up to 100 m (including EP-brake equipped vehicles)
    if (trainLength <= 100)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 66)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 67 && brakePercentage <= 82)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 83 && brakePercentage <= 99)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 100 && brakePercentage <= 121)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 122 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 149)
        {
            vMax = 130;
        }
        else if (brakePercentage >= 150)
        {
            vMax = 200;
        }
        else
        {
            vMax = 0;
        }
    }

    // Brake table A17, train lengths from 101 to 200 m
    else if (trainLength >= 101 && trainLength <= 200)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 66)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 67 && brakePercentage <= 82)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 83 && brakePercentage <= 99)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 100 && brakePercentage <= 121)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 122 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 149)
        {
            vMax = 130;
        }
        else if (brakePercentage >= 150)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }

    // Brake table A17, train lengths from 201 to 300 m
    else if (trainLength >= 201 && trainLength <= 300)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 66)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 67 && brakePercentage <= 82)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 83 && brakePercentage <= 99)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 100 && brakePercentage <= 121)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 122 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 149)
        {
            vMax = 130;
        }
        else if (brakePercentage >= 150)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }

    // Brake table A17, train lengths from 301 to 400 m
    else if (trainLength >= 301 && trainLength <= 400)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 69)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 70 && brakePercentage <= 82)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 83 && brakePercentage <= 102)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 103 && brakePercentage <= 124)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 125 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 149)
        {
            vMax = 130;
        }
        else if (brakePercentage >= 150)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }

    // Brake table A17, train lengths from 401 to 500 m
    else if (trainLength >= 401 && trainLength <= 500)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 69)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 70 && brakePercentage <= 82)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 83 && brakePercentage <= 102)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 103 && brakePercentage <= 124)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 125 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 149)
        {
            vMax = 130;
        }
        else if (brakePercentage >= 150)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }

    // Brake table A17, train lengths from 501 to 600 m
    else if (trainLength >= 501 && trainLength <= 600)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 72)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 73 && brakePercentage <= 85)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 86 && brakePercentage <= 87)
        {
            vMax = 90;
        }
        else if (brakePercentage >= 88 && brakePercentage <= 113)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 114 && brakePercentage <= 121)
        {
            vMax = 110;
        }
        else
        {
            vMax = 0;
        }
    }

    // Brake table A17, train lengths from 601 to 730 m
    else if (trainLength >= 601 && trainLength <= 730)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 75)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 76 && brakePercentage <= 90)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 91 && brakePercentage <= 93)
        {
            vMax = 90;
        }
        else if (brakePercentage >= 94 && brakePercentage <= 113)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 114 && brakePercentage <= 121)
        {
            vMax = 110;
        }
        else
        {
            vMax = 0;
        }
    }

    // Default vMax to 0 if nothing match the input
    else
    {
        vMax = 0;
    }

    return vMax;
}

export default VMaxA17;