import React from "react";
import { Link } from "react-router-dom";
import AdComponent from "../Components/AdComponent";

export default function TrafficInfo() {
  document.title = `Trafikinformationstjänster`;
  return (
    <>
    <section className="card">
      <h1 className="">Trafikinformationstjänster</h1>
      <Link to="/tl" className="not-prose font-semibold">Sök station</Link>
      <p className="mt-0">Här kan du hitta ankomst- och avgångsinformation för den driftplatssignatur du söker på.</p>
      <Link to="/ts" className="not-prose font-semibold">
        Sök tåg
      </Link>
      <p className="mt-0">Visar tidtabellsinformation för sökt tåg. Tar både annonserade och tekniska tågnummer i sökningen.</p>
      <Link to="/msg" className="not-prose font-semibold">
        Trafikmeddelanden
      </Link>
      <p className="mt-0">Visa aktuell trafikinformation och information om banarbeten som pågår i landet.</p>
    </section>
    <AdComponent />
    </>
  );
}
