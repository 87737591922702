function BrakePercentToDeceleration(brakePercent) {
  // Set up necesary variables and calculate the brake percentage out
  // from the input train weight and brake weight
  let decelerationFigure = "000";

  // Use switch to translate brake percentage to deceleration number (m/s2)
  switch (brakePercent) {
    case 18:
      decelerationFigure = "021";
      break;
    case 19:
      decelerationFigure = "022";
      break;
    case 20:
    case 21:
      decelerationFigure = "023";
      break;
    case 22:
      decelerationFigure = "024";
      break;
    case 23:
    case 24:
      decelerationFigure = "025";
      break;
    case 25:
      decelerationFigure = "026";
      break;
    case 26:
    case 27:
      decelerationFigure = "027";
      break;
    case 28:
      decelerationFigure = "028";
      break;
    case 29:
    case 30:
      decelerationFigure = "029";
      break;
    case 31:
      decelerationFigure = "030";
      break;
    case 32:
      decelerationFigure = "031";
      break;
    case 33:
    case 34:
      decelerationFigure = "032";
      break;
    case 35:
      decelerationFigure = "033";
      break;
    case 36:
    case 37:
      decelerationFigure = "034";
      break;
    case 38:
    case 39:
      decelerationFigure = "035";
      break;
    case 40:
      decelerationFigure = "036";
      break;
    case 41:
      decelerationFigure = "037";
      break;
    case 42:
    case 43:
      decelerationFigure = "038";
      break;
    case 44:
      decelerationFigure = "039";
      break;
    case 45:
    case 46:
      decelerationFigure = "040";
      break;
    case 47:
      decelerationFigure = "041";
      break;
    case 48:
    case 49:
      decelerationFigure = "042";
      break;
    case 50:
      decelerationFigure = "043";
      break;
    case 51:
    case 52:
      decelerationFigure = "044";
      break;
    case 53:
      decelerationFigure = "045";
      break;
    case 54:
      decelerationFigure = "046";
      break;
    case 55:
    case 56:
      decelerationFigure = "047";
      break;
    case 57:
      decelerationFigure = "048";
      break;
    case 58:
      decelerationFigure = "049";
      break;
    case 59:
    case 60:
      decelerationFigure = "050";
      break;
    case 61:
    case 62:
      decelerationFigure = "051";
      break;
    case 63:
    case 64:
      decelerationFigure = "052";
      break;
    case 65:
      decelerationFigure = "053";
      break;
    case 66:
      decelerationFigure = "054";
      break;
    case 67:
    case 68:
      decelerationFigure = "055";
      break;
    case 69:
      decelerationFigure = "056";
      break;
    case 70:
    case 71:
      decelerationFigure = "057";
      break;
    case 72:
      decelerationFigure = "058";
      break;
    case 73:
    case 74:
      decelerationFigure = "059";
      break;
    case 75:
      decelerationFigure = "060";
      break;
    case 76:
    case 77:
      decelerationFigure = "061";
      break;
    case 78:
      decelerationFigure = "062";
      break;
    case 79:
      decelerationFigure = "063";
      break;
    case 80:
    case 81:
      decelerationFigure = "064";
      break;
    case 82:
      decelerationFigure = "065";
      break;
    case 83:
    case 84:
      decelerationFigure = "066";
      break;
    case 85:
      decelerationFigure = "067";
      break;
    case 86:
    case 87:
      decelerationFigure = "068";
      break;
    case 88:
    case 89:
      decelerationFigure = "069";
      break;
    case 90:
      decelerationFigure = "070";
      break;
    case 91:
      decelerationFigure = "071";
      break;
    case 92:
    case 93:
      decelerationFigure = "072";
      break;
    case 94:
      decelerationFigure = "073";
      break;
    case 95:
    case 96:
      decelerationFigure = "074";
      break;
    case 97:
      decelerationFigure = "075";
      break;
    case 98:
    case 99:
      decelerationFigure = "076";
      break;
    case 100:
      decelerationFigure = "077";
      break;
    case 101:
    case 102:
      decelerationFigure = "078";
      break;
    case 103:
      decelerationFigure = "079";
      break;
    case 104:
      decelerationFigure = "080";
      break;
    case 105:
    case 106:
      decelerationFigure = "081";
      break;
    case 107:
      decelerationFigure = "082";
      break;
    case 108:
    case 109:
      decelerationFigure = "083";
      break;
    case 110:
    case 111:
      decelerationFigure = "084";
      break;
    case 112:
      decelerationFigure = "085";
      break;
    case 113:
      decelerationFigure = "086";
      break;
    case 114:
    case 115:
      decelerationFigure = "087";
      break;
    case 116:
      decelerationFigure = "088";
      break;
    case 117:
    case 118:
      decelerationFigure = "089";
      break;
    case 119:
      decelerationFigure = "090";
      break;
    case 120:
    case 121:
      decelerationFigure = "091";
      break;
    case 122:
      decelerationFigure = "092";
      break;
    case 123:
    case 124:
      decelerationFigure = "093";
      break;
    case 125:
      decelerationFigure = "094";
      break;
    case 126:
      decelerationFigure = "095";
      break;
    case 127:
    case 128:
      decelerationFigure = "096";
      break;
    case 129:
      decelerationFigure = "097";
      break;
    case 130:
    case 131:
      decelerationFigure = "098";
      break;
    case 132:
      decelerationFigure = "099";
      break;
    case 133:
    case 134:
      decelerationFigure = "100";
      break;
    case 135:
    case 136:
      decelerationFigure = "101";
      break;
    case 137:
      decelerationFigure = "102";
      break;
    case 138:
      decelerationFigure = "103";
      break;
    case 139:
    case 140:
      decelerationFigure = "104";
      break;
    case 141:
      decelerationFigure = "105";
      break;
    case 142:
    case 143:
      decelerationFigure = "106";
      break;
    case 144:
      decelerationFigure = "107";
      break;
    case 145:
    case 146:
      decelerationFigure = "108";
      break;
    case 147:
      decelerationFigure = "109";
      break;
    case 148:
    case 149:
      decelerationFigure = "110";
      break;
    case 150:
      decelerationFigure = "111";
      break;
    case 151:
      decelerationFigure = "112";
      break;
    case 152:
    case 153:
      decelerationFigure = "113";
      break;
    case 154:
      decelerationFigure = "114";
      break;
    case 155:
    case 156:
      decelerationFigure = "115";
      break;
    case 157:
    case 158:
      decelerationFigure = "116";
      break;
    case 159:
      decelerationFigure = "117";
      break;
    case 160:
      decelerationFigure = "118";
      break;
    case 161:
    case 162:
      decelerationFigure = "119";
      break;
    case 163:
      decelerationFigure = "120";
      break;
    case 164:
    case 165:
      decelerationFigure = "121";
      break;
    case 166:
      decelerationFigure = "122";
      break;
    case 167:
    case 168:
      decelerationFigure = "123";
      break;
    case 169:
      decelerationFigure = "124";
      break;
    case 170:
      decelerationFigure = "125";
      break;
    default:
      decelerationFigure = "000";
      break;
  }

  return decelerationFigure;
}

export default BrakePercentToDeceleration;
