import React from "react";
import BrakeData from "./BrakeData";
import VmaxResults from "./VmaxResults";
// Import calculation models
import BrakePercent from "../Model/UTF_CalcBrakePercent";
import BrakePercentToDecelertion from "../Model/UTF_CalcBrakePercentToDeceleration";
import DecelerationToBrakePercent from "../Model/UTF_CalcDecelerationToBrakePercent";
import VMaxA17 from "../Model/UTF_CalcVMaxA17";
import VMaxA10 from "../Model/UTF_CalcVMaxA10";
import VMaxB from "../Model/UTF_CalcVMaxB";
import VMaxC from "../Model/UTF_CalcVMaxC";
import VMaxCitybanan from "../Model/UTF_CalcVmaxCitybanan";
import VMaxGM from "../Model/UTF_CalcVMaxGM";

export default function Results({ data }) {
  let brakepercent, deceleration;

  if (data.dataType === "utf") {
    brakepercent = BrakePercent(data.trainWeight, data.brakeWeight)
      ? BrakePercent(data.trainWeight, data.brakeWeight)
      : 0;
    deceleration = BrakePercentToDecelertion(brakepercent)
      ? BrakePercentToDecelertion(brakepercent)
      : 0;
  } else if (data.dataType === "retsth") {
    brakepercent = DecelerationToBrakePercent(data.deceleration);
    deceleration = data.deceleration;
  } else if (data.dataType === "brpsth") {
    brakepercent = Number(data.brakepercent);
    deceleration = BrakePercentToDecelertion(brakepercent)
      ? BrakePercentToDecelertion(brakepercent)
      : 0;
  } else {
    brakepercent = 0;
    deceleration = 0;
  }
  const trainlength = data.trainLength ? data.trainLength : 0;
  const epbrake = data.epBrake ? data.epBrake : false;
  const vmax = data.vMax ? data.vMax : 999;

  const brakeResults = {
    deceleration: deceleration,
    brakepercent: brakepercent,
    trainlength: trainlength,
    epBrake: epbrake,
  };

  const vMaxA17 =
    VMaxA17(trainlength, brakepercent, epbrake) <= vmax
      ? VMaxA17(trainlength, brakepercent, epbrake)
      : vmax;
  const vMaxA10 =
    VMaxA10(trainlength, brakepercent, epbrake) <= vmax
      ? VMaxA10(trainlength, brakepercent, epbrake)
      : vmax;
  const vMaxB =
    VMaxB(trainlength, brakepercent, epbrake) <= vmax
      ? VMaxB(trainlength, brakepercent, epbrake)
      : vmax;
  const vMaxC =
    VMaxC(trainlength, brakepercent, epbrake) <= vmax
      ? VMaxC(trainlength, brakepercent, epbrake)
      : vmax;
  const vMaxCitybanan =
    VMaxCitybanan(trainlength, brakepercent, epbrake) <= vmax
      ? VMaxCitybanan(trainlength, brakepercent, epbrake)
      : vmax;
  const vMaxGM = VMaxGM(trainlength, brakepercent);

  const vmaxResults = {
    vmaxA17: vMaxA17,
    vmaxA10: vMaxA10,
    vmaxB: vMaxB,
    vmaxC: vMaxC,
    vmaxCitybanan: vMaxCitybanan,
    vmaxGM: vMaxGM,
  };

  return (
    <div>
      <BrakeData res={brakeResults} />
      <VmaxResults res={vmaxResults} />
    </div>
  );
}
