function VMaxB(trainLength, brakePercentage, epBrake) {
    // Set up vMax (Maximum velocity) as 0 as a fall back
    let vMax = 0;

    // Check if EP-brake is available
    if (epBrake === true)
    {
        trainLength = 99;
    }

    // Brake table B, lengths up to 100 m (including EP-brake equipped vehicles)
    if (trainLength <= 100)
    {
        if (brakePercentage >= 61 && brakePercentage <= 64)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 65 && brakePercentage <= 72)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 73 && brakePercentage <= 74)
        {
            vMax = 90;
        }
        else if (brakePercentage >= 75 && brakePercentage <= 85)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 86 && brakePercentage <= 109)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 110 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 134)
        {
            vMax = 160;
        }
        else if (brakePercentage >= 135 && brakePercentage <= 143)
        {
            vMax = 170;
        }
        else if (brakePercentage >= 144)
        {
            vMax = 200;
        }
        else
        {
            vMax = 0;
        }
    }


    // Brake table B, train lengths from 101 to 200 m
    else if (trainLength >= 101 && trainLength <= 200)
    {
        if (brakePercentage >= 61 && brakePercentage <= 64)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 65 && brakePercentage <= 72)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 73 && brakePercentage <= 74)
        {
            vMax = 90;
        }
        else if (brakePercentage >= 75 && brakePercentage <= 85)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 86 && brakePercentage <= 109)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 110 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }


    // Brake table B, train lengths from 201-300 m
    else if (trainLength >= 201 && trainLength <= 300)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 66)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 67 && brakePercentage <= 72)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 73 && brakePercentage <= 74)
        {
            vMax = 90;
        }
        else if (brakePercentage >= 75 && brakePercentage <= 85)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 86 && brakePercentage <= 109)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 110 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 134)
        {
            vMax = 150;
        }
        else if (brakePercentage >= 135)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }


    // Brake table B, train lengths from 301 to 400 m
    else if (trainLength >= 301 && trainLength <= 400)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 69)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 70 && brakePercentage <= 75)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 76 && brakePercentage <= 87)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 88 && brakePercentage <= 109)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 110 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 134)
        {
            vMax = 140;
        }
        else if (brakePercentage >= 135)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }


    // Brake table B, train lengths from 401 to 500 m
    else if (trainLength >= 401 && trainLength <= 500)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 69)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 70 && brakePercentage <= 75)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 76 && brakePercentage <= 87)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 88 && brakePercentage <= 109)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 110 && brakePercentage <= 129)
        {
            vMax = 120;
        }
        else if (brakePercentage >= 130 && brakePercentage <= 143)
        {
            vMax = 140;
        }
        else if (brakePercentage >= 144)
        {
            vMax = 160;
        }
        else
        {
            vMax = 0;
        }
    }


    // Brake table B, train lengths from 501 to 600 m
    else if (trainLength >= 501 && trainLength <= 600)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 72)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 73 && brakePercentage <= 79)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 80 && brakePercentage <= 93)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 94 && brakePercentage <= 118)
        {
            vMax = 110;
        }
        else if (brakePercentage >= 119 && brakePercentage <= 121)
        {
            vMax = 120;
        }
        else
        {
            vMax = 0;
        }
    }


    // Brake table B, train lengths from 601 to 730 m
    else if (trainLength >= 601 && trainLength <= 730)
    {
        if (brakePercentage <= 60)
        {
            vMax = 0;
        }
        else if (brakePercentage >= 61 && brakePercentage <= 74)
        {
            vMax = 70;
        }
        else if (brakePercentage >= 75 && brakePercentage <= 82)
        {
            vMax = 80;
        }
        else if (brakePercentage >= 83 && brakePercentage <= 87)
        {
            vMax = 90;
        }
        else if (brakePercentage >= 88 && brakePercentage <= 97)
        {
            vMax = 100;
        }
        else if (brakePercentage >= 98 && brakePercentage <= 121)
        {
            vMax = 110;
        }
        else
        {
            vMax = 0;
        }
    }


    // Default vMax to 0 if nothing match the input
    else
    {
        vMax = 0;
    }


    return vMax;
}

export default VMaxB;