const temporaryLocations = [
  { locationSignature: "A", advertisedLocationName: "Alings\u00e5s" },
  { locationSignature: "Aal", advertisedLocationName: "Annedal" },
  { locationSignature: "Ab", advertisedLocationName: "Arbr\u00e5" },
  { locationSignature: "Abb", advertisedLocationName: "Abborrtr\u00e4sk" },
  { locationSignature: "Acm", advertisedLocationName: "Avesta centrum" },
  { locationSignature: "Aft", advertisedLocationName: "Alfta" },
  { locationSignature: "Ag", advertisedLocationName: "Anneberg" },
  { locationSignature: "Agb", advertisedLocationName: "Agnesberg" },
  { locationSignature: "Agg", advertisedLocationName: "Algutsg\u00e5rden" },
  { locationSignature: "Ahm", advertisedLocationName: "Aleholm" },
  { locationSignature: "Aik", advertisedLocationName: "Aitik" },
  { locationSignature: "Ajr", advertisedLocationName: "Arvidsjaur" },
  { locationSignature: "Ak", advertisedLocationName: "Abisko \u00d6stra" },
  {
    locationSignature: "Akt",
    advertisedLocationName: "Abisko turiststation",
  },
  { locationSignature: "Al", advertisedLocationName: "Arl\u00f6v" },
  { locationSignature: "Alh", advertisedLocationName: "Alvhem" },
  {
    locationSignature: "Alv",
    advertisedLocationName: "Astrid Lindgrens v\u00e4rld",
  },
  { locationSignature: "Al\u00e4", advertisedLocationName: "Almn\u00e4s" },
  { locationSignature: "Am", advertisedLocationName: "Almedal" },
  { locationSignature: "Amk", advertisedLocationName: "Arnemark" },
  { locationSignature: "An", advertisedLocationName: "Arv\u00e5n" },
  { locationSignature: "Any", advertisedLocationName: "Aneby" },
  {
    locationSignature: "An\u00f6",
    advertisedLocationName: "Anundsj\u00f6",
  },
  { locationSignature: "Ap", advertisedLocationName: "Aspe\u00e5" },
  { locationSignature: "Apd", advertisedLocationName: "Aplared" },
  { locationSignature: "Apn", advertisedLocationName: "Aspen" },
  { locationSignature: "Apt", advertisedLocationName: "Aptas" },
  { locationSignature: "Ar", advertisedLocationName: "Arvika" },
  { locationSignature: "Ara", advertisedLocationName: "Arn\u00e4svall" },
  { locationSignature: "Arb", advertisedLocationName: "Arboga" },
  { locationSignature: "Arnc", advertisedLocationName: "Arlanda C" },
  { locationSignature: "Arne", advertisedLocationName: "Arlanda nedre" },
  { locationSignature: "Arnn", advertisedLocationName: "Arlanda N" },
  { locationSignature: "Arns", advertisedLocationName: "Arlanda S" },
  { locationSignature: "Asb", advertisedLocationName: "Assberg" },
  { locationSignature: "Asd", advertisedLocationName: "Aspedalen" },
  { locationSignature: "Atp", advertisedLocationName: "Attarp" },
  { locationSignature: "Auk", advertisedLocationName: "Auktsjaur" },
  { locationSignature: "Av", advertisedLocationName: "Alvesta" },
  { locationSignature: "Ava", advertisedLocationName: "Avaviken" },
  { locationSignature: "Avk", advertisedLocationName: "Almvik" },
  { locationSignature: "Avky", advertisedLocationName: "Avesta Krylbo" },
  { locationSignature: "Ax", advertisedLocationName: "Axmarby" },
  { locationSignature: "Ax.br", advertisedLocationName: "Axmar Bruk" },
  { locationSignature: "Ay", advertisedLocationName: "Alby" },
  { locationSignature: "A\u00e4", advertisedLocationName: "Alv\u00e4ng" },
  { locationSignature: "B", advertisedLocationName: "Bj\u00f6rnlunda" },
  { locationSignature: "Baa", advertisedLocationName: "Bark\u00e5kra" },
  { locationSignature: "Bad", advertisedLocationName: "Badsta" },
  {
    locationSignature: "Ba\u00f6",
    advertisedLocationName: "Backsj\u00f6n",
  },
  { locationSignature: "Bbe", advertisedLocationName: "Blyberg" },
  { locationSignature: "Bda", advertisedLocationName: "Blidstena" },
  { locationSignature: "Bdb", advertisedLocationName: "Badabruk" },
  { locationSignature: "Bdf", advertisedLocationName: "Bodafors" },
  { locationSignature: "Bdn", advertisedLocationName: "Boden C" },
  { locationSignature: "Bdo", advertisedLocationName: "Broddbo" },
  { locationSignature: "Bds", advertisedLocationName: "Bodens s\u00f6dra" },
  { locationSignature: "Bdy", advertisedLocationName: "Bredaryd" },
  { locationSignature: "Be", advertisedLocationName: "Backe" },
  { locationSignature: "Bef", advertisedLocationName: "Bengtsfors" },
  { locationSignature: "Ben", advertisedLocationName: "Bredviken" },
  { locationSignature: "Bet", advertisedLocationName: "Bruksberget" },
  { locationSignature: "Bf", advertisedLocationName: "Brunflo" },
  { locationSignature: "Bfo", advertisedLocationName: "Billingsfors" },
  { locationSignature: "Bfs", advertisedLocationName: "Bergfors" },
  { locationSignature: "Bg", advertisedLocationName: "Berga" },
  { locationSignature: "Bgm", advertisedLocationName: "Berghem" },
  { locationSignature: "Bgs", advertisedLocationName: "Bryngen\u00e4s" },
  { locationSignature: "Bgv", advertisedLocationName: "B\u00e4lgviken" },
  { locationSignature: "Bhb", advertisedLocationName: "Br\u00e4kne-Hoby" },
  { locationSignature: "Bhs", advertisedLocationName: "Bohus" },
  { locationSignature: "Bia", advertisedLocationName: "Birsta" },
  { locationSignature: "Bib", advertisedLocationName: "Billeberga" },
  { locationSignature: "Bih", advertisedLocationName: "Billesholm" },
  { locationSignature: "Bj", advertisedLocationName: "Bj\u00f6rna" },
  { locationSignature: "Bja", advertisedLocationName: "Bj\u00e4sta" },
  { locationSignature: "Bjb", advertisedLocationName: "Bj\u00f6rneborg" },
  { locationSignature: "Bjf", advertisedLocationName: "Bj\u00f8rnfjell" },
  { locationSignature: "Bjh", advertisedLocationName: "Bjurhem" },
  { locationSignature: "Bjm", advertisedLocationName: "Bj\u00e4rnum" },
  { locationSignature: "Bjn", advertisedLocationName: "Bj\u00f6rnkulla" },
  { locationSignature: "Bjo", advertisedLocationName: "Bj\u00f6rketorp" },
  { locationSignature: "Bjuv", advertisedLocationName: "Bjuv" },
  {
    locationSignature: "Bjy",
    advertisedLocationName: "Bj\u00f6rk\u00f6by",
  },
  {
    locationSignature: "Bj\u00f6",
    advertisedLocationName: "Bj\u00f6rnsj\u00f6",
  },
  { locationSignature: "Bkb", advertisedLocationName: "Barkarby" },
  { locationSignature: "Bl", advertisedLocationName: "Ballingsl\u00f6v" },
  { locationSignature: "Bld", advertisedLocationName: "Bl\u00e4dinge" },
  { locationSignature: "Blg", advertisedLocationName: "Borl\u00e4nge C" },
  { locationSignature: "Bln", advertisedLocationName: "Bj\u00f6rkliden" },
  { locationSignature: "Blv", advertisedLocationName: "Burl\u00f6v" },
  { locationSignature: "Bma", advertisedLocationName: "Blomsterm\u00e5la" },
  { locationSignature: "Bmb", advertisedLocationName: "Blomberg" },
  { locationSignature: "Bml", advertisedLocationName: "Brom\u00f6lla" },
  { locationSignature: "Bmo", advertisedLocationName: "B\u00e5ramo" },
  { locationSignature: "Bn", advertisedLocationName: "Bolln\u00e4s" },
  { locationSignature: "Bna", advertisedLocationName: "Brunna" },
  { locationSignature: "Bnk", advertisedLocationName: "Blattnicksele" },
  { locationSignature: "Boa", advertisedLocationName: "Borgstena" },
  { locationSignature: "Boda", advertisedLocationName: "Boda" },
  { locationSignature: "Bof", advertisedLocationName: "Bofors" },
  { locationSignature: "Bog", advertisedLocationName: "Bodaborg" },
  { locationSignature: "Bom", advertisedLocationName: "Bomansberget" },
  { locationSignature: "Bop", advertisedLocationName: "Botorp" },
  { locationSignature: "Bor", advertisedLocationName: "Bor" },
  { locationSignature: "Born", advertisedLocationName: "Born" },
  { locationSignature: "Boy", advertisedLocationName: "Bollebygd" },
  { locationSignature: "Bp", advertisedLocationName: "Br\u00e4nnarp" },
  { locationSignature: "Bra", advertisedLocationName: "Brista" },
  { locationSignature: "Brd", advertisedLocationName: "Br\u00e4nnland" },
  { locationSignature: "Bre", advertisedLocationName: "Br\u00e4nninge" },
  { locationSignature: "Brg", advertisedLocationName: "Br\u00e4nnberg" },
  { locationSignature: "Brh", advertisedLocationName: "Brattheden" },
  { locationSignature: "Brin", advertisedLocationName: "Brinellskolan" },
  { locationSignature: "Brk", advertisedLocationName: "Brokind" },
  { locationSignature: "Brl", advertisedLocationName: "Br\u00e5landa" },
  { locationSignature: "Brn", advertisedLocationName: "Br\u00e5nan" },
  { locationSignature: "Brny", advertisedLocationName: "Bro Nyg\u00e5rd" },
  { locationSignature: "Bro", advertisedLocationName: "Bro" },
  { locationSignature: "Brs", advertisedLocationName: "Brattsbacka" },
  { locationSignature: "Bry", advertisedLocationName: "Bankeryd" },
  { locationSignature: "Br\u00e5", advertisedLocationName: "Bjur\u00e5" },
  { locationSignature: "Br\u00f6", advertisedLocationName: "Bredsj\u00f6" },
  { locationSignature: "Bsa", advertisedLocationName: "Brastad" },
  { locationSignature: "Bsb", advertisedLocationName: "Bensj\u00f6backen" },
  { locationSignature: "Bsc", advertisedLocationName: "Bor\u00e5s C" },
  { locationSignature: "Bsd", advertisedLocationName: "Brunnsg\u00e5rd" },
  { locationSignature: "Bsg", advertisedLocationName: "Bispg\u00e5rden" },
  { locationSignature: "Bsp", advertisedLocationName: "Bestorp" },
  { locationSignature: "Bst", advertisedLocationName: "Bastutr\u00e4sk" },
  {
    locationSignature: "Bs\u00e4",
    advertisedLocationName: "Bj\u00e4rka-S\u00e4by",
  },
  { locationSignature: "Bt", advertisedLocationName: "Baggetorp" },
  { locationSignature: "Btb", advertisedLocationName: "Brattby" },
  { locationSignature: "Btg", advertisedLocationName: "Bratteborg" },
  { locationSignature: "Bth", advertisedLocationName: "Basthagen" },
  { locationSignature: "Btp", advertisedLocationName: "Biskopstorp" },
  { locationSignature: "Bt\u00e5", advertisedLocationName: "Bet\u00e5sen" },
  { locationSignature: "Bu", advertisedLocationName: "Brunsbergstunneln" },
  { locationSignature: "Bud", advertisedLocationName: "Buddbyn" },
  { locationSignature: "Buk", advertisedLocationName: "Brunsberg" },
  { locationSignature: "Bur", advertisedLocationName: "Buresj\u00f6n" },
  { locationSignature: "But", advertisedLocationName: "Buterud" },
  { locationSignature: "Bva", advertisedLocationName: "Barva" },
  { locationSignature: "Bvr", advertisedLocationName: "Blackvreten" },
  { locationSignature: "Bx", advertisedLocationName: "Boxholm" },
  { locationSignature: "By", advertisedLocationName: "Byvalla" },
  { locationSignature: "Bym", advertisedLocationName: "Byarum" },
  { locationSignature: "By\u00e4", advertisedLocationName: "By\u00e4lven" },
  { locationSignature: "Bz", advertisedLocationName: "Bruzaholm" },
  { locationSignature: "B\u00e5a", advertisedLocationName: "Berg\u00e5sa" },
  { locationSignature: "B\u00e5l", advertisedLocationName: "B\u00e5lsta" },
  { locationSignature: "B\u00e5n", advertisedLocationName: "B\u00e5stad" },
  { locationSignature: "B\u00e5p", advertisedLocationName: "Br\u00e5garp" },
  { locationSignature: "B\u00e4", advertisedLocationName: "Br\u00e4cke" },
  {
    locationSignature: "B\u00e4b",
    advertisedLocationName: "B\u00e4ckebron",
  },
  {
    locationSignature: "B\u00e4f",
    advertisedLocationName: "B\u00e4ckefors",
  },
  { locationSignature: "B\u00e4l", advertisedLocationName: "B\u00e4llsta" },
  {
    locationSignature: "B\u00f6g",
    advertisedLocationName: "Br\u00e4nn\u00f6g\u00e5rd",
  },
  {
    locationSignature: "B\u00f6k",
    advertisedLocationName: "Bj\u00f6rkhult",
  },
  { locationSignature: "B\u00f6n", advertisedLocationName: "Bodsj\u00f6n" },
  { locationSignature: "B\u00f6o", advertisedLocationName: "Bj\u00f6rbo" },
  { locationSignature: "Cas", advertisedLocationName: "Callans s\u00e5g" },
  { locationSignature: "Cg", advertisedLocationName: "Charlottenberg" },
  { locationSignature: "Ck", advertisedLocationName: "Karlskrona C" },
  {
    locationSignature: "Cph",
    advertisedLocationName: "Copenhagen Airport",
  },
  { locationSignature: "Cr", advertisedLocationName: "Kristianstad C" },
  { locationSignature: "Crgb", advertisedLocationName: "Kristianstad GBG" },
  {
    locationSignature: "Crs",
    advertisedLocationName: "Kristianstads s\u00f6dra",
  },
  { locationSignature: "Cst", advertisedLocationName: "Stockholm Central" },
  { locationSignature: "D", advertisedLocationName: "Deger\u00f6n" },
  { locationSignature: "Dat", advertisedLocationName: "Dammstorp" },
  { locationSignature: "Dbn", advertisedLocationName: "Degerb\u00e4cken" },
  { locationSignature: "De.ber", advertisedLocationName: "Berlin" },
  { locationSignature: "Dfl", advertisedLocationName: "Dala-Floda" },
  { locationSignature: "Dg", advertisedLocationName: "Degerfors" },
  { locationSignature: "Dgm", advertisedLocationName: "Degermyr" },
  { locationSignature: "Dgn", advertisedLocationName: "Dalgr\u00e4nsen" },
  {
    locationSignature: "Dg\u00f6",
    advertisedLocationName: "Dagl\u00f6sen",
  },
  { locationSignature: "Dis", advertisedLocationName: "Di\u00f6" },
  {
    locationSignature: "Di\u00f6",
    advertisedLocationName: "Di\u00f6 Norra",
  },
  { locationSignature: "Dj", advertisedLocationName: "Dala-J\u00e4rna" },
  { locationSignature: "Dk", advertisedLocationName: "Dockmyr" },
  {
    locationSignature: "Dk.cph",
    advertisedLocationName: "Copenhagen Airport",
  },
  { locationSignature: "Dk.hg", advertisedLocationName: "Helsing\u00f6r" },
  {
    locationSignature: "Dk.kh",
    advertisedLocationName: "K\u00f6penhamn H",
  },
  { locationSignature: "Dk.kk", advertisedLocationName: "\u00d6sterport" },
  {
    locationSignature: "Dk.t\u00e5t",
    advertisedLocationName: "T\u00e5rnby",
  },
  {
    locationSignature: "Dk.\u00f8re",
    advertisedLocationName: "\u00d6restad",
  },
  { locationSignature: "Dl", advertisedLocationName: "Dingle" },
  { locationSignature: "Dld", advertisedLocationName: "Dals L\u00e5nged" },
  { locationSignature: "Dma", advertisedLocationName: "Dannemora" },
  { locationSignature: "Dn", advertisedLocationName: "Dagarn" },
  { locationSignature: "Dns", advertisedLocationName: "Dyn\u00e4s" },
  { locationSignature: "Dre", advertisedLocationName: "Derome" },
  { locationSignature: "Drt", advertisedLocationName: "Dals Rostock" },
  { locationSignature: "Dt", advertisedLocationName: "Dingtuna" },
  { locationSignature: "Dta", advertisedLocationName: "Dorotea" },
  { locationSignature: "Dtc", advertisedLocationName: "Dorotea camping" },
  { locationSignature: "Du", advertisedLocationName: "Duved" },
  { locationSignature: "Duo", advertisedLocationName: "Duvbo" },
  { locationSignature: "Dy", advertisedLocationName: "Dysj\u00f6n" },
  { locationSignature: "D\u00e5", advertisedLocationName: "Djur\u00e5s" },
  {
    locationSignature: "D\u00e5n",
    advertisedLocationName: "D\u00e5nviken",
  },
  { locationSignature: "D\u00f6e", advertisedLocationName: "Dr\u00f6mme" },
  {
    locationSignature: "D\u00f6l",
    advertisedLocationName: "D\u00f6ljebro",
  },
  {
    locationSignature: "D\u00f6s",
    advertisedLocationName: "D\u00f6sjebro",
  },
  { locationSignature: "E", advertisedLocationName: "Esl\u00f6v" },
  { locationSignature: "Ea", advertisedLocationName: "Eldsberga" },
  { locationSignature: "Ebg", advertisedLocationName: "Enstaberga" },
  { locationSignature: "Ebk", advertisedLocationName: "Edsbj\u00f6rke" },
  { locationSignature: "Eby", advertisedLocationName: "Ekeby" },
  { locationSignature: "Ed", advertisedLocationName: "Ed" },
  { locationSignature: "Edn", advertisedLocationName: "Edsbyn" },
  {
    locationSignature: "Eds",
    advertisedLocationName: "Edsbyns s\u00f6dra",
  },
  { locationSignature: "Edv", advertisedLocationName: "Edsbyverken" },
  { locationSignature: "Ei", advertisedLocationName: "Erikslund" },
  { locationSignature: "Ek", advertisedLocationName: "Eksj\u00f6" },
  { locationSignature: "Eko", advertisedLocationName: "Ekolsund" },
  { locationSignature: "Eky", advertisedLocationName: "Ekeryd" },
  { locationSignature: "El", advertisedLocationName: "Edsvalla" },
  { locationSignature: "Em", advertisedLocationName: "Emmaboda" },
  { locationSignature: "En", advertisedLocationName: "Edane" },
  { locationSignature: "Ens", advertisedLocationName: "Enafors" },
  { locationSignature: "Ep", advertisedLocationName: "Enk\u00f6ping" },
  { locationSignature: "Er", advertisedLocationName: "Ervalla" },
  { locationSignature: "Era", advertisedLocationName: "Eneryda" },
  { locationSignature: "Erk", advertisedLocationName: "Erikstad" },
  {
    locationSignature: "Esn",
    advertisedLocationName: "Eken\u00e4ssj\u00f6n",
  },
  { locationSignature: "Et", advertisedLocationName: "Eskilstuna C" },
  { locationSignature: "Etk", advertisedLocationName: "Ektr\u00e4sk" },
  { locationSignature: "F", advertisedLocationName: "Falk\u00f6ping C" },
  { locationSignature: "Fa", advertisedLocationName: "Finner\u00f6dja" },
  { locationSignature: "Faa", advertisedLocationName: "Flodala" },
  {
    locationSignature: "Fabg",
    advertisedLocationName: "Falkenbergs godsstation",
  },
  { locationSignature: "Fabp", advertisedLocationName: "Falkenberg" },
  { locationSignature: "Fad", advertisedLocationName: "F\u00e4stad" },
  { locationSignature: "Fal", advertisedLocationName: "Falerum" },
  { locationSignature: "Fas", advertisedLocationName: "Farsta strand" },
  { locationSignature: "Fb", advertisedLocationName: "Forsbacka" },
  { locationSignature: "Fby", advertisedLocationName: "Floby" },
  { locationSignature: "Fc", advertisedLocationName: "Framn\u00e4s city" },
  { locationSignature: "Fd", advertisedLocationName: "Floda" },
  { locationSignature: "Fda", advertisedLocationName: "Furudal" },
  { locationSignature: "Fdf", advertisedLocationName: "Fr\u00e4ndefors" },
  { locationSignature: "Fdl", advertisedLocationName: "Fredriksdal" },
  { locationSignature: "Feb", advertisedLocationName: "Fellingsbro" },
  {
    locationSignature: "Fe\u00f6",
    advertisedLocationName: "F\u00e5gelsj\u00f6",
  },
  { locationSignature: "Ffl", advertisedLocationName: "Fruf\u00e4llan" },
  { locationSignature: "Ffs", advertisedLocationName: "Finnforsfallet" },
  { locationSignature: "Fg", advertisedLocationName: "Finsp\u00e5ng" },
  { locationSignature: "Fgc", advertisedLocationName: "Fagersta C" },
  { locationSignature: "Fgl", advertisedLocationName: "F\u00e5gelsta" },
  { locationSignature: "Fgn", advertisedLocationName: "Fagersta Norra" },
  { locationSignature: "Fgs", advertisedLocationName: "Fagersanna" },
  { locationSignature: "Fg\u00e5", advertisedLocationName: "Fager\u00e5s" },
  {
    locationSignature: "Fg\u00f6",
    advertisedLocationName: "F\u00e5ngsj\u00f6backen",
  },
  { locationSignature: "Fhm", advertisedLocationName: "Forshem" },
  { locationSignature: "Fi", advertisedLocationName: "Fiskeby" },
  { locationSignature: "Fid", advertisedLocationName: "Filipstad" },
  { locationSignature: "Fil", advertisedLocationName: "Fillan" },
  { locationSignature: "Fin", advertisedLocationName: "Finja" },
  {
    locationSignature: "Fi\u00e4",
    advertisedLocationName: "Filsb\u00e4ck",
  },
  {
    locationSignature: "Fj\u00e5",
    advertisedLocationName: "Fj\u00e4ll\u00e5sen",
  },
  { locationSignature: "Fki", advertisedLocationName: "Fj\u00e4lkinge" },
  { locationSignature: "Fkr", advertisedLocationName: "F\u00e5ker" },
  { locationSignature: "Fl", advertisedLocationName: "Fl\u00e4die" },
  { locationSignature: "Flb", advertisedLocationName: "Flemingsberg" },
  { locationSignature: "Fle", advertisedLocationName: "Flen" },
  {
    locationSignature: "Flgd",
    advertisedLocationName: "Friluftsg\u00e5rden",
  },
  { locationSignature: "Fln", advertisedLocationName: "Falun C" },
  { locationSignature: "Flp", advertisedLocationName: "Flackarp" },
  { locationSignature: "Fls", advertisedLocationName: "Flisby" },
  { locationSignature: "Fm", advertisedLocationName: "Forserum" },
  {
    locationSignature: "Fn",
    advertisedLocationName: "Falk\u00f6pings norra",
  },
  {
    locationSignature: "Fn\u00f6",
    advertisedLocationName: "Fr\u00e5n\u00f6",
  },
  { locationSignature: "Fod", advertisedLocationName: "Forsheda" },
  { locationSignature: "Fok", advertisedLocationName: "Folkesta" },
  { locationSignature: "Foo", advertisedLocationName: "Foodia" },
  { locationSignature: "Fra", advertisedLocationName: "Fristad" },
  { locationSignature: "Frd", advertisedLocationName: "Frinnaryd" },
  { locationSignature: "Fre", advertisedLocationName: "Fredrikslund" },
  { locationSignature: "Frl", advertisedLocationName: "Fritsla" },
  {
    locationSignature: "Fr\u00e5",
    advertisedLocationName: "Fryk\u00e5sen",
  },
  { locationSignature: "Fs", advertisedLocationName: "Fors" },
  { locationSignature: "Fsb", advertisedLocationName: "Fosieby" },
  { locationSignature: "Fsm", advertisedLocationName: "Forsmo" },
  {
    locationSignature: "Fs\u00f6",
    advertisedLocationName: "Flens \u00f6vre",
  },
  { locationSignature: "Ft", advertisedLocationName: "Fr\u00e4nsta" },
  { locationSignature: "Fud", advertisedLocationName: "Furulund" },
  { locationSignature: "Fur", advertisedLocationName: "Furet" },
  { locationSignature: "Fv", advertisedLocationName: "Fr\u00f6vi" },
  { locationSignature: "Fvk", advertisedLocationName: "Furuvik" },
  {
    locationSignature: "F\u00e5",
    advertisedLocationName: "Frilles\u00e5s",
  },
  { locationSignature: "F\u00e4r", advertisedLocationName: "Flisk\u00e4r" },
  {
    locationSignature: "F\u00f6r",
    advertisedLocationName: "F\u00f6rsl\u00f6v",
  },
  { locationSignature: "G", advertisedLocationName: "G\u00f6teborg C" },
  { locationSignature: "G.ls", advertisedLocationName: "Lejonsp\u00e5ret" },
  { locationSignature: "Ga", advertisedLocationName: "Graninge" },
  { locationSignature: "Gad", advertisedLocationName: "Granstanda" },
  { locationSignature: "Gal", advertisedLocationName: "Gamleby" },
  { locationSignature: "Gan", advertisedLocationName: "Gantofta" },
  { locationSignature: "Gas", advertisedLocationName: "Gamlestaden" },
  { locationSignature: "Gau", advertisedLocationName: "Garnudden" },
  {
    locationSignature: "Gb",
    advertisedLocationName: "G\u00f6teborgs norra",
  },
  {
    locationSignature: "Gbco",
    advertisedLocationName: "G\u00f6teborg Combiterminal",
  },
  {
    locationSignature: "Gbm",
    advertisedLocationName: "G\u00f6teborg Marieholm",
  },
  { locationSignature: "Gdl", advertisedLocationName: "Geijersdal" },
  {
    locationSignature: "Gdv",
    advertisedLocationName: "Gr\u00f6ndalsviken",
  },
  {
    locationSignature: "Gd\u00f6",
    advertisedLocationName: "G\u00e5rdsj\u00f6",
  },
  { locationSignature: "Ge", advertisedLocationName: "Gottne" },
  {
    locationSignature: "Gen",
    advertisedLocationName: "G\u00e5str\u00e4sken",
  },
  { locationSignature: "Gg", advertisedLocationName: "Gr\u00e4ngesberg" },
  {
    locationSignature: "Ggm",
    advertisedLocationName: "Gr\u00e4ngesbergs malmbang\u00e5rd",
  },
  { locationSignature: "Ghd", advertisedLocationName: "Grohed" },
  { locationSignature: "Ghu", advertisedLocationName: "Gula huset" },
  { locationSignature: "Ghy", advertisedLocationName: "Grythyttan" },
  {
    locationSignature: "Ghyn",
    advertisedLocationName: "Grythyttans norra",
  },
  { locationSignature: "Gi", advertisedLocationName: "Gistad" },
  { locationSignature: "Gia", advertisedLocationName: "Gide\u00e5backa" },
  { locationSignature: "Gib", advertisedLocationName: "Grillby" },
  { locationSignature: "Gim", advertisedLocationName: "Gimon\u00e4s" },
  {
    locationSignature: "Gk",
    advertisedLocationName: "G\u00f6teborg Kville",
  },
  {
    locationSignature: "Glk",
    advertisedLocationName: "Glommerstr\u00e4sk",
  },
  { locationSignature: "Glt", advertisedLocationName: "Gulltr\u00e4sk" },
  { locationSignature: "Glv", advertisedLocationName: "Glumsl\u00f6v" },
  { locationSignature: "Gm", advertisedLocationName: "Gemla" },
  { locationSignature: "Gmo", advertisedLocationName: "Gimo" },
  { locationSignature: "Gmp", advertisedLocationName: "Gamlarp" },
  { locationSignature: "Gms", advertisedLocationName: "Grums" },
  { locationSignature: "Gn", advertisedLocationName: "Gnesta" },
  { locationSignature: "Gnb", advertisedLocationName: "Gunnesbo" },
  { locationSignature: "Gnd", advertisedLocationName: "Genevad" },
  { locationSignature: "Gnf", advertisedLocationName: "Gagnef" },
  { locationSignature: "Gng", advertisedLocationName: "Gullringen" },
  { locationSignature: "Gnp", advertisedLocationName: "Gnarp" },
  {
    locationSignature: "Gn\u00e5",
    advertisedLocationName: "Gr\u00f6n\u00e5sen",
  },
  { locationSignature: "Gn\u00f6", advertisedLocationName: "Gnosj\u00f6" },
  { locationSignature: "Go", advertisedLocationName: "Godeg\u00e5rd" },
  { locationSignature: "Gop", advertisedLocationName: "Gr\u00e4storp" },
  { locationSignature: "Gp", advertisedLocationName: "Gripenberg" },
  { locationSignature: "Gpc", advertisedLocationName: "Geogr. Polcirkeln" },
  { locationSignature: "Gr", advertisedLocationName: "Granbergsdal" },
  { locationSignature: "Grd", advertisedLocationName: "Grevaryd" },
  { locationSignature: "Grm", advertisedLocationName: "Grims\u00e5s" },
  { locationSignature: "Grn", advertisedLocationName: "G\u00f6ringen" },
  { locationSignature: "Gro", advertisedLocationName: "Gubbero" },
  { locationSignature: "Grs", advertisedLocationName: "Gransj\u00f6" },
  { locationSignature: "Gru", advertisedLocationName: "Grundbro" },
  { locationSignature: "Gry", advertisedLocationName: "Gryttje" },
  {
    locationSignature: "Gr\u00f6",
    advertisedLocationName: "Gr\u00f6tingen",
  },
  { locationSignature: "Gse", advertisedLocationName: "Gunsen" },
  {
    locationSignature: "Gsh",
    advertisedLocationName: "G\u00f6teborg Skandiahamnen",
  },
  { locationSignature: "Gsn", advertisedLocationName: "Gastsj\u00f6n" },
  { locationSignature: "Gss", advertisedLocationName: "G\u00e4rsn\u00e4s" },
  { locationSignature: "Gst", advertisedLocationName: "Gammelstad" },
  {
    locationSignature: "Gsv",
    advertisedLocationName: "G\u00f6teborg S\u00e4ven\u00e4s",
  },
  { locationSignature: "Gs\u00e5", advertisedLocationName: "Gars\u00e5s" },
  { locationSignature: "Gt", advertisedLocationName: "Grimstorp" },
  { locationSignature: "Gtf", advertisedLocationName: "Gustafs" },
  { locationSignature: "Gttu", advertisedLocationName: "Gamla Tortuna" },
  { locationSignature: "Gt\u00e5", advertisedLocationName: "Get\u00e5" },
  { locationSignature: "Gua", advertisedLocationName: "Gullberna" },
  { locationSignature: "Gui", advertisedLocationName: "Gussi" },
  { locationSignature: "Gun", advertisedLocationName: "Gunnarn" },
  { locationSignature: "Gv", advertisedLocationName: "G\u00e4llivare" },
  {
    locationSignature: "Gvo",
    advertisedLocationName: "G\u00f6teborg Volvo",
  },
  { locationSignature: "Gy", advertisedLocationName: "G\u00e4ddmyr" },
  { locationSignature: "Gyo", advertisedLocationName: "Grycksbo" },
  { locationSignature: "Gyt", advertisedLocationName: "Gyttorp" },
  {
    locationSignature: "G\u00e5n",
    advertisedLocationName: "G\u00e5ln\u00e4s",
  },
  {
    locationSignature: "G\u00e5p",
    advertisedLocationName: "G\u00e5vetorp",
  },
  { locationSignature: "G\u00e4", advertisedLocationName: "G\u00e4vle C" },
  {
    locationSignature: "G\u00e4.f",
    advertisedLocationName: "T\u00e5ghallen",
  },
  {
    locationSignature: "G\u00e4.g",
    advertisedLocationName: "Gasklockorna",
  },
  {
    locationSignature: "G\u00e4.h",
    advertisedLocationName: "G\u00e4vle hamn",
  },
  {
    locationSignature: "G\u00e4.j",
    advertisedLocationName: "J\u00e4rnv\u00e4gsmuseum",
  },
  {
    locationSignature: "G\u00e4b",
    advertisedLocationName: "G\u00e4vle godsbang\u00e5rd",
  },
  { locationSignature: "G\u00e4d", advertisedLocationName: "G\u00e4rdala" },
  {
    locationSignature: "G\u00e4g",
    advertisedLocationName: "Gr\u00e4sberg",
  },
  {
    locationSignature: "G\u00f6",
    advertisedLocationName: "G\u00e4ll\u00f6",
  },
  {
    locationSignature: "G\u00f6n",
    advertisedLocationName: "G\u00e5rdsj\u00f6n",
  },
  { locationSignature: "Haa", advertisedLocationName: "Hamra" },
  { locationSignature: "Hak", advertisedLocationName: "Hallstavik" },
  { locationSignature: "Hal", advertisedLocationName: "Hallinden" },
  { locationSignature: "Han", advertisedLocationName: "Harasj\u00f6n" },
  { locationSignature: "Har", advertisedLocationName: "Harr\u00e5" },
  { locationSignature: "Hb", advertisedLocationName: "Helsingborg C" },
  { locationSignature: "Hbd", advertisedLocationName: "H\u00f6gboda" },
  { locationSignature: "Hbgb", advertisedLocationName: "Raml\u00f6sa" },
  { locationSignature: "Hbr", advertisedLocationName: "Holsbybrunn" },
  {
    locationSignature: "Hb\u00e4",
    advertisedLocationName: "H\u00f6gbr\u00e4nna",
  },
  { locationSignature: "Hd", advertisedLocationName: "Halmstad C" },
  { locationSignature: "Hdm", advertisedLocationName: "Hedemora" },
  { locationSignature: "Hdn", advertisedLocationName: "Holmsveden" },
  {
    locationSignature: "Hdr",
    advertisedLocationName: "Halmstads rangerbang\u00e5rd",
  },
  { locationSignature: "He", advertisedLocationName: "Heberg" },
  { locationSignature: "Hel", advertisedLocationName: "Helenelund" },
  { locationSignature: "Hes", advertisedLocationName: "Helmershus" },
  { locationSignature: "Het", advertisedLocationName: "Hestra" },
  { locationSignature: "Hf", advertisedLocationName: "Hultsfred" },
  { locationSignature: "Hfa", advertisedLocationName: "Hemfosa" },
  {
    locationSignature: "Hfj",
    advertisedLocationName: "Hamr\u00e5ngefj\u00e4rden",
  },
  { locationSignature: "Hfr", advertisedLocationName: "Hofors" },
  { locationSignature: "Hfs", advertisedLocationName: "Holmfors" },
  { locationSignature: "Hg", advertisedLocationName: "Hagastr\u00f6m" },
  { locationSignature: "Hge", advertisedLocationName: "Hagge" },
  { locationSignature: "Hgl", advertisedLocationName: "Hagalund" },
  {
    locationSignature: "Hgs",
    advertisedLocationName: "H\u00e4ggen\u00e5s",
  },
  { locationSignature: "Hgv", advertisedLocationName: "H\u00e4ggvik" },
  {
    locationSignature: "Hg\u00f6",
    advertisedLocationName: "H\u00f6gsj\u00f6",
  },
  { locationSignature: "Hh", advertisedLocationName: "Hallstahammar" },
  { locationSignature: "Hie", advertisedLocationName: "Hyllie" },
  { locationSignature: "Hil", advertisedLocationName: "Hillared" },
  { locationSignature: "Hj", advertisedLocationName: "Hjulsbro" },
  { locationSignature: "Hjp", advertisedLocationName: "Hj\u00e4rup" },
  { locationSignature: "Hka", advertisedLocationName: "Huskvarna" },
  { locationSignature: "Hkl", advertisedLocationName: "Hudiksvall" },
  { locationSignature: "Hks", advertisedLocationName: "Hack\u00e5s" },
  {
    locationSignature: "Hk\u00f6",
    advertisedLocationName: "H\u00f6k\u00f6n",
  },
  { locationSignature: "Hl", advertisedLocationName: "Horndal" },
  { locationSignature: "Hlf", advertisedLocationName: "H\u00e4llefors" },
  { locationSignature: "Hlk", advertisedLocationName: "H\u00e4llekis" },
  { locationSignature: "Hlm", advertisedLocationName: "Helgum" },
  { locationSignature: "Hlp", advertisedLocationName: "Hasslarp" },
  { locationSignature: "Hlr", advertisedLocationName: "Horndals Bruk" },
  { locationSignature: "Hls", advertisedLocationName: "H\u00e4lln\u00e4s" },
  { locationSignature: "Hlt", advertisedLocationName: "Herrhult" },
  {
    locationSignature: "Hlv",
    advertisedLocationName: "H\u00e4llevadsholm",
  },
  { locationSignature: "Hly", advertisedLocationName: "Hilleby" },
  {
    locationSignature: "Hl\u00f6",
    advertisedLocationName: "H\u00f6l\u00f6",
  },
  { locationSignature: "Hm", advertisedLocationName: "H\u00e4ssleholm" },
  { locationSignature: "Hma", advertisedLocationName: "H\u00f6kmora" },
  { locationSignature: "Hmfs", advertisedLocationName: "Hissmofors" },
  { locationSignature: "Hmr", advertisedLocationName: "Hammar" },
  { locationSignature: "Hm\u00f6", advertisedLocationName: "Holmsj\u00f6" },
  { locationSignature: "Hnb", advertisedLocationName: "H\u00e4lsingenybo" },
  { locationSignature: "Hnd", advertisedLocationName: "Handen" },
  { locationSignature: "Hnl", advertisedLocationName: "H\u00e4llenyland" },
  { locationSignature: "Hnn", advertisedLocationName: "Hennan" },
  { locationSignature: "Hno", advertisedLocationName: "Hinsnoret" },
  {
    locationSignature: "Hn\u00e4",
    advertisedLocationName: "H\u00e4lleforsn\u00e4s",
  },
  { locationSignature: "Ho", advertisedLocationName: "Habo" },
  { locationSignature: "Hod", advertisedLocationName: "Holmsund" },
  { locationSignature: "Hok", advertisedLocationName: "Hok" },
  { locationSignature: "Hova", advertisedLocationName: "Hova" },
  { locationSignature: "Ho\u00e5", advertisedLocationName: "Holm\u00e5n" },
  { locationSignature: "Hp", advertisedLocationName: "Haparanda" },
  { locationSignature: "Hpbg", advertisedLocationName: "Hallsberg" },
  { locationSignature: "Hpn", advertisedLocationName: "Hallsberg Posten" },
  { locationSignature: "Hr", advertisedLocationName: "Herrljunga" },
  {
    locationSignature: "Hrbg",
    advertisedLocationName: "Hallsbergs rangerbang\u00e5rd",
  },
  { locationSignature: "Hre", advertisedLocationName: "Horred" },
  { locationSignature: "Hrl", advertisedLocationName: "H\u00f6rle" },
  { locationSignature: "Hrm", advertisedLocationName: "Hargshamn" },
  { locationSignature: "Hrt", advertisedLocationName: "Harrtr\u00e4sk" },
  { locationSignature: "Hs", advertisedLocationName: "Hasselfors" },
  { locationSignature: "Hsa", advertisedLocationName: "Hovsta" },
  { locationSignature: "Hsb", advertisedLocationName: "Hedensbyn" },
  {
    locationSignature: "Hsd",
    advertisedLocationName: "H\u00e4rn\u00f6sand C",
  },
  { locationSignature: "Hsk", advertisedLocationName: "Hanaskog" },
  { locationSignature: "Hsr", advertisedLocationName: "Hassler\u00f6r" },
  { locationSignature: "Hsy", advertisedLocationName: "Hussj\u00f6by" },
  {
    locationSignature: "Hs\u00f6",
    advertisedLocationName: "H\u00f6rnsj\u00f6",
  },
  { locationSignature: "Ht", advertisedLocationName: "Hednoret" },
  { locationSignature: "Htg", advertisedLocationName: "Hoting" },
  { locationSignature: "Hto", advertisedLocationName: "Hillerstorp" },
  { locationSignature: "Hu", advertisedLocationName: "Huddinge" },
  { locationSignature: "Hud", advertisedLocationName: "Hulingsryd" },
  { locationSignature: "Hul", advertisedLocationName: "Hult" },
  { locationSignature: "Hum", advertisedLocationName: "Husum" },
  { locationSignature: "Humn", advertisedLocationName: "Husums norra" },
  { locationSignature: "Huv", advertisedLocationName: "Huvudsta" },
  {
    locationSignature: "Hu\u00f6",
    advertisedLocationName: "Hundsj\u00f6n",
  },
  { locationSignature: "Hv", advertisedLocationName: "H\u00e4stveda" },
  { locationSignature: "Hvd", advertisedLocationName: "Hj\u00e4ltevad" },
  { locationSignature: "Hvp", advertisedLocationName: "Hovmantorp" },
  { locationSignature: "Hvr", advertisedLocationName: "H\u00e5verud" },
  { locationSignature: "Hvs", advertisedLocationName: "Hovsl\u00e4tt" },
  { locationSignature: "Hy", advertisedLocationName: "Heby" },
  { locationSignature: "Hyb", advertisedLocationName: "Hyltebruk" },
  { locationSignature: "Hyl", advertisedLocationName: "Hyllstofta" },
  {
    locationSignature: "H\u00e5",
    advertisedLocationName: "H\u00e5sj\u00f6",
  },
  {
    locationSignature: "H\u00e5b",
    advertisedLocationName: "H\u00e5lbergsliden",
  },
  {
    locationSignature: "H\u00e5g",
    advertisedLocationName: "Harm\u00e5nger",
  },
  {
    locationSignature: "H\u00e5k",
    advertisedLocationName: "H\u00e5moj\u00e5kk",
  },
  { locationSignature: "H\u00e5l", advertisedLocationName: "H\u00e5llsta" },
  {
    locationSignature: "H\u00e5p",
    advertisedLocationName: "H\u00e5kantorp",
  },
  { locationSignature: "H\u00e5s", advertisedLocationName: "Hind\u00e5s" },
  { locationSignature: "H\u00e4", advertisedLocationName: "H\u00e4stbo" },
  {
    locationSignature: "H\u00e4b",
    advertisedLocationName: "H\u00e4mrasviken",
  },
  { locationSignature: "H\u00e4d", advertisedLocationName: "H\u00e4rad" },
  {
    locationSignature: "H\u00e4n",
    advertisedLocationName: "H\u00e4ggsj\u00f6n",
  },
  { locationSignature: "H\u00e4p", advertisedLocationName: "H\u00e4ljarp" },
  { locationSignature: "H\u00e4y", advertisedLocationName: "H\u00e4rryda" },
  {
    locationSignature: "H\u00f6",
    advertisedLocationName: "H\u00f6\u00f6r",
  },
  {
    locationSignature: "H\u00f6f",
    advertisedLocationName: "H\u00f6rnefors",
  },
  {
    locationSignature: "H\u00f6g",
    advertisedLocationName: "H\u00f6gbysj\u00f6n",
  },
  { locationSignature: "H\u00f6ja", advertisedLocationName: "H\u00f6ja" },
  {
    locationSignature: "H\u00f6n",
    advertisedLocationName: "H\u00f6gdalen",
  },
  {
    locationSignature: "H\u00f6s",
    advertisedLocationName: "H\u00f6ssj\u00f6n",
  },
  { locationSignature: "H\u00f6y", advertisedLocationName: "H\u00f6gsby" },
  { locationSignature: "Ibe", advertisedLocationName: "Ivarsbj\u00f6rke" },
  { locationSignature: "Id", advertisedLocationName: "Iggesund" },
  { locationSignature: "Igt", advertisedLocationName: "Ingatorp" },
  { locationSignature: "Imr", advertisedLocationName: "Ingm\u00e5r" },
  { locationSignature: "In", advertisedLocationName: "Insj\u00f6n" },
  { locationSignature: "Ip", advertisedLocationName: "Igelstorp" },
  { locationSignature: "Ist", advertisedLocationName: "Is\u00e4tra" },
  { locationSignature: "It.bol", advertisedLocationName: "Bolzano" },
  { locationSignature: "It.ven", advertisedLocationName: "Venedig" },
  { locationSignature: "J", advertisedLocationName: "Jonsered \u00f6stra" },
  { locationSignature: "Jb", advertisedLocationName: "J\u00e4rbo" },
  { locationSignature: "Jbg", advertisedLocationName: "Johannisberg" },
  { locationSignature: "Jbk", advertisedLocationName: "J\u00e4dersbruk" },
  { locationSignature: "Jbo", advertisedLocationName: "Jordbro" },
  { locationSignature: "Jhl", advertisedLocationName: "Johannedal" },
  { locationSignature: "Jho", advertisedLocationName: "Jakobshyttan" },
  { locationSignature: "Jk", advertisedLocationName: "Jokkmokk" },
  { locationSignature: "Jkb", advertisedLocationName: "Jakobsberg" },
  { locationSignature: "Jla", advertisedLocationName: "J\u00e4rl\u00e5sa" },
  { locationSignature: "Jli", advertisedLocationName: "Jamtli" },
  { locationSignature: "Jlo", advertisedLocationName: "J\u00e4rlebo" },
  { locationSignature: "Jn", advertisedLocationName: "J\u00e4rna" },
  {
    locationSignature: "Jnt",
    advertisedLocationName: "J\u00e4rnetr\u00e4sk",
  },
  { locationSignature: "Jpn", advertisedLocationName: "J\u00e4rpen" },
  { locationSignature: "Jps", advertisedLocationName: "J\u00e4rp\u00e5s" },
  { locationSignature: "Jr", advertisedLocationName: "J\u00e4rvs\u00f6" },
  { locationSignature: "Jrn", advertisedLocationName: "J\u00f6rn" },
  {
    locationSignature: "Js",
    advertisedLocationName: "J\u00e4mtlands Sik\u00e5s",
  },
  { locationSignature: "Ju", advertisedLocationName: "Jularbo" },
  { locationSignature: "Jv", advertisedLocationName: "Jonsered" },
  { locationSignature: "Jy", advertisedLocationName: "Jenny" },
  { locationSignature: "J\u00e5", advertisedLocationName: "Ju\u00e5n" },
  {
    locationSignature: "J\u00e5r",
    advertisedLocationName: "J\u00f6n\u00e5ker",
  },
  {
    locationSignature: "J\u00e4f",
    advertisedLocationName: "J\u00e4rnforsen",
  },
  {
    locationSignature: "J\u00e4y",
    advertisedLocationName: "J\u00e4rl\u00f6vsby",
  },
  {
    locationSignature: "J\u00f6",
    advertisedLocationName: "J\u00f6nk\u00f6ping C",
  },
  {
    locationSignature: "J\u00f6gb",
    advertisedLocationName: "J\u00f6nk\u00f6pings godsbang\u00e5rd",
  },
  { locationSignature: "K", advertisedLocationName: "Katrineholm C" },
  { locationSignature: "Ka", advertisedLocationName: "Kattarp" },
  { locationSignature: "Kaa", advertisedLocationName: "Koijuvaara" },
  { locationSignature: "Kac", advertisedLocationName: "Kalmar C" },
  { locationSignature: "Kap", advertisedLocationName: "Karpalund" },
  { locationSignature: "Kas", advertisedLocationName: "Kalmar s\u00f6dra" },
  {
    locationSignature: "Kasd",
    advertisedLocationName: "Karlskoga Dalbacksgatan",
  },
  { locationSignature: "Kat", advertisedLocationName: "Kattisavan" },
  {
    locationSignature: "Ka\u00e4",
    advertisedLocationName: "Kattistr\u00e4sk",
  },
  { locationSignature: "Kb", advertisedLocationName: "Kungsbacka" },
  { locationSignature: "Kba", advertisedLocationName: "Korsberga" },
  { locationSignature: "Kbg", advertisedLocationName: "Karlsborg" },
  { locationSignature: "Kbn", advertisedLocationName: "Karbenning" },
  { locationSignature: "Kb\u00e4", advertisedLocationName: "Kolb\u00e4ck" },
  { locationSignature: "Kd", advertisedLocationName: "K\u00e5ger\u00f6d" },
  { locationSignature: "Kda", advertisedLocationName: "Krigslida" },
  { locationSignature: "Kde", advertisedLocationName: "Kode" },
  { locationSignature: "Ke", advertisedLocationName: "Karlberg" },
  { locationSignature: "Keb", advertisedLocationName: "Kejsarb\u00e4cken" },
  { locationSignature: "Kei", advertisedLocationName: "Ker\u00e4sjoki" },
  { locationSignature: "Kf", advertisedLocationName: "Kortfors" },
  { locationSignature: "Kfn", advertisedLocationName: "Kolforsen" },
  { locationSignature: "Kg", advertisedLocationName: "K\u00e4vlinge" },
  { locationSignature: "Kgd", advertisedLocationName: "Kungsg\u00e5rden" },
  { locationSignature: "Kgs", advertisedLocationName: "Kragen\u00e4s" },
  { locationSignature: "Kh", advertisedLocationName: "Karlshamn" },
  { locationSignature: "Khe", advertisedLocationName: "Hede" },
  { locationSignature: "Khn", advertisedLocationName: "Kristinehamn" },
  { locationSignature: "Khy", advertisedLocationName: "Klenshyttan" },
  {
    locationSignature: "Kh\u00e4",
    advertisedLocationName: "Kallh\u00e4ll",
  },
  { locationSignature: "Kia", advertisedLocationName: "Kirunavaara" },
  { locationSignature: "Kid", advertisedLocationName: "Kinnared" },
  { locationSignature: "Kil", advertisedLocationName: "Kil" },
  { locationSignature: "Kin", advertisedLocationName: "Kinna" },
  { locationSignature: "Kisa", advertisedLocationName: "Kisa" },
  { locationSignature: "Kju", advertisedLocationName: "Kjula" },
  {
    locationSignature: "Kj\u00e5",
    advertisedLocationName: "Katterj\u00e5kk",
  },
  { locationSignature: "Kl", advertisedLocationName: "Klippan" },
  { locationSignature: "Kla", advertisedLocationName: "Kumla" },
  {
    locationSignature: "Klbn",
    advertisedLocationName: "Klockarb\u00e4cken",
  },
  { locationSignature: "Klh", advertisedLocationName: "Kallholsfors" },
  { locationSignature: "Kll", advertisedLocationName: "K\u00e4llby" },
  { locationSignature: "Kln", advertisedLocationName: "K\u00e4larne" },
  { locationSignature: "Klr", advertisedLocationName: "Koler" },
  { locationSignature: "Kls", advertisedLocationName: "Kilafors" },
  { locationSignature: "Klv", advertisedLocationName: "Kilvo" },
  { locationSignature: "Klx", advertisedLocationName: "Kalix" },
  { locationSignature: "Km", advertisedLocationName: "Kilsmo" },
  { locationSignature: "Kmb", advertisedLocationName: "Kiruna" },
  { locationSignature: "Kms", advertisedLocationName: "Kimstad" },
  { locationSignature: "Kmy", advertisedLocationName: "Kummelby" },
  { locationSignature: "Kn", advertisedLocationName: "Knivsta" },
  { locationSignature: "Kng", advertisedLocationName: "Kvarn\u00e4ngen" },
  { locationSignature: "Knl", advertisedLocationName: "Knalleland" },
  { locationSignature: "Knp", advertisedLocationName: "Knapptorp" },
  { locationSignature: "Kns", advertisedLocationName: "Kolsn\u00e4s" },
  { locationSignature: "Kn\u00e4", advertisedLocationName: "Kn\u00e4red" },
  {
    locationSignature: "Ko",
    advertisedLocationName: "Kornsj\u00f6 gr\u00e4nsen",
  },
  { locationSignature: "Koe", advertisedLocationName: "Kolke" },
  { locationSignature: "Koj", advertisedLocationName: "Kosj\u00e4rv" },
  { locationSignature: "Kon", advertisedLocationName: "Kolm\u00e5rden" },
  { locationSignature: "Kos", advertisedLocationName: "Koskullskulle" },
  { locationSignature: "Ko\u00e4", advertisedLocationName: "Korsn\u00e4s" },
  { locationSignature: "Kp", advertisedLocationName: "K\u00f6ping" },
  { locationSignature: "Kpe", advertisedLocationName: "Kaisepakte" },
  { locationSignature: "Kpg", advertisedLocationName: "Kopparberg" },
  { locationSignature: "Kpkv", advertisedLocationName: "Korpklev" },
  { locationSignature: "Kpm", advertisedLocationName: "K\u00f6pmannebro" },
  { locationSignature: "Kr", advertisedLocationName: "Killeberg" },
  { locationSignature: "Krb", advertisedLocationName: "Karsb\u00e4cken" },
  { locationSignature: "Krd", advertisedLocationName: "K\u00e5llered" },
  { locationSignature: "Krf", advertisedLocationName: "Kramfors" },
  { locationSignature: "Kri", advertisedLocationName: "Karungi" },
  { locationSignature: "Krl", advertisedLocationName: "Kringlan" },
  { locationSignature: "Krm", advertisedLocationName: "Krokom" },
  { locationSignature: "Krn", advertisedLocationName: "Krampen" },
  { locationSignature: "Kroj", advertisedLocationName: "Krokom OJ" },
  {
    locationSignature: "Kr\u00f6b",
    advertisedLocationName: "Kr\u00f6kesbo",
  },
  { locationSignature: "Ks", advertisedLocationName: "Karlstad C" },
  { locationSignature: "Ksc", advertisedLocationName: "Karlstad C" },
  {
    locationSignature: "Ksac",
    advertisedLocationName: "Karlskoga centrum",
  },
  { locationSignature: "Ksg", advertisedLocationName: "Karlsberg" },
  { locationSignature: "Kst", advertisedLocationName: "Kistinge" },
  { locationSignature: "Ksu", advertisedLocationName: "Kvicksund" },
  { locationSignature: "Ksus", advertisedLocationName: "Kvicksund" },
  { locationSignature: "Ks\u00f6", advertisedLocationName: "Karsj\u00f6" },
  { locationSignature: "Kt", advertisedLocationName: "Kapellet" },
  { locationSignature: "Ktm", advertisedLocationName: "Kaitum" },
  {
    locationSignature: "Kt\u00e4",
    advertisedLocationName: "Korstr\u00e4sk",
  },
  { locationSignature: "Kud", advertisedLocationName: "Klingerud" },
  { locationSignature: "Kv", advertisedLocationName: "Krokvik" },
  { locationSignature: "Kva", advertisedLocationName: "Koskivaara" },
  { locationSignature: "Kvf", advertisedLocationName: "Kvillsfors" },
  { locationSignature: "Kvh", advertisedLocationName: "Klevshult" },
  { locationSignature: "Kvi", advertisedLocationName: "Kvidinge" },
  {
    locationSignature: "Kv\u00e4",
    advertisedLocationName: "V\u00e4lsviken",
  },
  {
    locationSignature: "Kv\u00f6",
    advertisedLocationName: "Kvarnsj\u00f6",
  },
  { locationSignature: "Kx", advertisedLocationName: "Kalixfors" },
  {
    locationSignature: "K\u00e5",
    advertisedLocationName: "Koppar\u00e5sen",
  },
  {
    locationSignature: "K\u00e5b",
    advertisedLocationName: "K\u00e5bdalis",
  },
  { locationSignature: "K\u00e4", advertisedLocationName: "K\u00e4lleryd" },
  {
    locationSignature: "K\u00e4b",
    advertisedLocationName: "K\u00e4rreberga",
  },
  { locationSignature: "K\u00e4e", advertisedLocationName: "K\u00e4llene" },
  {
    locationSignature: "K\u00e4n",
    advertisedLocationName: "Kungs\u00e4ngen",
  },
  { locationSignature: "K\u00e4p", advertisedLocationName: "K\u00e4ppsta" },
  {
    locationSignature: "K\u00e4r",
    advertisedLocationName: "K\u00e4rrgruvan",
  },
  {
    locationSignature: "K\u00e4v",
    advertisedLocationName: "K\u00e4lvattnet",
  },
  { locationSignature: "K\u00f6a", advertisedLocationName: "K\u00f6nsa" },
  {
    locationSignature: "K\u00f6p",
    advertisedLocationName: "K\u00f6pingebro",
  },
  { locationSignature: "K\u00f6r", advertisedLocationName: "Kungs\u00f6r" },
  { locationSignature: "La", advertisedLocationName: "Ludvika" },
  { locationSignature: "Lab", advertisedLocationName: "Lappberg" },
  { locationSignature: "Laov", advertisedLocationName: "Laholm" },
  { locationSignature: "Lb", advertisedLocationName: "Lingbo" },
  { locationSignature: "Ld", advertisedLocationName: "Lindesberg" },
  { locationSignature: "Ldl", advertisedLocationName: "Lidlund" },
  { locationSignature: "Ldo", advertisedLocationName: "Lindome" },
  { locationSignature: "Le", advertisedLocationName: "Lule\u00e5 C" },
  { locationSignature: "Le.k", advertisedLocationName: "Karlsvik" },
  { locationSignature: "Lea", advertisedLocationName: "Leksboda" },
  { locationSignature: "Lek", advertisedLocationName: "Lekarekulle" },
  { locationSignature: "Len", advertisedLocationName: "Lene" },
  {
    locationSignature: "Les",
    advertisedLocationName: "L\u00f6d\u00f6se S\u00f6dra",
  },
  { locationSignature: "Let", advertisedLocationName: "Lilla Edet" },
  { locationSignature: "Lg", advertisedLocationName: "L\u00e4ggesta" },
  { locationSignature: "Lgb", advertisedLocationName: "L\u00e5ngbron" },
  { locationSignature: "Lgd", advertisedLocationName: "Ledsg\u00e5rd" },
  { locationSignature: "Lgm", advertisedLocationName: "Linghem" },
  { locationSignature: "Lh", advertisedLocationName: "Lammhult" },
  { locationSignature: "Lhr", advertisedLocationName: "Lillhamra" },
  { locationSignature: "Lia", advertisedLocationName: "Lillaryd" },
  { locationSignature: "Lin", advertisedLocationName: "Lina\u00e4lv" },
  { locationSignature: "Lis", advertisedLocationName: "Liseberg" },
  { locationSignature: "Lit", advertisedLocationName: "Lit" },
  { locationSignature: "Lj", advertisedLocationName: "Ljungskile" },
  { locationSignature: "Lju", advertisedLocationName: "Ljung" },
  {
    locationSignature: "Ljun",
    advertisedLocationName: "Ljungaverks lastplats",
  },
  { locationSignature: "Ljv", advertisedLocationName: "Ljungaverk" },
  { locationSignature: "Lkm", advertisedLocationName: "L\u00f6kom" },
  { locationSignature: "Lkn", advertisedLocationName: "Lindekullen" },
  { locationSignature: "Lkp", advertisedLocationName: "Lidk\u00f6ping" },
  {
    locationSignature: "Lk\u00e4",
    advertisedLocationName: "Lakatr\u00e4sk",
  },
  { locationSignature: "Lk\u00f6", advertisedLocationName: "Landskrona" },
  { locationSignature: "Lln", advertisedLocationName: "Linddalen" },
  { locationSignature: "Lm", advertisedLocationName: "Liljeholmen" },
  { locationSignature: "Lma", advertisedLocationName: "Lomma" },
  { locationSignature: "Lme", advertisedLocationName: "Limmared" },
  { locationSignature: "Lmm", advertisedLocationName: "Lemmestr\u00f6" },
  { locationSignature: "Lmn", advertisedLocationName: "L\u00e5ngviksmon" },
  { locationSignature: "Lmo", advertisedLocationName: "L\u00f6cknamon" },
  { locationSignature: "Lms", advertisedLocationName: "Lomselen\u00e4s" },
  { locationSignature: "Lnk", advertisedLocationName: "Lernacken" },
  { locationSignature: "Lns", advertisedLocationName: "Lidn\u00e4s" },
  { locationSignature: "Lnv", advertisedLocationName: "L\u00e5ngvattnet" },
  { locationSignature: "Ln\u00e5", advertisedLocationName: "Lugn\u00e5s" },
  { locationSignature: "Lo", advertisedLocationName: "Lessebo" },
  { locationSignature: "Loka", advertisedLocationName: "Loka" },
  { locationSignature: "Lom", advertisedLocationName: "Lomsmyren" },
  { locationSignature: "Lot", advertisedLocationName: "Lottefors" },
  { locationSignature: "Lp", advertisedLocationName: "Link\u00f6ping C" },
  { locationSignature: "Lpk", advertisedLocationName: "Lapptr\u00e4sk" },
  { locationSignature: "Lr", advertisedLocationName: "Lerum" },
  { locationSignature: "Lrd", advertisedLocationName: "Landeryd" },
  { locationSignature: "Lre", advertisedLocationName: "L\u00e4stringe" },
  { locationSignature: "Lrg", advertisedLocationName: "Laduberg" },
  { locationSignature: "Lrp", advertisedLocationName: "Lockarp" },
  { locationSignature: "Lrt", advertisedLocationName: "Lerot" },
  { locationSignature: "Ls", advertisedLocationName: "Ljusdal" },
  { locationSignature: "Lsb", advertisedLocationName: "Ljusne bruk" },
  { locationSignature: "Lsd", advertisedLocationName: "Leksand" },
  { locationSignature: "Lse", advertisedLocationName: "Ljusne" },
  {
    locationSignature: "Lsj",
    advertisedLocationName: "L\u00e5ngsj\u00f6n",
  },
  { locationSignature: "Lsl", advertisedLocationName: "L\u00e5ngsele" },
  { locationSignature: "Lsn", advertisedLocationName: "Losesj\u00f6n" },
  {
    locationSignature: "Lsp",
    advertisedLocationName: "Lycksele industrisp\u00e5r",
  },
  { locationSignature: "Ls\u00e5", advertisedLocationName: "Ljus\u00e5" },
  { locationSignature: "Ltj", advertisedLocationName: "Lilltj\u00e4ra" },
  {
    locationSignature: "Ltk",
    advertisedLocationName: "L\u00e5ngtr\u00e4sk",
  },
  { locationSignature: "Ltr", advertisedLocationName: "Loster" },
  { locationSignature: "Lu", advertisedLocationName: "Lund C" },
  { locationSignature: "Lub", advertisedLocationName: "Lundby" },
  { locationSignature: "Lup", advertisedLocationName: "Lunnarp" },
  {
    locationSignature: "Lu\u00e4",
    advertisedLocationName: "Lubbotr\u00e4sk",
  },
  { locationSignature: "Lvd", advertisedLocationName: "L\u00f6vliden" },
  { locationSignature: "Lve", advertisedLocationName: "Lovene" },
  { locationSignature: "Ly", advertisedLocationName: "Lycksele" },
  { locationSignature: "Lyd", advertisedLocationName: "Lyrestad" },
  { locationSignature: "Lye", advertisedLocationName: "Lyse" },
  {
    locationSignature: "Lyg",
    advertisedLocationName: "Lyckseles\u00e5gen",
  },
  { locationSignature: "Lys", advertisedLocationName: "Lysekil" },
  { locationSignature: "Lyt", advertisedLocationName: "Lycksele timmer" },
  { locationSignature: "Lyv", advertisedLocationName: "Lysvik" },
  { locationSignature: "L\u00e5", advertisedLocationName: "Lax\u00e5" },
  {
    locationSignature: "L\u00e5k",
    advertisedLocationName: "L\u00e5ktatj\u00e5kka",
  },
  {
    locationSignature: "L\u00f6",
    advertisedLocationName: "L\u00f6rstrand",
  },
  {
    locationSignature: "L\u00f6b",
    advertisedLocationName: "L\u00f6vberga",
  },
  {
    locationSignature: "L\u00f6n",
    advertisedLocationName: "L\u00f6nneberga",
  },
  {
    locationSignature: "L\u00f6\u00f6",
    advertisedLocationName: "L\u00f6d\u00f6se",
  },
  { locationSignature: "M", advertisedLocationName: "Malm\u00f6 C" },
  { locationSignature: "Ma", advertisedLocationName: "Mattmar" },
  { locationSignature: "Maa", advertisedLocationName: "Marma" },
  { locationSignature: "Maj", advertisedLocationName: "Maj" },
  { locationSignature: "Mar", advertisedLocationName: "Marieholm" },
  { locationSignature: "Mb", advertisedLocationName: "Mobodarne" },
  { locationSignature: "Mbv", advertisedLocationName: "Murbergsviken" },
  { locationSignature: "Mby", advertisedLocationName: "Malmby" },
  {
    locationSignature: "Mb\u00e4",
    advertisedLocationName: "Malmb\u00e4ck",
  },
  { locationSignature: "Mdl", advertisedLocationName: "Moradal" },
  { locationSignature: "Mdm", advertisedLocationName: "Mariedamm" },
  { locationSignature: "Mdn", advertisedLocationName: "M\u00f6lndal" },
  {
    locationSignature: "Md\u00f6",
    advertisedLocationName: "M\u00f6lndals \u00f6vre",
  },
  { locationSignature: "Me", advertisedLocationName: "Marmaverken" },
  { locationSignature: "Meh", advertisedLocationName: "Mehedeby" },
  { locationSignature: "Mfj", advertisedLocationName: "Mockfj\u00e4rd" },
  { locationSignature: "Mfn", advertisedLocationName: "Munkfloh\u00f6gen" },
  { locationSignature: "Mfs", advertisedLocationName: "Malungsfors" },
  {
    locationSignature: "Mgb",
    advertisedLocationName: "Malm\u00f6 godsbang\u00e5rd",
  },
  { locationSignature: "Mh", advertisedLocationName: "Moholm" },
  { locationSignature: "Mhn", advertisedLocationName: "Myrheden" },
  { locationSignature: "Mi", advertisedLocationName: "M\u00f6rsil" },
  { locationSignature: "Mia", advertisedLocationName: "Maria" },
  { locationSignature: "Mim", advertisedLocationName: "Mimer" },
  { locationSignature: "Mip", advertisedLocationName: "Milletorp" },
  { locationSignature: "Mjv", advertisedLocationName: "Morj\u00e4rv" },
  { locationSignature: "Mk", advertisedLocationName: "Murjek" },
  { locationSignature: "Mkl", advertisedLocationName: "Munkedal" },
  { locationSignature: "Mklh", advertisedLocationName: "Munkedals hamn" },
  { locationSignature: "Mkln", advertisedLocationName: "Munkedals nedre" },
  { locationSignature: "Ml", advertisedLocationName: "Mellerud" },
  { locationSignature: "Mlb", advertisedLocationName: "Mellby" },
  { locationSignature: "Mlf", advertisedLocationName: "Meselefors" },
  { locationSignature: "Mlg", advertisedLocationName: "Malung" },
  {
    locationSignature: "Mlgf",
    advertisedLocationName: "Malung Folkh\u00f6gskola",
  },
  { locationSignature: "Mlgg", advertisedLocationName: "Malung Gymnasium" },
  { locationSignature: "Ml\u00f6", advertisedLocationName: "Mell\u00f6sa" },
  { locationSignature: "Mnd", advertisedLocationName: "Mariannelund" },
  { locationSignature: "Mo", advertisedLocationName: "Moheda" },
  { locationSignature: "Mog", advertisedLocationName: "Mo grindar" },
  { locationSignature: "Mon", advertisedLocationName: "Mon" },
  { locationSignature: "Morp", advertisedLocationName: "Munktorp" },
  { locationSignature: "Mos", advertisedLocationName: "Moskosel" },
  { locationSignature: "Mot", advertisedLocationName: "Motala C" },
  { locationSignature: "Moy", advertisedLocationName: "Mollaryd" },
  { locationSignature: "Mp", advertisedLocationName: "M\u00f6lltorp" },
  { locationSignature: "Mpb", advertisedLocationName: "Persborg" },
  { locationSignature: "Mr", advertisedLocationName: "M\u00e4rsta" },
  { locationSignature: "Mrc", advertisedLocationName: "Mora" },
  {
    locationSignature: "Mrai",
    advertisedLocationName: "Mora kommuns industrisp\u00e5r",
  },
  { locationSignature: "Mras", advertisedLocationName: "Morastrand" },
  { locationSignature: "Mrd", advertisedLocationName: "Markaryd" },
  { locationSignature: "Mrd.ax", advertisedLocationName: "Axhult" },
  { locationSignature: "Mrh", advertisedLocationName: "Marsvinsholm" },
  { locationSignature: "Mrl", advertisedLocationName: "Mora lasarett" },
  { locationSignature: "Mrm", advertisedLocationName: "Marma" },
  { locationSignature: "Mrs", advertisedLocationName: "Morshyttan" },
  { locationSignature: "Mru", advertisedLocationName: "M\u00f6rrum" },
  { locationSignature: "Ms", advertisedLocationName: "Mos\u00e5s" },
  {
    locationSignature: "Msb",
    advertisedLocationName: "M\u00f6nster\u00e5s bruk",
  },
  { locationSignature: "Msj", advertisedLocationName: "Malmsj\u00f6" },
  { locationSignature: "Msl", advertisedLocationName: "Mellansel" },
  { locationSignature: "Msn", advertisedLocationName: "Medskogsheden" },
  {
    locationSignature: "Mss",
    advertisedLocationName: "M\u00f6nster\u00e5s",
  },
  { locationSignature: "Mst", advertisedLocationName: "Mariestad" },
  {
    locationSignature: "Ms\u00f6",
    advertisedLocationName: "Mellansj\u00f6",
  },
  { locationSignature: "Mt", advertisedLocationName: "Mantorp" },
  { locationSignature: "Mtm", advertisedLocationName: "Maitum" },
  { locationSignature: "Mtn", advertisedLocationName: "Mertainen" },
  { locationSignature: "Mu", advertisedLocationName: "Mullsj\u00f6" },
  { locationSignature: "Mud", advertisedLocationName: "Mosselund" },
  { locationSignature: "My", advertisedLocationName: "Mj\u00f6lby" },
  { locationSignature: "Myk", advertisedLocationName: "M\u00f6lnlycke" },
  { locationSignature: "Myn", advertisedLocationName: "Myrbacken" },
  { locationSignature: "Myra", advertisedLocationName: "Myra" },
  {
    locationSignature: "M\u00e5",
    advertisedLocationName: "Morgong\u00e5va",
  },
  { locationSignature: "M\u00e5a", advertisedLocationName: "M\u00e5lilla" },
  { locationSignature: "M\u00e5p", advertisedLocationName: "M\u00e5nsarp" },
  {
    locationSignature: "M\u00e4k",
    advertisedLocationName: "M\u00e4rb\u00e4ck",
  },
  { locationSignature: "M\u00f6", advertisedLocationName: "M\u00f6lnbo" },
  {
    locationSignature: "M\u00f6a",
    advertisedLocationName: "M\u00f6rlunda",
  },
  { locationSignature: "M\u00f6l", advertisedLocationName: "M\u00f6rtsal" },
  { locationSignature: "M\u00f6r", advertisedLocationName: "M\u00f6rarp" },
  { locationSignature: "N", advertisedLocationName: "N\u00e4ssj\u00f6 C" },
  { locationSignature: "Nb", advertisedLocationName: "N\u00e4sberg" },
  { locationSignature: "Nba", advertisedLocationName: "Nyboda" },
  { locationSignature: "Nbg", advertisedLocationName: "Norberg" },
  { locationSignature: "Nbr", advertisedLocationName: "Nybybruk" },
  { locationSignature: "Nbt", advertisedLocationName: "Norra bantorget" },
  { locationSignature: "Nd", advertisedLocationName: "N\u00e4ssundet" },
  { locationSignature: "Ndt", advertisedLocationName: "Norrsundet" },
  {
    locationSignature: "Ndv",
    advertisedLocationName: "Norsesund v\u00e4stra",
  },
  { locationSignature: "Ng", advertisedLocationName: "Norrh\u00f6g" },
  {
    locationSignature: "Ngd",
    advertisedLocationName: "Nyn\u00e4sg\u00e5rd",
  },
  { locationSignature: "Nh", advertisedLocationName: "Norsholm" },
  { locationSignature: "Nhr", advertisedLocationName: "Norrahammar" },
  { locationSignature: "Njb", advertisedLocationName: "Njurundabommen" },
  { locationSignature: "Nk", advertisedLocationName: "Nyk\u00f6ping C" },
  { locationSignature: "Nka", advertisedLocationName: "Nykroppa" },
  {
    locationSignature: "Nkp",
    advertisedLocationName: "N\u00e4rkes Kvarntorp",
  },
  { locationSignature: "Nkr", advertisedLocationName: "Norra Kikkejaur" },
  {
    locationSignature: "Nks",
    advertisedLocationName: "Nyk\u00f6ping s\u00f6dra",
  },
  {
    locationSignature: "Nkt",
    advertisedLocationName: "N\u00e4verk\u00e4rret",
  },
  { locationSignature: "Nkv", advertisedLocationName: "Nykvarn" },
  { locationSignature: "Nl", advertisedLocationName: "Nelhammar" },
  { locationSignature: "Nld", advertisedLocationName: "Nyland" },
  { locationSignature: "Nly", advertisedLocationName: "Nolby" },
  { locationSignature: "Nml", advertisedLocationName: "Niemisel" },
  { locationSignature: "No.hld", advertisedLocationName: "Halden" },
  { locationSignature: "No.kat", advertisedLocationName: "Katterat" },
  { locationSignature: "No.kvg", advertisedLocationName: "Kongsvinger" },
  {
    locationSignature: "No.lil",
    advertisedLocationName: "Lillestr\u00f6m",
  },
  {
    locationSignature: "No.lls",
    advertisedLocationName: "Lillestr\u00f6m",
  },
  { locationSignature: "No.nk", advertisedLocationName: "Narvik" },
  { locationSignature: "No.osl", advertisedLocationName: "Oslo" },
  { locationSignature: "No.rom", advertisedLocationName: "Rombak" },
  { locationSignature: "No.sbo", advertisedLocationName: "Sarpsborg" },
  { locationSignature: "No.sms", advertisedLocationName: "Straumsnes" },
  {
    locationSignature: "No.s\u00f6s",
    advertisedLocationName: "S\u00f6sterbekk",
  },
  { locationSignature: "No.tnd", advertisedLocationName: "Trondheim" },
  { locationSignature: "Noe", advertisedLocationName: "Norrmj\u00f6le" },
  { locationSignature: "Nog", advertisedLocationName: "Nordmaling" },
  { locationSignature: "Nol", advertisedLocationName: "Nol" },
  { locationSignature: "Non", advertisedLocationName: "Norrsj\u00f6n" },
  { locationSignature: "Norl", advertisedLocationName: "Nordlunda" },
  { locationSignature: "Nosk", advertisedLocationName: "Nordskogen" },
  { locationSignature: "Nr", advertisedLocationName: "Norrk\u00f6ping C" },
  { locationSignature: "Nrs", advertisedLocationName: "Norrfors" },
  { locationSignature: "Nrt", advertisedLocationName: "Nuortikon" },
  { locationSignature: "Ns", advertisedLocationName: "Norsesund" },
  { locationSignature: "Nsj", advertisedLocationName: "Nyckelsj\u00f6n" },
  { locationSignature: "Nst", advertisedLocationName: "Stockholms norra" },
  {
    locationSignature: "Ns\u00f6",
    advertisedLocationName: "Nedansj\u00f6",
  },
  { locationSignature: "Nta", advertisedLocationName: "Nacksta" },
  { locationSignature: "Nte", advertisedLocationName: "Nuorttagievlle" },
  { locationSignature: "Ntv", advertisedLocationName: "Nattavaara" },
  { locationSignature: "Nvk", advertisedLocationName: "Norrviken" },
  { locationSignature: "Nvl", advertisedLocationName: "Norra Valbo" },
  { locationSignature: "Nvn", advertisedLocationName: "Notviken" },
  { locationSignature: "Ny", advertisedLocationName: "Nyhem" },
  { locationSignature: "Nyb", advertisedLocationName: "Nybro" },
  { locationSignature: "Nyc", advertisedLocationName: "Nyn\u00e4shamn" },
  { locationSignature: "Nyf", advertisedLocationName: "Nyfors" },
  { locationSignature: "Ny\u00e5", advertisedLocationName: "Ny\u00e5ker" },
  { locationSignature: "N\u00e5s", advertisedLocationName: "N\u00e5s" },
  { locationSignature: "N\u00e4", advertisedLocationName: "N\u00e4lden" },
  {
    locationSignature: "N\u00e4t",
    advertisedLocationName: "N\u00e4ttraby",
  },
  { locationSignature: "N\u00f6e", advertisedLocationName: "N\u00f6dinge" },
  { locationSignature: "O", advertisedLocationName: "Osby" },
  { locationSignature: "Ob", advertisedLocationName: "Ockelbo" },
  { locationSignature: "Of", advertisedLocationName: "Olofstr\u00f6m" },
  { locationSignature: "Oh", advertisedLocationName: "Oskarshamn" },
  { locationSignature: "Ol", advertisedLocationName: "Oleby" },
  { locationSignature: "Om", advertisedLocationName: "Oskarstr\u00f6m" },
  { locationSignature: "Omb", advertisedLocationName: "Ombenning" },
  { locationSignature: "Ope", advertisedLocationName: "Ope" },
  { locationSignature: "Oph", advertisedLocationName: "Opphem" },
  { locationSignature: "Or", advertisedLocationName: "Olskroken" },
  { locationSignature: "Orn", advertisedLocationName: "Orn\u00e4s" },
  { locationSignature: "Ors", advertisedLocationName: "Orsa" },
  { locationSignature: "Ory", advertisedLocationName: "Ormaryd" },
  { locationSignature: "Os", advertisedLocationName: "Orrskog" },
  { locationSignature: "Osl", advertisedLocationName: "Osl\u00e4ttfors" },
  { locationSignature: "Os\u00f6", advertisedLocationName: "Ovansj\u00f6" },
  { locationSignature: "Ot", advertisedLocationName: "Ottebol" },
  { locationSignature: "Ox", advertisedLocationName: "Oxie" },
  { locationSignature: "Oxd", advertisedLocationName: "Oxel\u00f6sund" },
  { locationSignature: "Oxm", advertisedLocationName: "Oxmyran" },
  { locationSignature: "P", advertisedLocationName: "Partille" },
  { locationSignature: "Pb", advertisedLocationName: "Pr\u00e4ssebo" },
  { locationSignature: "Pbg", advertisedLocationName: "Persberg" },
  { locationSignature: "Pc", advertisedLocationName: "Polcirkeln" },
  { locationSignature: "Pea", advertisedLocationName: "Peuravaara" },
  { locationSignature: "Phm", advertisedLocationName: "Peberholm" },
  {
    locationSignature: "Pii",
    advertisedLocationName: "Pitk\u00e4j\u00e4rvi",
  },
  { locationSignature: "Pj", advertisedLocationName: "Porjus" },
  { locationSignature: "Pl", advertisedLocationName: "Pilgrimstad" },
  { locationSignature: "Plm", advertisedLocationName: "Paulistr\u00f6m" },
  { locationSignature: "Pm", advertisedLocationName: "Pr\u00e4stmon" },
  { locationSignature: "Pt", advertisedLocationName: "Perstorp" },
  { locationSignature: "Pt\u00e5", advertisedLocationName: "Pite\u00e5" },
  { locationSignature: "P\u00e5", advertisedLocationName: "P\u00e5lsboda" },
  { locationSignature: "P\u00e5a", advertisedLocationName: "P\u00e5arp" },
  {
    locationSignature: "P\u00e4b",
    advertisedLocationName: "Pite\u00e4lvsbron",
  },
  { locationSignature: "P\u00f6b", advertisedLocationName: "P\u00f6lsebo" },
  { locationSignature: "R", advertisedLocationName: "Rotebro" },
  { locationSignature: "Ram", advertisedLocationName: "Raml\u00f6sa" },
  { locationSignature: "Ran", advertisedLocationName: "Rian" },
  { locationSignature: "Ras", advertisedLocationName: "Ralings\u00e5s" },
  { locationSignature: "Rb", advertisedLocationName: "Ronneby" },
  { locationSignature: "Rbh", advertisedLocationName: "Rabbalshede" },
  { locationSignature: "Rbk", advertisedLocationName: "R\u00e5b\u00e4ck" },
  { locationSignature: "Rbo", advertisedLocationName: "R\u00f6stbo" },
  { locationSignature: "Rby", advertisedLocationName: "Rockneby" },
  {
    locationSignature: "Rb\u00e4",
    advertisedLocationName: "Repb\u00e4cken",
  },
  { locationSignature: "Rd", advertisedLocationName: "R\u00e4vlanda" },
  { locationSignature: "Rdg", advertisedLocationName: "Rydsg\u00e5rd" },
  { locationSignature: "Rf", advertisedLocationName: "Rimforsa" },
  { locationSignature: "Rfb", advertisedLocationName: "Rimforsa brygga" },
  { locationSignature: "Rft", advertisedLocationName: "Reftele" },
  { locationSignature: "Rgn", advertisedLocationName: "Riksgr\u00e4nsen" },
  {
    locationSignature: "Riks",
    advertisedLocationName: "Riksgr\u00e4ns Swe-Dk.",
  },
  { locationSignature: "Rjn", advertisedLocationName: "R\u00f6jan" },
  { locationSignature: "Rjn.vd", advertisedLocationName: "Vemdalen" },
  { locationSignature: "Rk", advertisedLocationName: "R\u00f6rvik" },
  { locationSignature: "Rka", advertisedLocationName: "Rinkaby" },
  { locationSignature: "Rke", advertisedLocationName: "Rekarne" },
  {
    locationSignature: "Rk\u00f6",
    advertisedLocationName: "Rocksj\u00f6n",
  },
  {
    locationSignature: "Rl\u00e5",
    advertisedLocationName: "R\u00e4lls\u00e5",
  },
  { locationSignature: "Rmh", advertisedLocationName: "R\u00e4mshyttan" },
  { locationSignature: "Rmn", advertisedLocationName: "Ramn\u00e4s" },
  { locationSignature: "Rmtp", advertisedLocationName: "Regumatorp" },
  { locationSignature: "Rob", advertisedLocationName: "Roback" },
  { locationSignature: "Rog", advertisedLocationName: "Roseng\u00e5rd" },
  { locationSignature: "Ros", advertisedLocationName: "Ross\u00f6n" },
  { locationSignature: "Rps", advertisedLocationName: "Ripats" },
  { locationSignature: "Rs", advertisedLocationName: "Rosersberg" },
  { locationSignature: "Rsi", advertisedLocationName: "R\u00e5tsi" },
  { locationSignature: "Rsn", advertisedLocationName: "Rensj\u00f6n" },
  { locationSignature: "Rs\u00f6", advertisedLocationName: "Ramsj\u00f6" },
  { locationSignature: "Rt", advertisedLocationName: "Ransta" },
  { locationSignature: "Rte", advertisedLocationName: "R\u00f6ste" },
  { locationSignature: "Rts", advertisedLocationName: "Rottneros" },
  { locationSignature: "Ru", advertisedLocationName: "Ragunda" },
  { locationSignature: "Rud", advertisedLocationName: "Ruda" },
  { locationSignature: "Ruk", advertisedLocationName: "Rundvik" },
  { locationSignature: "Rum", advertisedLocationName: "Runemo" },
  { locationSignature: "Rus", advertisedLocationName: "Runsala" },
  { locationSignature: "Rut", advertisedLocationName: "Rautas" },
  { locationSignature: "Rv", advertisedLocationName: "R\u00e4ttvik" },
  { locationSignature: "Ry", advertisedLocationName: "Rosshyttan" },
  { locationSignature: "Ryb", advertisedLocationName: "Ryssj\u00f6brink" },
  { locationSignature: "Rye", advertisedLocationName: "Rynge" },
  { locationSignature: "Ryg", advertisedLocationName: "Ryggen" },
  { locationSignature: "Ryk", advertisedLocationName: "Ryd\u00f6bruk" },
  { locationSignature: "Rym", advertisedLocationName: "Rydaholm" },
  { locationSignature: "Ryr", advertisedLocationName: "Ryr" },
  {
    locationSignature: "Ry\u00e4",
    advertisedLocationName: "Rydeb\u00e4ck",
  },
  {
    locationSignature: "R\u00e5g",
    advertisedLocationName: "R\u00e5gsveden",
  },
  {
    locationSignature: "R\u00e5n",
    advertisedLocationName: "R\u00e5sj\u00f6n",
  },
  {
    locationSignature: "R\u00e5s",
    advertisedLocationName: "R\u00e5skogen",
  },
  {
    locationSignature: "R\u00e5\u00f6",
    advertisedLocationName: "R\u00e5g\u00f6n",
  },
  { locationSignature: "R\u00e4p", advertisedLocationName: "R\u00e4ppe" },
  { locationSignature: "R\u00f6", advertisedLocationName: "R\u00f6dberg" },
  {
    locationSignature: "R\u00f6n",
    advertisedLocationName: "R\u00f6nninge",
  },
  {
    locationSignature: "R\u00f6p",
    advertisedLocationName: "R\u00f6rstorp",
  },
  { locationSignature: "S", advertisedLocationName: "Sveg" },
  { locationSignature: "Sae", advertisedLocationName: "Sandared" },
  { locationSignature: "Saf", advertisedLocationName: "Staffanstorp" },
  { locationSignature: "Sak", advertisedLocationName: "Sandb\u00e4ck" },
  { locationSignature: "Sam", advertisedLocationName: "Samnan" },
  { locationSignature: "Sarp", advertisedLocationName: "Sannarp" },
  { locationSignature: "Sas", advertisedLocationName: "Salsta" },
  { locationSignature: "Sau", advertisedLocationName: "Stora Tuna" },
  {
    locationSignature: "Sa\u00f6",
    advertisedLocationName: "Skabersj\u00f6",
  },
  { locationSignature: "Sba", advertisedLocationName: "Spannarboda" },
  { locationSignature: "Sbe", advertisedLocationName: "Salub\u00f6le" },
  { locationSignature: "Sbi", advertisedLocationName: "Storbl\u00e5liden" },
  { locationSignature: "Sbk", advertisedLocationName: "Stenbacken" },
  { locationSignature: "Sbl", advertisedLocationName: "Sk\u00e4rblacka" },
  {
    locationSignature: "Sbu",
    advertisedLocationName: "Str\u00f6msn\u00e4sbruk",
  },
  { locationSignature: "Sby", advertisedLocationName: "Norra Sunderbyn" },
  {
    locationSignature: "Sb\u00e4",
    advertisedLocationName: "S\u00e5gb\u00e4cken",
  },
  { locationSignature: "Sci", advertisedLocationName: "Stockholm City" },
  { locationSignature: "Sde", advertisedLocationName: "Sk\u00f6ldinge" },
  { locationSignature: "Sdm", advertisedLocationName: "Sandmon" },
  {
    locationSignature: "Sdn\u00e4",
    advertisedLocationName: "Sandsj\u00f6n\u00e4s",
  },
  {
    locationSignature: "Sdr",
    advertisedLocationName: "Sm\u00e5landsstenar",
  },
  { locationSignature: "Sdy", advertisedLocationName: "Smedby" },
  { locationSignature: "Sea", advertisedLocationName: "Svedala" },
  { locationSignature: "See", advertisedLocationName: "Skene" },
  { locationSignature: "Sege", advertisedLocationName: "Sege" },
  { locationSignature: "Sel", advertisedLocationName: "S\u00e4vedalen" },
  { locationSignature: "Sen", advertisedLocationName: "Stavreviken" },
  { locationSignature: "Set", advertisedLocationName: "Stensundberget" },
  { locationSignature: "Sf", advertisedLocationName: "Storfors" },
  { locationSignature: "Sfl", advertisedLocationName: "S\u00e4ffle" },
  { locationSignature: "Sfn", advertisedLocationName: "Storflon" },
  { locationSignature: "Sg", advertisedLocationName: "Stehag" },
  { locationSignature: "Sgm", advertisedLocationName: "Segmon" },
  {
    locationSignature: "Sgs",
    advertisedLocationName: "Str\u00e4ngn\u00e4s",
  },
  { locationSignature: "Sg\u00e4", advertisedLocationName: "Slagn\u00e4s" },
  { locationSignature: "Sh", advertisedLocationName: "Stj\u00e4rnhov" },
  { locationSignature: "Shm", advertisedLocationName: "S\u00f6derhamn" },
  { locationSignature: "Shr", advertisedLocationName: "Surahammar" },
  { locationSignature: "Shv", advertisedLocationName: "S\u00f6derhamn" },
  { locationSignature: "Si", advertisedLocationName: "Simrishamn" },
  { locationSignature: "Sic", advertisedLocationName: "Silverdalen" },
  { locationSignature: "Sid", advertisedLocationName: "Silverdalens bruk" },
  { locationSignature: "Sie", advertisedLocationName: "St\u00e4vie" },
  { locationSignature: "Sif", advertisedLocationName: "Sikfors" },
  { locationSignature: "Sii", advertisedLocationName: "Silinge" },
  { locationSignature: "Sik", advertisedLocationName: "Svartvik" },
  {
    locationSignature: "Sir",
    advertisedLocationName: "Sorsele industrisp\u00e5r",
  },
  { locationSignature: "Sjka", advertisedLocationName: "Sjisjka" },
  { locationSignature: "Sk", advertisedLocationName: "Sk\u00f6vde C" },
  { locationSignature: "Skb", advertisedLocationName: "Skinnskatteberg" },
  { locationSignature: "Skbl", advertisedLocationName: "Sk\u00e4lebol" },
  { locationSignature: "Skby", advertisedLocationName: "Skavstaby" },
  { locationSignature: "Ske", advertisedLocationName: "Skee" },
  { locationSignature: "Skg", advertisedLocationName: "Skog\u00e5s" },
  { locationSignature: "Skk", advertisedLocationName: "Sk\u00f6nvik" },
  { locationSignature: "Skl", advertisedLocationName: "Skellefte\u00e5" },
  { locationSignature: "Skm", advertisedLocationName: "Stenkumla" },
  { locationSignature: "Skms", advertisedLocationName: "Skymossen" },
  { locationSignature: "Skn", advertisedLocationName: "Sk\u00e4nninge" },
  { locationSignature: "Skog", advertisedLocationName: "Skoghall" },
  { locationSignature: "Skr", advertisedLocationName: "Sk\u00e5re" },
  { locationSignature: "Skrp", advertisedLocationName: "Skogstorp" },
  { locationSignature: "Skrt", advertisedLocationName: "Sk\u00e4ret" },
  { locationSignature: "Skru", advertisedLocationName: "Sk\u00e5llerud" },
  { locationSignature: "Skv", advertisedLocationName: "Skebokvarn" },
  { locationSignature: "Sk\u00e4", advertisedLocationName: "Sk\u00e4stra" },
  { locationSignature: "Sl", advertisedLocationName: "Sala" },
  { locationSignature: "Slbk", advertisedLocationName: "Solbacka" },
  { locationSignature: "Sle", advertisedLocationName: "Sl\u00e4tte" },
  { locationSignature: "Slg", advertisedLocationName: "S\u00e4llinge" },
  { locationSignature: "Slj", advertisedLocationName: "Selsj\u00f6n" },
  { locationSignature: "Slm", advertisedLocationName: "Solum" },
  {
    locationSignature: "Sln\u00e4",
    advertisedLocationName: "Selln\u00e4s",
  },
  { locationSignature: "Sm", advertisedLocationName: "Sandhem" },
  { locationSignature: "Smd", advertisedLocationName: "Str\u00f6mstad" },
  { locationSignature: "Smj", advertisedLocationName: "Smedjebacken" },
  { locationSignature: "Smn", advertisedLocationName: "Sommen" },
  { locationSignature: "Smo", advertisedLocationName: "Str\u00f6msbro" },
  { locationSignature: "Smp", advertisedLocationName: "Smedstorp" },
  { locationSignature: "Smt", advertisedLocationName: "Simonstorp" },
  {
    locationSignature: "Smu",
    advertisedLocationName: "Sm\u00e5lands Burseryd",
  },
  { locationSignature: "Sm\u00e5", advertisedLocationName: "Sime\u00e5" },
  { locationSignature: "Sn", advertisedLocationName: "Stenkullen" },
  { locationSignature: "Snb", advertisedLocationName: "Snickarbo" },
  { locationSignature: "Sne", advertisedLocationName: "Sandarne" },
  {
    locationSignature: "Sng\u00e5",
    advertisedLocationName: "Sings\u00e5n",
  },
  { locationSignature: "Snl", advertisedLocationName: "Skanlog" },
  { locationSignature: "Snt", advertisedLocationName: "Snyten" },
  { locationSignature: "Snu", advertisedLocationName: "Stenungsund" },
  { locationSignature: "Snv", advertisedLocationName: "Sandviken" },
  {
    locationSignature: "Sn\u00f6",
    advertisedLocationName: "Stensj\u00f6n",
  },
  { locationSignature: "So", advertisedLocationName: "Solna" },
  { locationSignature: "Soa", advertisedLocationName: "Stordalen" },
  {
    locationSignature: "Sod",
    advertisedLocationName: "Stockholm Odenplan",
  },
  { locationSignature: "Soe", advertisedLocationName: "Stolpen" },
  { locationSignature: "Sol", advertisedLocationName: "Sollentuna" },
  { locationSignature: "Som", advertisedLocationName: "Stormyran" },
  { locationSignature: "Sop", advertisedLocationName: "Skorped" },
  { locationSignature: "So\u00f6", advertisedLocationName: "Storsj\u00f6" },
  { locationSignature: "Sp", advertisedLocationName: "Sparreholm" },
  { locationSignature: "Sph", advertisedLocationName: "Skeppshult" },
  { locationSignature: "Spj", advertisedLocationName: "Spjutsbygd" },
  { locationSignature: "Spjb", advertisedLocationName: "Spjutb\u00e4cken" },
  { locationSignature: "Spk", advertisedLocationName: "Spr\u00e4ngsviken" },
  { locationSignature: "Spn", advertisedLocationName: "Stolpstugan" },
  {
    locationSignature: "Spy",
    advertisedLocationName: "Sp\u00e4nnarhyttan",
  },
  { locationSignature: "Sp\u00e5", advertisedLocationName: "Sp\u00e5nga" },
  { locationSignature: "Sr", advertisedLocationName: "Stor\u00e5" },
  {
    locationSignature: "Sre",
    advertisedLocationName: "S\u00f6derb\u00e4rke",
  },
  {
    locationSignature: "Srn",
    advertisedLocationName: "S\u00f6rtj\u00e4rn",
  },
  { locationSignature: "Sro", advertisedLocationName: "Storebro" },
  { locationSignature: "Srp", advertisedLocationName: "Skurup" },
  { locationSignature: "Srr", advertisedLocationName: "Skattk\u00e4rr" },
  { locationSignature: "Srs", advertisedLocationName: "Sorsele" },
  { locationSignature: "Srt", advertisedLocationName: "Str\u00f6mtorp" },
  { locationSignature: "Sru", advertisedLocationName: "Skruv" },
  { locationSignature: "Srv", advertisedLocationName: "Storvreta" },
  { locationSignature: "Ss", advertisedLocationName: "Stenstorp" },
  { locationSignature: "Ssa", advertisedLocationName: "Sj\u00f6sa" },
  { locationSignature: "Ssh", advertisedLocationName: "Str\u00f6msholm" },
  {
    locationSignature: "Sst",
    advertisedLocationName: "Stockholms s\u00f6dra",
  },
  {
    locationSignature: "Ss\u00e4",
    advertisedLocationName: "Segers\u00e4ng",
  },
  { locationSignature: "St", advertisedLocationName: "S\u00e4ter" },
  { locationSignature: "Sta", advertisedLocationName: "Stuvsta" },
  { locationSignature: "Stb", advertisedLocationName: "St\u00e5ngby" },
  { locationSignature: "Std", advertisedLocationName: "St\u00f6de" },
  { locationSignature: "Ste", advertisedLocationName: "Svarte" },
  { locationSignature: "Sten", advertisedLocationName: "Stegsskogen" },
  { locationSignature: "Sth", advertisedLocationName: "Stora H\u00f6ga" },
  {
    locationSignature: "Sti",
    advertisedLocationName: "Storbergets industrisp\u00e5r",
  },
  { locationSignature: "Stje", advertisedLocationName: "Stjerneskolan" },
  { locationSignature: "Stk", advertisedLocationName: "Siktr\u00e4sk" },
  { locationSignature: "Stl", advertisedLocationName: "St\u00e4lldalen" },
  { locationSignature: "Stlv", advertisedLocationName: "Stora Levene" },
  { locationSignature: "Sto", advertisedLocationName: "Stortr\u00e4sk" },
  {
    locationSignature: "Ston",
    advertisedLocationName: "Stockviksverkens nedre",
  },
  {
    locationSignature: "Stp",
    advertisedLocationName: "Sv\u00e5gertorp / Malm\u00f6 Syd",
  },
  { locationSignature: "Str", advertisedLocationName: "Storlien" },
  { locationSignature: "Sts", advertisedLocationName: "Storsund" },
  { locationSignature: "Stu", advertisedLocationName: "Stugsund" },
  { locationSignature: "Stv", advertisedLocationName: "Stavre" },
  {
    locationSignature: "St\u00e5",
    advertisedLocationName: "Sollefte\u00e5",
  },
  {
    locationSignature: "St\u00e4",
    advertisedLocationName: "Sandtr\u00e4sk",
  },
  {
    locationSignature: "St\u00f6",
    advertisedLocationName: "Str\u00e5ngsj\u00f6",
  },
  { locationSignature: "Sub", advertisedLocationName: "Sundbyberg" },
  { locationSignature: "Suc", advertisedLocationName: "Sundsvall C" },
  { locationSignature: "Sue", advertisedLocationName: "Surte" },
  { locationSignature: "Suf", advertisedLocationName: "Sturefors" },
  { locationSignature: "Sui", advertisedLocationName: "Skuckuviken" },
  { locationSignature: "Sum", advertisedLocationName: "Storuman" },
  { locationSignature: "Sun", advertisedLocationName: "Sunne" },
  { locationSignature: "Sur", advertisedLocationName: "Skutsk\u00e4r" },
  { locationSignature: "Sus", advertisedLocationName: "Sunderby sjukhus" },
  {
    locationSignature: "Suv",
    advertisedLocationName: "Sundsvall V\u00e4stra",
  },
  { locationSignature: "Sv", advertisedLocationName: "Storvik" },
  {
    locationSignature: "Svc",
    advertisedLocationName: "Svenstavik centrum",
  },
  { locationSignature: "Sve", advertisedLocationName: "S\u00e4ve" },
  { locationSignature: "Svg", advertisedLocationName: "Svensh\u00f6gen" },
  { locationSignature: "Svi", advertisedLocationName: "S\u00f6dra Vi" },
  { locationSignature: "Svj", advertisedLocationName: "Svedje" },
  { locationSignature: "Svk", advertisedLocationName: "Svenstavik" },
  { locationSignature: "Svt", advertisedLocationName: "S\u00e4vast" },
  { locationSignature: "Svv", advertisedLocationName: "Svappavaara" },
  { locationSignature: "Sv\u00e5", advertisedLocationName: "Svart\u00e5" },
  {
    locationSignature: "Sv\u00f6",
    advertisedLocationName: "Skytts Vemmerl\u00f6v",
  },
  { locationSignature: "Sy", advertisedLocationName: "Stockaryd" },
  { locationSignature: "Syd", advertisedLocationName: "Skillingaryd" },
  { locationSignature: "Sym", advertisedLocationName: "Skrytmyran" },
  { locationSignature: "Syt", advertisedLocationName: "Skyttorp" },
  {
    locationSignature: "S\u00e5n",
    advertisedLocationName: "Sten\u00e5sen",
  },
  {
    locationSignature: "S\u00e5rs",
    advertisedLocationName: "S\u00f6r\u00e5kers s\u00f6dra",
  },
  {
    locationSignature: "S\u00e4",
    advertisedLocationName: "S\u00e4vsj\u00f6",
  },
  {
    locationSignature: "S\u00e4g",
    advertisedLocationName: "S\u00f6r\u00e4ng",
  },
  {
    locationSignature: "S\u00e4n",
    advertisedLocationName: "S\u00e4vastklinten",
  },
  { locationSignature: "S\u00e4p", advertisedLocationName: "Sk\u00e4rpan" },
  {
    locationSignature: "S\u00e4r",
    advertisedLocationName: "S\u00e4ven\u00e4s rangerbang\u00e5rd",
  },
  {
    locationSignature: "S\u00e4u",
    advertisedLocationName: "Sunn\u00e4sbruk",
  },
  {
    locationSignature: "S\u00e4v",
    advertisedLocationName: "S\u00e4ven\u00e4s",
  },
  { locationSignature: "S\u00e4y", advertisedLocationName: "S\u00e4by" },
  {
    locationSignature: "S\u00f6c",
    advertisedLocationName: "S\u00f6dert\u00e4lje centrum",
  },
  {
    locationSignature: "S\u00f6d",
    advertisedLocationName: "S\u00f6dert\u00e4lje hamn",
  },
  {
    locationSignature: "S\u00f6dy",
    advertisedLocationName: "S\u00f6derby",
  },
  {
    locationSignature: "S\u00f6g",
    advertisedLocationName: "S\u00f6lvesborg",
  },
  { locationSignature: "S\u00f6k", advertisedLocationName: "St\u00f6cke" },
  {
    locationSignature: "S\u00f6ka",
    advertisedLocationName: "S\u00f6dert\u00e4lje kanal",
  },
  {
    locationSignature: "S\u00f6la",
    advertisedLocationName: "S\u00f6sdala",
  },
  {
    locationSignature: "S\u00f6u",
    advertisedLocationName: "S\u00f6dert\u00e4lje Syd (SL)",
  },
  {
    locationSignature: "S\u00f6v",
    advertisedLocationName: "Skelleftehamns \u00f6vre",
  },
  {
    locationSignature: "S\u00f6\u00f6",
    advertisedLocationName: "S\u00f6dert\u00e4lje Syd",
  },
  { locationSignature: "T", advertisedLocationName: "T\u00f6reboda" },
  { locationSignature: "Tag", advertisedLocationName: "Taberg" },
  { locationSignature: "Tb", advertisedLocationName: "Tillberga" },
  { locationSignature: "Tba", advertisedLocationName: "Tystberga" },
  { locationSignature: "Tbn", advertisedLocationName: "Torbacken" },
  { locationSignature: "Tbo", advertisedLocationName: "Tobo" },
  { locationSignature: "Tbr", advertisedLocationName: "Tibro" },
  { locationSignature: "Tby", advertisedLocationName: "Tandsbyn" },
  { locationSignature: "Tdj", advertisedLocationName: "Tr\u00f6dje" },
  { locationSignature: "Teo", advertisedLocationName: "Torebo" },
  { locationSignature: "Tet", advertisedLocationName: "Tolikberget" },
  { locationSignature: "Tgp", advertisedLocationName: "T\u00e5garp" },
  { locationSignature: "Tgs", advertisedLocationName: "Tr\u00e5ngstad" },
  { locationSignature: "Th", advertisedLocationName: "Tenhult" },
  { locationSignature: "Thd", advertisedLocationName: "Tallhed" },
  { locationSignature: "Thl", advertisedLocationName: "Tornhill" },
  { locationSignature: "Thm", advertisedLocationName: "Tr\u00e4skholm" },
  {
    locationSignature: "Thn",
    advertisedLocationName: "Trollh\u00e4ttan C",
  },
  {
    locationSignature: "Th\u00f6",
    advertisedLocationName: "Treh\u00f6rningsj\u00f6",
  },
  { locationSignature: "Tim", advertisedLocationName: "Timsfors" },
  { locationSignature: "Tip", advertisedLocationName: "Tierp" },
  { locationSignature: "Tj", advertisedLocationName: "Tjunnaryd" },
  { locationSignature: "Tjs", advertisedLocationName: "Tjugonde slussen" },
  { locationSignature: "Tjsk", advertisedLocationName: "Tjustskolan" },
  {
    locationSignature: "Tj\u00e4",
    advertisedLocationName: "Tj\u00e4rnvik",
  },
  { locationSignature: "Tl", advertisedLocationName: "Tall\u00e5sen" },
  { locationSignature: "Tlg", advertisedLocationName: "T\u00e4llberg" },
  { locationSignature: "Tli", advertisedLocationName: "Tomelilla" },
  { locationSignature: "Tlt", advertisedLocationName: "Tolita" },
  {
    locationSignature: "Tm",
    advertisedLocationName: "Tomteboda bang\u00e5rd",
  },
  {
    locationSignature: "Tm\u00f6",
    advertisedLocationName: "Tomteboda \u00f6vre",
  },
  { locationSignature: "Tn", advertisedLocationName: "Tannefors" },
  { locationSignature: "Tnk", advertisedLocationName: "Tornetr\u00e4sk" },
  { locationSignature: "Tns", advertisedLocationName: "Tran\u00e5s" },
  { locationSignature: "Tnu", advertisedLocationName: "Tanum" },
  { locationSignature: "To", advertisedLocationName: "Torpshammar" },
  { locationSignature: "Tof", advertisedLocationName: "Tofta" },
  { locationSignature: "Top", advertisedLocationName: "Torp\u00e5kra" },
  { locationSignature: "Tot", advertisedLocationName: "Toresta" },
  { locationSignature: "Tou", advertisedLocationName: "Torup" },
  { locationSignature: "Toy", advertisedLocationName: "Torsby" },
  { locationSignature: "Tp", advertisedLocationName: "Teckomatorp" },
  { locationSignature: "Tpu", advertisedLocationName: "Tappudden" },
  { locationSignature: "Trd", advertisedLocationName: "Traryd" },
  { locationSignature: "Tre", advertisedLocationName: "Trekanten" },
  { locationSignature: "Trg", advertisedLocationName: "Trelleborg" },
  { locationSignature: "Tri", advertisedLocationName: "Triangeln" },
  { locationSignature: "Trm", advertisedLocationName: "Trolmen" },
  { locationSignature: "Tr\u00e5", advertisedLocationName: "Timr\u00e5" },
  { locationSignature: "Ts", advertisedLocationName: "Tungelsta" },
  {
    locationSignature: "Tsg",
    advertisedLocationName: "T\u00e5gsj\u00f6berg",
  },
  { locationSignature: "Tsl", advertisedLocationName: "Tisselskog" },
  {
    locationSignature: "Ts\u00e5",
    advertisedLocationName: "Tors\u00e5ker",
  },
  { locationSignature: "Ttu", advertisedLocationName: "Tortuna" },
  { locationSignature: "Tu", advertisedLocationName: "Tumba" },
  { locationSignature: "Tud", advertisedLocationName: "Tunadal" },
  { locationSignature: "Tul", advertisedLocationName: "Tullinge" },
  { locationSignature: "Tun", advertisedLocationName: "Tunneby" },
  {
    locationSignature: "Tvb",
    advertisedLocationName: "Tv\u00e4r\u00e5b\u00e4ck",
  },
  { locationSignature: "Tvd", advertisedLocationName: "Torved" },
  {
    locationSignature: "Tv\u00e4",
    advertisedLocationName: "Tv\u00e4r\u00e5lund",
  },
  { locationSignature: "Ty", advertisedLocationName: "Tyringe" },
  { locationSignature: "Tye", advertisedLocationName: "Tyllered" },
  {
    locationSignature: "T\u00e5d",
    advertisedLocationName: "Tr\u00e5ngsund",
  },
  {
    locationSignature: "T\u00e5v",
    advertisedLocationName: "Tr\u00e5ngsviken",
  },
  { locationSignature: "T\u00e4l", advertisedLocationName: "T\u00e4lle" },
  { locationSignature: "T\u00f6", advertisedLocationName: "Tj\u00f6rnarp" },
  { locationSignature: "T\u00f6e", advertisedLocationName: "T\u00f6sse" },
  { locationSignature: "T\u00f6v", advertisedLocationName: "T\u00f6va" },
  { locationSignature: "U", advertisedLocationName: "Uppsala C" },
  { locationSignature: "Udl", advertisedLocationName: "Ulriksdal" },
  { locationSignature: "Uf", advertisedLocationName: "Ulriksfors" },
  { locationSignature: "Uly", advertisedLocationName: "Ulvshyttan" },
  { locationSignature: "Un", advertisedLocationName: "Unders\u00e5ker" },
  { locationSignature: "Una", advertisedLocationName: "Uppsala norra" },
  { locationSignature: "Up", advertisedLocationName: "Upp\u00e5kra" },
  {
    locationSignature: "Upv",
    advertisedLocationName: "Upplands V\u00e4sby",
  },
  { locationSignature: "Utp", advertisedLocationName: "Ulvstorp" },
  { locationSignature: "Uts", advertisedLocationName: "Utsikten" },
  { locationSignature: "Uv", advertisedLocationName: "Uddevalla C" },
  { locationSignature: "Uvn", advertisedLocationName: "Ursviken" },
  { locationSignature: "U\u00e5", advertisedLocationName: "Ume\u00e5 C" },
  {
    locationSignature: "U\u00e5gb",
    advertisedLocationName: "Ume\u00e5 godsbang\u00e5rd",
  },
  {
    locationSignature: "U\u00e5\u00f6",
    advertisedLocationName: "Ume\u00e5 \u00f6stra",
  },
  {
    locationSignature: "U\u00f6",
    advertisedLocationName: "Uddevalla \u00d6stra",
  },
  { locationSignature: "V", advertisedLocationName: "V\u00e4rnamo" },
  { locationSignature: "Vaa", advertisedLocationName: "Vara" },
  { locationSignature: "Vad", advertisedLocationName: "Vad" },
  { locationSignature: "Vag", advertisedLocationName: "Varg\u00f6n" },
  { locationSignature: "Vaj", advertisedLocationName: "Varjistr\u00e4sk" },
  { locationSignature: "Vb", advertisedLocationName: "Varberg" },
  {
    locationSignature: "Vbd",
    advertisedLocationName: "V\u00e4stra Bodarne",
  },
  {
    locationSignature: "Vby",
    advertisedLocationName: "V\u00e4nn\u00e4sby",
  },
  {
    locationSignature: "Vdb",
    advertisedLocationName: "V\u00e4rmlands Bro",
  },
  { locationSignature: "Vdi", advertisedLocationName: "Veddige" },
  { locationSignature: "Vdn", advertisedLocationName: "Vindeln" },
  { locationSignature: "Vdv", advertisedLocationName: "Vedev\u00e5g" },
  { locationSignature: "Ve", advertisedLocationName: "V\u00e4se" },
  {
    locationSignature: "Veas",
    advertisedLocationName: "Velanda S\u00f6dra",
  },
  { locationSignature: "Ved", advertisedLocationName: "Vedum" },
  { locationSignature: "Vf", advertisedLocationName: "Vartofta" },
  { locationSignature: "Vfa", advertisedLocationName: "Vissefj\u00e4rda" },
  { locationSignature: "Vfo", advertisedLocationName: "Viskafors" },
  { locationSignature: "Vg", advertisedLocationName: "V\u00e4nersborg C" },
  { locationSignature: "Vga", advertisedLocationName: "Vega" },
  { locationSignature: "Vgd", advertisedLocationName: "Vaggeryd" },
  {
    locationSignature: "Vg\u00e5",
    advertisedLocationName: "V\u00e5rg\u00e5rda",
  },
  { locationSignature: "Vh", advertisedLocationName: "Vegeholm" },
  { locationSignature: "Vha", advertisedLocationName: "Vattholma" },
  { locationSignature: "Vhd", advertisedLocationName: "Vagnh\u00e4rad" },
  {
    locationSignature: "Vhe",
    advertisedLocationName: "V\u00e4sterhaninge",
  },
  { locationSignature: "Vhy", advertisedLocationName: "Vikmanshyttan" },
  { locationSignature: "Via", advertisedLocationName: "Via" },
  { locationSignature: "Vib", advertisedLocationName: "Vimmerby" },
  { locationSignature: "Vibh", advertisedLocationName: "Vimmerby Hamra" },
  {
    locationSignature: "Vid",
    advertisedLocationName: "V\u00e4stra Ingelstad",
  },
  { locationSignature: "Vik", advertisedLocationName: "Vikersvik" },
  { locationSignature: "Vim", advertisedLocationName: "Vimnarp" },
  { locationSignature: "Vip", advertisedLocationName: "Viarp" },
  { locationSignature: "Visk", advertisedLocationName: "Viskan" },
  { locationSignature: "Vj", advertisedLocationName: "Vassijaure" },
  { locationSignature: "Vjm", advertisedLocationName: "Vojm\u00e5n" },
  { locationSignature: "Vk", advertisedLocationName: "V\u00e4stervik" },
  { locationSignature: "Vka", advertisedLocationName: "Vika" },
  { locationSignature: "Vl", advertisedLocationName: "Vallsta" },
  { locationSignature: "Vlb", advertisedLocationName: "Vilhelmsborg" },
  { locationSignature: "Vld", advertisedLocationName: "Vinlidsberg" },
  { locationSignature: "Vln", advertisedLocationName: "Valln\u00e4s" },
  { locationSignature: "Vm", advertisedLocationName: "Vattjom" },
  { locationSignature: "Vma", advertisedLocationName: "Vilhelmina" },
  { locationSignature: "Vman", advertisedLocationName: "Vilhelmina norra" },
  {
    locationSignature: "Vmat",
    advertisedLocationName: "Vilhelmina timmer",
  },
  { locationSignature: "Vme", advertisedLocationName: "VME" },
  { locationSignature: "Vmu", advertisedLocationName: "Villersmuren" },
  { locationSignature: "Vn", advertisedLocationName: "V\u00e4rtan" },
  { locationSignature: "Vna", advertisedLocationName: "Voxna" },
  { locationSignature: "Vns", advertisedLocationName: "V\u00e4nn\u00e4s" },
  { locationSignature: "Vnt", advertisedLocationName: "Vackern\u00e4set" },
  { locationSignature: "Vn\u00f6", advertisedLocationName: "Vinn\u00f6" },
  { locationSignature: "Vo", advertisedLocationName: "Vansbro" },
  { locationSignature: "Vpm", advertisedLocationName: "Varpemossen" },
  { locationSignature: "Vr", advertisedLocationName: "Ving\u00e5ker" },
  { locationSignature: "Vre", advertisedLocationName: "Vrena" },
  { locationSignature: "Vrn", advertisedLocationName: "Verveln" },
  { locationSignature: "Vru", advertisedLocationName: "Vekerum" },
  {
    locationSignature: "Vr\u00f6",
    advertisedLocationName: "V\u00e4r\u00f6",
  },
  { locationSignature: "Vs", advertisedLocationName: "Vislanda" },
  { locationSignature: "Vsd", advertisedLocationName: "Vikingstad" },
  { locationSignature: "Vsg", advertisedLocationName: "Valskog" },
  { locationSignature: "Vsj", advertisedLocationName: "Vittsj\u00f6" },
  { locationSignature: "Vsk", advertisedLocationName: "Vuonoskogen" },
  { locationSignature: "Vso", advertisedLocationName: "Virsbo" },
  { locationSignature: "Vs\u00f6", advertisedLocationName: "Viresj\u00f6" },
  { locationSignature: "Vt", advertisedLocationName: "Vretstorp" },
  { locationSignature: "Vta", advertisedLocationName: "Vetlanda" },
  { locationSignature: "Vtn", advertisedLocationName: "Vattn\u00e4s" },
  { locationSignature: "Vto", advertisedLocationName: "V\u00e4stra Torup" },
  { locationSignature: "Vtv", advertisedLocationName: "Vitvattnet" },
  {
    locationSignature: "Vt\u00e5",
    advertisedLocationName: "Vit\u00e5fors",
  },
  { locationSignature: "Vv", advertisedLocationName: "Vallvik" },
  {
    locationSignature: "V\u00e5",
    advertisedLocationName: "V\u00e4ster\u00e5s C",
  },
  { locationSignature: "V\u00e5b", advertisedLocationName: "V\u00e5lberg" },
  {
    locationSignature: "V\u00e5k",
    advertisedLocationName: "Vall\u00e5kra",
  },
  {
    locationSignature: "V\u00e5n",
    advertisedLocationName: "V\u00e4ster\u00e5s norra",
  },
  {
    locationSignature: "V\u00e5v",
    advertisedLocationName: "V\u00e4ster\u00e5s v\u00e4stra",
  },
  { locationSignature: "V\u00e4", advertisedLocationName: "V\u00e4ring" },
  {
    locationSignature: "V\u00e4d",
    advertisedLocationName: "V\u00e4tteryd",
  },
  {
    locationSignature: "V\u00e4n",
    advertisedLocationName: "V\u00e4nn\u00e4s norra",
  },
  {
    locationSignature: "V\u00e4t",
    advertisedLocationName: "V\u00e4stra \u00c4mtervik",
  },
  {
    locationSignature: "V\u00e4y",
    advertisedLocationName: "V\u00e4steraspby",
  },
  {
    locationSignature: "V\u00f6",
    advertisedLocationName: "V\u00e4xj\u00f6",
  },
  { locationSignature: "V\u00f6v", advertisedLocationName: "Vinsl\u00f6v" },
  { locationSignature: "wAga", advertisedLocationName: "Aga" },
  { locationSignature: "wAll", advertisedLocationName: "All\u00e9parken" },
  { locationSignature: "wAlv", advertisedLocationName: "Alvik" },
  { locationSignature: "wBag", advertisedLocationName: "Baggeby" },
  { locationSignature: "wBod", advertisedLocationName: "Bodal" },
  { locationSignature: "wBvi", advertisedLocationName: "Brevik" },
  {
    locationSignature: "wG\u00e5b",
    advertisedLocationName: "G\u00e5shaga brygga",
  },
  {
    locationSignature: "wG\u00e5h",
    advertisedLocationName: "G\u00e5shaga",
  },
  {
    locationSignature: "wHlt",
    advertisedLocationName: "H\u00f6glandstorget",
  },
  {
    locationSignature: "wH\u00f6b",
    advertisedLocationName: "H\u00f6gberga",
  },
  { locationSignature: "Wibe", advertisedLocationName: "Wibe" },
  {
    locationSignature: "wKlv",
    advertisedLocationName: "Kl\u00f6verv\u00e4gen",
  },
  { locationSignature: "wKoa", advertisedLocationName: "Kottla" },
  {
    locationSignature: "wK\u00e4p",
    advertisedLocationName: "K\u00e4ppala",
  },
  { locationSignature: "wLab", advertisedLocationName: "Larsberg" },
  {
    locationSignature: "wLnb",
    advertisedLocationName: "Lundsbrunn kurort",
  },
  { locationSignature: "wNob", advertisedLocationName: "Nockeby" },
  { locationSignature: "wNot", advertisedLocationName: "Nockeby torg" },
  { locationSignature: "wOll", advertisedLocationName: "Olovslund" },
  { locationSignature: "wRos", advertisedLocationName: "Ropsten" },
  { locationSignature: "wSa", advertisedLocationName: "Skara" },
  {
    locationSignature: "wSkr",
    advertisedLocationName: "Sk\u00e4rs\u00e4tra",
  },
  { locationSignature: "wSms", advertisedLocationName: "Smedsl\u00e4tten" },
  { locationSignature: "wSsu", advertisedLocationName: "Sissehult" },
  { locationSignature: "wTov", advertisedLocationName: "Torsvik" },
  {
    locationSignature: "wT\u00e5s",
    advertisedLocationName: "Tall\u00e5s Grusgropsv\u00e4xel",
  },
  {
    locationSignature: "w\u00c5lG",
    advertisedLocationName: "\u00c5lstens G\u00e5rd",
  },
  {
    locationSignature: "w\u00c5ls",
    advertisedLocationName: "\u00c5lstensgatan",
  },
  { locationSignature: "xA", advertisedLocationName: "Anten" },
  { locationSignature: "xAkm", advertisedLocationName: "Ankarsrum" },
  { locationSignature: "xAlg", advertisedLocationName: "Almunge" },
  { locationSignature: "xAlp", advertisedLocationName: "Altorp" },
  { locationSignature: "xAre", advertisedLocationName: "Arninge" },
  { locationSignature: "xArl", advertisedLocationName: "Arelid" },
  { locationSignature: "xAsk", advertisedLocationName: "Aska" },
  { locationSignature: "xB", advertisedLocationName: "B\u00e4rby" },
  { locationSignature: "xBb", advertisedLocationName: "Brobacka" },
  { locationSignature: "xBl\u00e4", advertisedLocationName: "Bl\u00e4gda" },
  { locationSignature: "xBn", advertisedLocationName: "Bor Norra" },
  {
    locationSignature: "xBvv",
    advertisedLocationName: "Br\u00e5vallav\u00e4gen",
  },
  {
    locationSignature: "xB\u00e4l",
    advertisedLocationName: "B\u00e4llsta",
  },
  {
    locationSignature: "xB\u00f6p",
    advertisedLocationName: "Br\u00f6sarp",
  },
  {
    locationSignature: "xB\u00f6s",
    advertisedLocationName: "Br\u00f6sarp s\u00f6dra",
  },
  { locationSignature: "xDir", advertisedLocationName: "Dirhuvden" },
  { locationSignature: "xDje", advertisedLocationName: "Djursholms Ekeby" },
  {
    locationSignature: "xDj\u00f6",
    advertisedLocationName: "Djursholms \u00d6sby",
  },
  { locationSignature: "xDkn", advertisedLocationName: "Dala kvarn" },
  { locationSignature: "xDo", advertisedLocationName: "Delsbo" },
  { locationSignature: "xEkg", advertisedLocationName: "Ekskogen" },
  { locationSignature: "xEkh", advertisedLocationName: "Ekebohult" },
  { locationSignature: "xEkn", advertisedLocationName: "Eken" },
  { locationSignature: "xEnb", advertisedLocationName: "Enebyberg" },
  { locationSignature: "xErn", advertisedLocationName: "Ervalla NBVJ" },
  { locationSignature: "xEsa", advertisedLocationName: "Ensta" },
  { locationSignature: "xEvb", advertisedLocationName: "Erstaviksbadet" },
  { locationSignature: "xEyd", advertisedLocationName: "Elgaryd" },
  { locationSignature: "xF", advertisedLocationName: "Faringe" },
  { locationSignature: "xFb", advertisedLocationName: "Funbo" },
  { locationSignature: "xFgr", advertisedLocationName: "Fagerr\u00f6r" },
  { locationSignature: "xFia", advertisedLocationName: "Finkelboda" },
  { locationSignature: "xFie", advertisedLocationName: "Fisk\u00e5sen" },
  { locationSignature: "xFl", advertisedLocationName: "Fyrislund" },
  { locationSignature: "xFla", advertisedLocationName: "Flaten" },
  {
    locationSignature: "xFm\u00f6",
    advertisedLocationName: "Forsm\u00f6llan",
  },
  { locationSignature: "xFos", advertisedLocationName: "Fredriksfors" },
  { locationSignature: "xFsa", advertisedLocationName: "Forsa" },
  { locationSignature: "xFsd", advertisedLocationName: "Fagersand" },
  { locationSignature: "xFsu", advertisedLocationName: "Fr\u00f6sunda" },
  {
    locationSignature: "xFs\u00e4",
    advertisedLocationName: "Fisks\u00e4tra",
  },
  {
    locationSignature: "xF\u00e5t",
    advertisedLocationName: "F\u00e5rhult",
  },
  { locationSignature: "xGa", advertisedLocationName: "Gunsta" },
  { locationSignature: "xGby", advertisedLocationName: "Gimarpsby" },
  { locationSignature: "xGhn", advertisedLocationName: "Gripsholmsviken" },
  { locationSignature: "xGim", advertisedLocationName: "Gimarp" },
  { locationSignature: "xGnl", advertisedLocationName: "Gn\u00f6tteln" },
  {
    locationSignature: "xGpf",
    advertisedLocationName: "Galoppf\u00e4ltet",
  },
  {
    locationSignature: "xGr",
    advertisedLocationName: "Gr\u00e4fsn\u00e4s",
  },
  { locationSignature: "Xgu", advertisedLocationName: "Gud\u00e5" },
  { locationSignature: "xGyb", advertisedLocationName: "Gyllebosj\u00f6" },
  { locationSignature: "xGyt", advertisedLocationName: "Gyttorp" },
  {
    locationSignature: "xG\u00e5v",
    advertisedLocationName: "G\u00e5rdveda",
  },
  { locationSignature: "xHap", advertisedLocationName: "Hultarp" },
  { locationSignature: "xHbo", advertisedLocationName: "Hybo" },
  { locationSignature: "xHdt", advertisedLocationName: "Hedlandet" },
  { locationSignature: "xHed", advertisedLocationName: "Henriksdal" },
  { locationSignature: "Xhel", advertisedLocationName: "Hell" },
  {
    locationSignature: "xHg\u00e4",
    advertisedLocationName: "H\u00e4gern\u00e4s",
  },
  {
    locationSignature: "xHhf",
    advertisedLocationName: "Hultsfreds Hembygdspark",
  },
  { locationSignature: "xHhn", advertisedLocationName: "Hjorthagen" },
  {
    locationSignature: "xHjm",
    advertisedLocationName: "Hjort\u00f6str\u00f6m",
  },
  { locationSignature: "xHjt", advertisedLocationName: "Hjorted" },
  {
    locationSignature: "xHkl",
    advertisedLocationName: "Hudiksvall (xHkl)",
  },
  { locationSignature: "xHpf", advertisedLocationName: "Hultsfred (xHpf)" },
  { locationSignature: "Xhra", advertisedLocationName: "Hegra" },
  { locationSignature: "xHy", advertisedLocationName: "Hesselby" },
  {
    locationSignature: "xH\u00e4s",
    advertisedLocationName: "Hultan\u00e4s",
  },
  {
    locationSignature: "xH\u00f6n",
    advertisedLocationName: "H\u00e4rn\u00f6n",
  },
  {
    locationSignature: "xH\u00f6o",
    advertisedLocationName: "H\u00f6sj\u00f6mo",
  },
  { locationSignature: "xIgb", advertisedLocationName: "Igelboda" },
  { locationSignature: "xIpr", advertisedLocationName: "Impregneringen" },
  { locationSignature: "xJ", advertisedLocationName: "J\u00e4rle" },
  { locationSignature: "xJbn", advertisedLocationName: "Jagbacken" },
  {
    locationSignature: "xJ\u00e5s",
    advertisedLocationName: "J\u00e4dra\u00e5s",
  },
  { locationSignature: "Xkat", advertisedLocationName: "Katterat" },
  { locationSignature: "xKgl", advertisedLocationName: "Kragstalund" },
  { locationSignature: "xKh", advertisedLocationName: "K\u00e4llarhalsen" },
  { locationSignature: "xKht", advertisedLocationName: "Kambshagtorp" },
  { locationSignature: "xKpr", advertisedLocationName: "Kopper\u00e5" },
  { locationSignature: "xK\u00e5r", advertisedLocationName: "K\u00e5rsta" },
  { locationSignature: "xL", advertisedLocationName: "L\u00f6th" },
  { locationSignature: "xLba", advertisedLocationName: "L\u00e5ngbacka" },
  { locationSignature: "xLbr", advertisedLocationName: "L\u00e4nna bruk" },
  { locationSignature: "xLdm", advertisedLocationName: "Lindholmen" },
  {
    locationSignature: "xLgn",
    advertisedLocationName: "L\u00e4ggesta nedre",
  },
  { locationSignature: "xLh", advertisedLocationName: "L\u00f6vstahagen" },
  { locationSignature: "xLhl", advertisedLocationName: "Lah\u00e4ll" },
  {
    locationSignature: "xLi\u00e4",
    advertisedLocationName: "Lill\u00e4ngen",
  },
  {
    locationSignature: "xLjd",
    advertisedLocationName: "Ljungbyheds grusgrop",
  },
  { locationSignature: "xLm", advertisedLocationName: "Lilla Mon" },
  { locationSignature: "xLna", advertisedLocationName: "L\u00e4nna" },
  { locationSignature: "xLnb", advertisedLocationName: "Lundsbrunn" },
  {
    locationSignature: "xL\u00e5\u00f6",
    advertisedLocationName: "L\u00e5ngsj\u00f6n",
  },
  {
    locationSignature: "xL\u00e4e",
    advertisedLocationName: "L\u00e4nninge",
  },
  { locationSignature: "xL\u00f6t", advertisedLocationName: "L\u00f6t" },
  { locationSignature: "Xmag", advertisedLocationName: "Magnor" },
  { locationSignature: "Xmer", advertisedLocationName: "Mer\u00e5ker" },
  { locationSignature: "xMfd", advertisedLocationName: "Mariefred" },
  {
    locationSignature: "xMf\u00e5",
    advertisedLocationName: "Mariefreds \u00e5ngb\u00e5tsstation",
  },
  { locationSignature: "xMg", advertisedLocationName: "Moga" },
  { locationSignature: "xMl", advertisedLocationName: "Marielund" },
  { locationSignature: "xMln", advertisedLocationName: "Marielund" },
  { locationSignature: "xMnb", advertisedLocationName: "Molnby" },
  { locationSignature: "xMop", advertisedLocationName: "Mosstorp" },
  {
    locationSignature: "xMsa",
    advertisedLocationName: "M\u00e5lilla Sanatorium",
  },
  { locationSignature: "xMus", advertisedLocationName: "Munkebos" },
  {
    locationSignature: "xMy\u00e5",
    advertisedLocationName: "Myr\u00e5sen",
  },
  { locationSignature: "xM\u00f6b", advertisedLocationName: "M\u00f6rby" },
  { locationSignature: "xN", advertisedLocationName: "Nora stad" },
  {
    locationSignature: "xNbe",
    advertisedLocationName: "N\u00e4sbyall\u00e9",
  },
  { locationSignature: "xNbp", advertisedLocationName: "N\u00e4sbypark" },
  { locationSignature: "xNeg", advertisedLocationName: "Neglinge" },
  { locationSignature: "Xnk", advertisedLocationName: "Narvik" },
  { locationSignature: "xNka", advertisedLocationName: "Nacka" },
  { locationSignature: "xNv", advertisedLocationName: "N\u00e4sviken" },
  {
    locationSignature: "xNys",
    advertisedLocationName: "Nyg\u00e5rds Stall Dalhem",
  },
  { locationSignature: "xOhs", advertisedLocationName: "Ohs Bruk" },
  { locationSignature: "xOta", advertisedLocationName: "Ormsta" },
  { locationSignature: "xOxb", advertisedLocationName: "Oxberg" },
  {
    locationSignature: "xPgn",
    advertisedLocationName: "Pershyttev\u00e4gen",
  },
  { locationSignature: "xPh", advertisedLocationName: "Pershyttan" },
  { locationSignature: "xPte", advertisedLocationName: "Pallanite" },
  { locationSignature: "xQo", advertisedLocationName: "Kvarnabo" },
  { locationSignature: "xRa", advertisedLocationName: "Roma" },
  { locationSignature: "xRav", advertisedLocationName: "Ravlundabro" },
  {
    locationSignature: "xRa\u00e4",
    advertisedLocationName: "Ravlunda br\u00e4nneri",
  },
  { locationSignature: "xRdb", advertisedLocationName: "Rydbo" },
  { locationSignature: "Xrhm", advertisedLocationName: "Ranheim" },
  {
    locationSignature: "xRnb",
    advertisedLocationName: "Roslags N\u00e4sby",
  },
  {
    locationSignature: "xRok",
    advertisedLocationName: "Roma kungsg\u00e5rd",
  },
  { locationSignature: "Xrom", advertisedLocationName: "Rombakk" },
  {
    locationSignature: "xRv\u00e4",
    advertisedLocationName: "Ringv\u00e4gen",
  },
  { locationSignature: "xSa", advertisedLocationName: "Sk\u00f6lsta" },
  { locationSignature: "xSac", advertisedLocationName: "Skara camping" },
  {
    locationSignature: "xSbn",
    advertisedLocationName: "Saltsj\u00f6baden",
  },
  { locationSignature: "xScs", advertisedLocationName: "Scoutstugan" },
  {
    locationSignature: "xSdn",
    advertisedLocationName: "Saltsj\u00f6-Duvn\u00e4s",
  },
  { locationSignature: "xSdu", advertisedLocationName: "Sandudden" },
  { locationSignature: "xSik", advertisedLocationName: "Sickla" },
  {
    locationSignature: "xSjl",
    advertisedLocationName: "Saltsj\u00f6-J\u00e4rla",
  },
  {
    locationSignature: "xSjv",
    advertisedLocationName: "Sj\u00f6v\u00e4gen",
  },
  { locationSignature: "xSln", advertisedLocationName: "Solsidan" },
  { locationSignature: "xSlu", advertisedLocationName: "Slussen" },
  { locationSignature: "xSl\u00e4", advertisedLocationName: "Selkn\u00e4" },
  { locationSignature: "xSm", advertisedLocationName: "Stora Mon" },
  { locationSignature: "xSmk", advertisedLocationName: "Skogsmark" },
  { locationSignature: "xSn", advertisedLocationName: "Stensj\u00f6n" },
  { locationSignature: "xSoo", advertisedLocationName: "S:t Olof" },
  {
    locationSignature: "xSp\u00e4",
    advertisedLocationName: "Sp\u00e5ngen\u00e4s",
  },
  { locationSignature: "xSsu", advertisedLocationName: "Stocksund" },
  {
    locationSignature: "xSt\u00e4",
    advertisedLocationName: "Stor\u00e4ngen",
  },
  {
    locationSignature: "xSvb",
    advertisedLocationName: "Svartb\u00e4cken Nedre",
  },
  {
    locationSignature: "xS\u00f6y",
    advertisedLocationName: "Sj\u00f6bygget",
  },
  { locationSignature: "xTab", advertisedLocationName: "Tattby" },
  { locationSignature: "xTbl", advertisedLocationName: "Tibble" },
  { locationSignature: "xTe", advertisedLocationName: "Tule" },
  { locationSignature: "xTeg", advertisedLocationName: "Tule grusgrop" },
  { locationSignature: "xTib", advertisedLocationName: "Triabo" },
  { locationSignature: "xTip", advertisedLocationName: "Tippen" },
  { locationSignature: "xTna", advertisedLocationName: "Tuna" },
  { locationSignature: "Xtnd", advertisedLocationName: "Trondheim" },
  { locationSignature: "xTob", advertisedLocationName: "Totebo" },
  { locationSignature: "xTog", advertisedLocationName: "Tomtskog" },
  { locationSignature: "xTp", advertisedLocationName: "Torpa" },
  { locationSignature: "xTsk", advertisedLocationName: "Trollskogen" },
  { locationSignature: "xTug", advertisedLocationName: "Tunag\u00e5rd" },
  { locationSignature: "xTve", advertisedLocationName: "Tveta" },
  {
    locationSignature: "xTx",
    advertisedLocationName: "Taxinge-N\u00e4sby",
  },
  { locationSignature: "xT\u00e5s", advertisedLocationName: "Tall\u00e5s" },
  {
    locationSignature: "xT\u00e4b",
    advertisedLocationName: "T\u00e4by kyrkby",
  },
  {
    locationSignature: "xT\u00e4c",
    advertisedLocationName: "T\u00e4by Centrum",
  },
  {
    locationSignature: "xT\u00e4l",
    advertisedLocationName: "T\u00e4lj\u00f6",
  },
  { locationSignature: "xUnv", advertisedLocationName: "Universitetet" },
  {
    locationSignature: "xU\u00f6",
    advertisedLocationName: "Uppsala \u00d6stra",
  },
  { locationSignature: "xVas", advertisedLocationName: "Valstad" },
  { locationSignature: "xVbm", advertisedLocationName: "Viggbyholm" },
  {
    locationSignature: "xVb\u00e4",
    advertisedLocationName: "Verkeb\u00e4ck",
  },
  { locationSignature: "xVen", advertisedLocationName: "Vena" },
  { locationSignature: "xVev", advertisedLocationName: "Vendev\u00e4gen" },
  { locationSignature: "xVht", advertisedLocationName: "V\u00e4rdshuset" },
  { locationSignature: "xVir", advertisedLocationName: "Virestad" },
  { locationSignature: "xVis", advertisedLocationName: "Visinge" },
  { locationSignature: "xVlt", advertisedLocationName: "Vallentuna" },
  { locationSignature: "xVsm", advertisedLocationName: "Virserum" },
  { locationSignature: "xVst", advertisedLocationName: "Vadstena" },
  { locationSignature: "xVtk", advertisedLocationName: "Vitaby kyrka" },
  { locationSignature: "xVty", advertisedLocationName: "Vitaby" },
  {
    locationSignature: "xV\u00e4m",
    advertisedLocationName: "V\u00e4derum",
  },
  {
    locationSignature: "x\u00c5a",
    advertisedLocationName: "\u00c5seda (x\u00c5a)",
  },
  {
    locationSignature: "x\u00c5bg",
    advertisedLocationName: "\u00c5kersberga",
  },
  { locationSignature: "x\u00c5bo", advertisedLocationName: "\u00c5bo" },
  {
    locationSignature: "x\u00c5ru",
    advertisedLocationName: "\u00c5kers Run\u00f6",
  },
  { locationSignature: "x\u00c5s", advertisedLocationName: "\u00c5rsta" },
  { locationSignature: "x\u00c5tp", advertisedLocationName: "\u00c5torp" },
  {
    locationSignature: "x\u00c4p",
    advertisedLocationName: "\u00c4lvstorp",
  },
  { locationSignature: "x\u00d6dh", advertisedLocationName: "\u00d6dhult" },
  {
    locationSignature: "x\u00d6ga",
    advertisedLocationName: "\u00d6stberga",
  },
  {
    locationSignature: "x\u00d6sk",
    advertisedLocationName: "\u00d6stersk\u00e4r",
  },
  {
    locationSignature: "x\u00d6st",
    advertisedLocationName: "Stockholms \u00d6stra",
  },
  {
    locationSignature: "x\u00d6sv",
    advertisedLocationName: "\u00d6stervik",
  },
  {
    locationSignature: "x\u00d6vl",
    advertisedLocationName: "\u00d6stra Vemmerl\u00f6v",
  },
  { locationSignature: "Y", advertisedLocationName: "Ystad" },
  { locationSignature: "Yb", advertisedLocationName: "Ytterby" },
  { locationSignature: "Yl", advertisedLocationName: "Ytterhogdal" },
  { locationSignature: "Ym", advertisedLocationName: "Yttermalung" },
  {
    locationSignature: "Y\u00f6",
    advertisedLocationName: "Yttersj\u00f6n",
  },
  { locationSignature: "Zdro", advertisedLocationName: "Drogden" },
  { locationSignature: "Zgae", advertisedLocationName: "Espergaerde" },
  { locationSignature: "Zhg", advertisedLocationName: "Helsing\u00f6r" },
  { locationSignature: "Zhl", advertisedLocationName: "Hellerup" },
  { locationSignature: "Zhum", advertisedLocationName: "Humlebaek" },
  { locationSignature: "Zkk", advertisedLocationName: "\u00d6sterport" },
  { locationSignature: "Zkl", advertisedLocationName: "Klampenborg" },
  { locationSignature: "Zklv", advertisedLocationName: "Kalvebod" },
  { locationSignature: "Zkn", advertisedLocationName: "N\u00f6rreport" },
  {
    locationSignature: "zK\u00f6p",
    advertisedLocationName: "f.d. K\u00f6pingebro",
  },
  { locationSignature: "Zni", advertisedLocationName: "Niv\u00e5" },
  { locationSignature: "Zok", advertisedLocationName: "Kokkedal" },
  { locationSignature: "Zru", advertisedLocationName: "Rungsted" },
  { locationSignature: "Zsq", advertisedLocationName: "Snekkersten" },
  { locationSignature: "Zs\u00e5", advertisedLocationName: "Skodsborg" },
  { locationSignature: "Zt\u00e5t", advertisedLocationName: "T\u00e5rnby" },
  { locationSignature: "Zvb", advertisedLocationName: "Vedbaek" },
  {
    locationSignature: "Z\u00f6re",
    advertisedLocationName: "\u00d6restad",
  },
  { locationSignature: "\u00c5", advertisedLocationName: "\u00c5sbro" },
  { locationSignature: "\u00c5a", advertisedLocationName: "\u00c5seda" },
  {
    locationSignature: "\u00c5b",
    advertisedLocationName: "\u00c5sensbruk",
  },
  { locationSignature: "\u00c5ba", advertisedLocationName: "\u00c5lberga" },
  {
    locationSignature: "\u00c5be",
    advertisedLocationName: "\u00c5rstaberg",
  },
  { locationSignature: "\u00c5by", advertisedLocationName: "\u00c5by" },
  {
    locationSignature: "\u00c5byg",
    advertisedLocationName: "\u00c5by godsbang\u00e5rd",
  },
  {
    locationSignature: "\u00c5d",
    advertisedLocationName: "\u00c5dalsliden",
  },
  { locationSignature: "\u00c5g", advertisedLocationName: "\u00c5nge" },
  {
    locationSignature: "\u00c5ggb",
    advertisedLocationName: "\u00c5nge godsbang\u00e5rd",
  },
  { locationSignature: "\u00c5gy", advertisedLocationName: "\u00c5ngebyn" },
  { locationSignature: "\u00c5h", advertisedLocationName: "\u00c5shammar" },
  { locationSignature: "\u00c5hus", advertisedLocationName: "\u00c5hus" },
  { locationSignature: "\u00c5k", advertisedLocationName: "\u00c5karp" },
  {
    locationSignature: "\u00c5kg",
    advertisedLocationName: "\u00c5rskogen",
  },
  {
    locationSignature: "\u00c5ks",
    advertisedLocationName: "\u00c5kers styckebruk",
  },
  { locationSignature: "\u00c5kt", advertisedLocationName: "\u00c5skott" },
  {
    locationSignature: "\u00c5l",
    advertisedLocationName: "\u00c5m\u00e5l",
  },
  {
    locationSignature: "\u00c5lg",
    advertisedLocationName: "\u00c5ls\u00e4ng",
  },
  { locationSignature: "\u00c5m", advertisedLocationName: "\u00c5msele" },
  {
    locationSignature: "\u00c5mn",
    advertisedLocationName: "\u00c5ngs\u00e5gsmossen",
  },
  { locationSignature: "\u00c5my", advertisedLocationName: "\u00c5myran" },
  {
    locationSignature: "\u00c5nm",
    advertisedLocationName: "\u00c5nimskog",
  },
  { locationSignature: "\u00c5nn", advertisedLocationName: "\u00c5nn" },
  { locationSignature: "\u00c5p", advertisedLocationName: "\u00c5storp" },
  { locationSignature: "\u00c5rd", advertisedLocationName: "\u00c5ryd" },
  { locationSignature: "\u00c5re", advertisedLocationName: "\u00c5re" },
  { locationSignature: "\u00c5s", advertisedLocationName: "\u00c5s" },
  { locationSignature: "\u00c5sa", advertisedLocationName: "\u00c5sa" },
  {
    locationSignature: "\u00c5sal",
    advertisedLocationName: "\u00c5sarna central",
  },
  {
    locationSignature: "\u00c5sra",
    advertisedLocationName: "\u00c5sarna S\u00f6dra",
  },
  {
    locationSignature: "\u00c5st",
    advertisedLocationName: "\u00c5str\u00e4sk",
  },
  { locationSignature: "\u00c5t", advertisedLocationName: "\u00c5motfors" },
  {
    locationSignature: "\u00c5vg",
    advertisedLocationName: "\u00c5tvidaberg",
  },
  { locationSignature: "\u00c4", advertisedLocationName: "\u00c4ngelholm" },
  { locationSignature: "\u00c4ba", advertisedLocationName: "\u00c4lvboda" },
  {
    locationSignature: "\u00c4bg",
    advertisedLocationName: "\u00c4ngelsberg",
  },
  {
    locationSignature: "\u00c4bgb",
    advertisedLocationName: "\u00c4ngelsbergs bruk",
  },
  { locationSignature: "\u00c4fs", advertisedLocationName: "\u00c4ggfors" },
  { locationSignature: "\u00c4h", advertisedLocationName: "\u00c4lmhult" },
  {
    locationSignature: "\u00c4kb",
    advertisedLocationName: "\u00c4lvkarleby",
  },
  {
    locationSignature: "\u00c4l",
    advertisedLocationName: "\u00c4lgar\u00e5s",
  },
  { locationSignature: "\u00c4lo", advertisedLocationName: "\u00c4lvho" },
  { locationSignature: "\u00c4ls", advertisedLocationName: "\u00c4lvros" },
  {
    locationSignature: "\u00c4n",
    advertisedLocationName: "\u00c4lv\u00e4ngen",
  },
  { locationSignature: "\u00c4ng", advertisedLocationName: "\u00c4ng" },
  {
    locationSignature: "\u00c4n\u00f6",
    advertisedLocationName: "\u00c4ngersj\u00f6",
  },
  { locationSignature: "\u00c4p", advertisedLocationName: "\u00c4ppelbo" },
  {
    locationSignature: "\u00c4s",
    advertisedLocationName: "\u00c4lvsj\u00f6",
  },
  {
    locationSignature: "\u00c4sb",
    advertisedLocationName: "\u00c4landsbro",
  },
  {
    locationSignature: "\u00c4sg",
    advertisedLocationName: "\u00c4lvsj\u00f6 godsbang\u00e5rd",
  },
  {
    locationSignature: "\u00c4sr",
    advertisedLocationName: "\u00c4skek\u00e4rr",
  },
  {
    locationSignature: "\u00c4tk",
    advertisedLocationName: "\u00c4ttekulla",
  },
  { locationSignature: "\u00c4y", advertisedLocationName: "\u00c4lvsbyn" },
  { locationSignature: "\u00d6", advertisedLocationName: "\u00d6rtofta" },
  { locationSignature: "\u00d6a", advertisedLocationName: "\u00d6kna" },
  {
    locationSignature: "\u00d6al",
    advertisedLocationName: "\u00d6steraln\u00f6",
  },
  {
    locationSignature: "\u00d6b",
    advertisedLocationName: "\u00d6rebro S\u00f6dra",
  },
  {
    locationSignature: "\u00d6bl",
    advertisedLocationName: "\u00d6sj\u00f6bol",
  },
  {
    locationSignature: "\u00d6bn",
    advertisedLocationName: "\u00d6rab\u00e4cken",
  },
  {
    locationSignature: "\u00d6da",
    advertisedLocationName: "\u00d6d\u00e5kra",
  },
  {
    locationSignature: "\u00d6g",
    advertisedLocationName: "\u00d6stra Grevie",
  },
  {
    locationSignature: "\u00d6gd",
    advertisedLocationName: "\u00d6verg\u00e5rd",
  },
  { locationSignature: "\u00d6h", advertisedLocationName: "\u00d6rbyhus" },
  {
    locationSignature: "\u00d6hl",
    advertisedLocationName: "\u00d6verhogdal",
  },
  {
    locationSignature: "\u00d6hlg",
    advertisedLocationName: "\u00d6verhogdals grusgrop",
  },
  {
    locationSignature: "\u00d6j",
    advertisedLocationName: "\u00d6stansj\u00f6",
  },
  { locationSignature: "\u00d6jr", advertisedLocationName: "\u00d6jervik" },
  {
    locationSignature: "\u00d6k",
    advertisedLocationName: "\u00d6rnsk\u00f6ldsvik C",
  },
  {
    locationSignature: "\u00d6kn",
    advertisedLocationName: "\u00d6rnsk\u00f6ldsvik norra",
  },
  { locationSignature: "\u00d6l", advertisedLocationName: "\u00d6lme" },
  {
    locationSignature: "\u00d6na",
    advertisedLocationName: "\u00d6naskogen",
  },
  {
    locationSignature: "\u00d6nd",
    advertisedLocationName: "\u00d6nnestad",
  },
  { locationSignature: "\u00d6r", advertisedLocationName: "\u00d6rebro C" },
  {
    locationSignature: "\u00d6rs",
    advertisedLocationName: "\u00d6rsj\u00f6",
  },
  {
    locationSignature: "\u00d6s",
    advertisedLocationName: "\u00d6stersund C",
  },
  { locationSignature: "\u00d6so", advertisedLocationName: "\u00d6smo" },
  {
    locationSignature: "\u00d6sv",
    advertisedLocationName: "\u00d6stersund V\u00e4stra",
  },
  {
    locationSignature: "\u00d6s\u00e5",
    advertisedLocationName: "\u00d6ster\u00e5s",
  },
  {
    locationSignature: "\u00d6te",
    advertisedLocationName: "\u00d6stert\u00e4lje",
  },
  { locationSignature: "\u00d6v", advertisedLocationName: "\u00d6stavall" },
  { locationSignature: "\u00d6vb", advertisedLocationName: "\u00d6verby" },
  { locationSignature: "\u00d6vm", advertisedLocationName: "\u00d6verum" },
  {
    locationSignature: "\u00d6vmb",
    advertisedLocationName: "\u00d6verums Bruk",
  },
  {
    locationSignature: "\u00d6vn",
    advertisedLocationName: "\u00d6sterv\u00e4rn",
  },
  {
    locationSignature: "\u00d6v\u00f6",
    advertisedLocationName: "\u00d6vsj\u00f6",
  },
  { locationSignature: "\u00d6x", advertisedLocationName: "\u00d6xnered" },
  {
    locationSignature: "\u00d6\u00e4",
    advertisedLocationName: "\u00d6re\u00e4lv",
  },
  {
    locationSignature: "\u00d6\u00e4g",
    advertisedLocationName: "\u00d6ster\u00e4ng",
  },
];

export default temporaryLocations;
