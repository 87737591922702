import React, { useRef, useState } from "react";

export default function ContactForm() {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);
  // Create two honey traps for bot filtering
  const nameTrap = useRef();
  const emailTrap = useRef();
  // Create refs for input fields
  const contactName = useRef();
  const contactEmail = useRef();
  const contactMessage = useRef();

  // Handle form submit
  async function submitHandler(event) {
    event.preventDefault();
    setSubmitted(true);
    setError("");

    // Create mail object and fill with data
    const mail = {
      name: nameTrap.current.value,
      email: emailTrap.current.value,
      contact_name: contactName.current.value,
      contact_email: contactEmail.current.value,
      contact_message: contactMessage.current.value,
    };

    // Try to send the mail using a PHP backend
    try {
      const response = await fetch("https://ewenson.se/mail.php", { method: "POST", body: JSON.stringify(mail) });
      if (response.ok) {
        setSuccessMessage("Ditt meddelande skickades utan problem. Tack för att du hör av dig!");
      }
    } catch (error) {
      setError(error);
      setSubmitted(false);
    }
  }

  return (
    <>
      {error && <div className="warning">{error}</div>}
      {successMessage && <div className="success">{successMessage}</div>}
      <section className="card">
        <h2>Kontaktformulär</h2>
        <p>Vill du komma i kontakt med mig som utvecklar och driver utf.nu? Använd med fördel då detta kontaktformulär.</p>
        <form onSubmit={submitHandler} className="flex flex-col">
          <input type="hidden" name="name" id="name" ref={nameTrap} />
          <input type="hidden" name="email" id="email" ref={emailTrap} />
          <label className="block font-semibold" htmlFor="contact_name">
            Namn
          </label>
          <input
            type="text"
            id="contact_name"
            name="contact_name"
            placeholder="Ditt namn"
            ref={contactName}
            required
          />
          <label className="block font-semibold" htmlFor="contact_email">
            E-post
          </label>
          <input
            type="email"
            id="contact_email"
            name="contact_email"
            placeholder="Din e-postadress"
            ref={contactEmail}
            required
          />
          <label className="block font-semibold" htmlFor="contact_message">
            Meddelande
          </label>
          <textarea
            rows="10"
            id="contact_message"
            name="contact_message"
            placeholder="Ditt meddelande..."
            ref={contactMessage}
            required
          />
          <button
            type="submit"
            disabled={isSubmitted}
          >
            Skicka
          </button>
        </form>
      </section>
    </>
  );
}
