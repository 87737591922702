import React from "react";

function VmaxResults({ res }) {
  return (
    <section className="card overflow-auto" id="vMaxResults">
      <h2 className="mb-0">STH med hänsyn till bromsförmåga</h2>
      <p className="text-xs mt-1 mb-3">
        Gäller bromsgrupp P/R. Kontrollera alltid uppgifterna mot linjeboken innan du använder dem i skarpt läge.
      </p>
      <table className="table-auto">
        <thead>
          <tr>
            <th className="text-left p-2">Bantyp</th>
            <th className="text-right p-2">STH</th>
          </tr>
        </thead>
        <tbody>
          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="text-left p-2">A17</td>
            <td className="text-right p-2">{res.vmaxA17} km/h</td>
          </tr>
          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="text-left p-2">A10</td>
            <td className="text-right p-2">{res.vmaxA10} km/h</td>
          </tr>
          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="text-left p-2">B</td>
            <td className="text-right p-2">{res.vmaxB} km/h</td>
          </tr>
          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="text-left p-2">C</td>
            <td className="text-right p-2">{res.vmaxC} km/h</td>
          </tr>
          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="text-left p-2">Citybanan</td>
            <td className="text-right p-2">{res.vmaxCitybanan} km/h</td>
          </tr>
        </tbody>
      </table>
      <table className="container table-auto">
        <thead>
          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <th colSpan={2}>Bromsprocenttabell GM</th>
          </tr>
        </thead>
        <tbody>
          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="text-left p-2">STH</td>
            <td className="text-right p-2">{res.vmaxGM} km/h</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

export default VmaxResults;
