import React from "react";
import { Link } from "react-router-dom";
import InlineLoadingSpinner from "./InlineLoadingSpinner";
import { fetchLocationName } from "../Utils/Common";

export default function TrainTable({ schedule, trainId, isLoading, locationList }) {
  const datetimeOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  const longDateTimeOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return (
    <section className="card overflow-auto">
      <h3 className="">
        Tidtabell {trainId ? " tåg " + trainId : ""} {isLoading ? <InlineLoadingSpinner /> : ""}
      </h3>
      <table className="table-auto">
        <thead className="font-weight-bold">
          <tr className="">
            <th className="text-left">Plats</th>
            <th className="text-center">Spår</th>
            <th className="text-left">Ank</th>
            <th className="text-left">Avg</th>
            <th className="">Anm</th>
          </tr>
        </thead>
        <tbody>
          {schedule?.map((item, index) => (
            <tr className="text-xs md:text-base" key={index}>
              <td className="whitespace-nowrap">
                <Link
                  to={`/station/${item.LocationSignature}`}
                  className="scheduleLink"
                  title={fetchLocationName(locationList, item.LocationSignature)}
                >
                  <span
                    style={{
                      fontWeight: item.ArrivalData.Advertised || item.DepartureData.Advertised ? "600" : "normal",
                    }}
                  >
                    {fetchLocationName(locationList, item.LocationSignature)}
                  </span>
                </Link>

                <br />
                <small className="text-muted">{item.LocationSignature}</small>
              </td>
              <td className="text-center">
                <span
                  style={{
                    fontWeight: item.ArrivalData.Advertised || item.DepartureData.Advertised ? "600" : "normal",
                  }}
                >
                  {item.DepartureData.TrackAtLocation && item.DepartureData.TrackAtLocation !== "x"
                    ? item.DepartureData.TrackAtLocation
                    : item.ArrivalData.TrackAtLocation}
                </span>
              </td>
              <td className="text-left whitespace-nowrap">
                <span
                  style={{
                    fontWeight: item.ArrivalData.Advertised ? "600" : "normal",
                    textDecoration: item.ArrivalData.Canceled ? "line-through" : "none",
                  }}
                >
                  {item.ArrivalData.AdvertisedTimeAtLocation
                    ? new Intl.DateTimeFormat("sv-SE", datetimeOptions).format(
                        new Date(item.ArrivalData.AdvertisedTimeAtLocation)
                      )
                    : ""}
                </span>
                {item.ArrivalData.EstimatedTimeAtLocation ? (
                  <span>
                    <br />
                    <b>
                      Ber:&nbsp;
                      {new Intl.DateTimeFormat("sv-SE", datetimeOptions).format(
                        new Date(item.ArrivalData.EstimatedTimeAtLocation)
                      )}
                    </b>
                  </span>
                ) : (
                  ""
                )}
                <br />
                {item.ArrivalData.TimeAtLocation ? (
                  <span
                    className="font-italic font-weight-light"
                    title={new Intl.DateTimeFormat("sv-SE", longDateTimeOptions).format(
                      new Date(item.ArrivalData.TimeAtLocationWithSeconds)
                    )}
                  >
                    {new Intl.DateTimeFormat("sv-SE", datetimeOptions).format(
                      new Date(item.ArrivalData.TimeAtLocation)
                    )}{" "}
                    (
                    <span
                      style={{
                        color: item.ArrivalState ? item.ArrivalState.textColor : "",
                      }}
                    >
                      {item.ArrivalState ? `${item.ArrivalState.prefix}${item.ArrivalState.minutes}` : ""}
                    </span>
                    )
                  </span>
                ) : (
                  ""
                )}
              </td>
              <td className="text-left whitespace-nowrap">
                <span
                  style={{
                    fontWeight: item.DepartureData.Advertised ? "600" : "normal",
                    textDecoration: item.DepartureData.Canceled ? "line-through" : "none",
                  }}
                >
                  {item.DepartureData.AdvertisedTimeAtLocation
                    ? new Intl.DateTimeFormat("sv-SE", datetimeOptions).format(
                        new Date(item.DepartureData.AdvertisedTimeAtLocation)
                      )
                    : ""}
                </span>
                {item.DepartureData.EstimatedTimeAtLocation ? (
                  <span>
                    <br />
                    <b>
                      Ber:&nbsp;
                      {new Intl.DateTimeFormat("sv-SE", datetimeOptions).format(
                        new Date(item.DepartureData.EstimatedTimeAtLocation)
                      )}
                    </b>
                  </span>
                ) : (
                  ""
                )}
                {item.DepartureData.TimeAtLocation ? (
                  <span
                    className="font-italic font-weight-light"
                    title={new Intl.DateTimeFormat("sv-SE", longDateTimeOptions).format(
                      new Date(item.DepartureData.TimeAtLocationWithSeconds)
                    )}
                  >
                    <br />
                    {new Intl.DateTimeFormat("sv-SE", datetimeOptions).format(
                      new Date(item.DepartureData.TimeAtLocation)
                    )}{" "}
                    (
                    <span
                      style={{
                        color: item.DepartureState ? item.DepartureState.textColor : "",
                      }}
                    >
                      {item.DepartureState ? `${item.DepartureState.prefix}${item.DepartureState.minutes}` : ""}
                    </span>
                    )
                  </span>
                ) : (
                  ""
                )}
              </td>
              <td className="">
                {item.DepartureData.Deviation
                  ? item.DepartureData.Deviation.map((Deviation) => (
                      <div className="font-weight-light" key={Deviation.Code}>
                        {Deviation.Description}
                      </div>
                    ))
                  : item.ArrivalData.Deviation
                  ? item.ArrivalData.Deviation.map((Deviation) => (
                      <div className="font-weight-light" key={Deviation.Code}>
                        {Deviation.Description}
                      </div>
                    ))
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
