import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StationBoard from "../Components/StationBoard";
import { trainStations } from "../Data/trainStations";
import { getDateFormat, getLongTime } from "../Utils/Common";
import { calcTrainStatus } from "../Services/calcTrainStatus";
import { fetchJsonResponse } from "../Services/fetchJsonResponse";
import { stationQuery } from "../Services/queries/stationQuery";
import { trainMessageQuery } from "../Services/queries/trainMessageQuery";
import { trainStatusQuery } from "../Services/queries/trainStatusQuery";
import LastUpdateInfo from "../Components/LastUpdateInfo";
import TrainMessageCard from "../Components/TrainMessageCard";
import { getTrainStationName } from "../Services/getTrainStationName";
import AdComponent from "../Components/AdComponent";

export default function StationPage({ type }) {
  let { locationId, limit = 25 } = useParams();
  const navigate = useNavigate();
  const [arrivalsData, setArrivalsData] = useState(null);
  const [departuresData, setDeparturesData] = useState(null);
  const [errors, setErrors] = useState(null);
  const [messages, setMessages] = useState(null);
  const [messageStreamUrl, setMessageStreamUrl] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const locationRef = useRef();

  const submitHandler = async (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    navigate(`/station/${locationRef.current.value}`);
  };

  async function getTrainState(trainIdent, searchDate) {
    if (searchDate !== undefined) {
      const trainState = await fetchJsonResponse(trainStatusQuery(trainIdent, getDateFormat(searchDate)));
      if (trainState.TrainAnnouncement[0]?.TimeAtLocation) {
        return await calcTrainStatus(trainState.TrainAnnouncement[0]);
      }
    }
    return {};
  }

  async function getStationData() {
    console.log(locationId);
    if (locationId) {
      setLoading(true);

      console.log(`Updated at ${getLongTime(new Date())}`);
      const stationName = await getTrainStationName(locationId);
      setLocationName(stationName?.AdvertisedLocationName);

      const arrivalResponse = await fetchJsonResponse(stationQuery(locationId, "Ankomst", isNaN(limit) ? 25 : limit));
      const arrivals = await Promise.all(
        arrivalResponse?.TrainAnnouncement?.map(async (item) => {
          item.FromLocationName = await getTrainStationName(item.FromLocation[0]?.LocationName);
          item.ToLocationName = await getTrainStationName(item.ToLocation[0]?.LocationName);
          item.TrainStatus = await getTrainState(item.AdvertisedTrainIdent, item.ScheduledDepartureDateTime);
          return item;
        })
      );
      setArrivalsData(arrivals);

      const departuresResponse = await fetchJsonResponse(stationQuery(locationId, "Avgang", isNaN(limit) ? 25 : limit));
      const departures = await Promise.all(
        departuresResponse.TrainAnnouncement?.map(async (item) => {
          item.FromLocationName = await getTrainStationName(item.FromLocation[0]?.LocationName);
          item.ToLocationName = await getTrainStationName(item.ToLocation[0]?.LocationName);
          item.TrainStatus = await getTrainState(item.TechnicalTrainIdent, item.ScheduledDepartureDateTime);
          return item;
        })
      );
      setDeparturesData(departures);
      setLoading(false);
    }
  }

  async function getMessages() {
    if (locationId) {
      const result = await fetchJsonResponse(trainMessageQuery(locationId));
      const stationName = await getTrainStationName(locationId);

      setLocationName(stationName.AdvertisedLocationName);
      setMessages(result);
      if (messageStreamUrl === null) {
        console.log(`Updated at ${getLongTime(new Date())}`);
        setMessageStreamUrl(result?.INFO?.SSEURL);
      }
    }
  }

  useEffect(() => {
    let interval;

    getStationData();
    interval = setInterval(() => {
      getStationData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, type, limit, navigate]);

  // Get train messages
  useEffect(() => {
    let eventSource;

    getMessages();

    // Start stream
    if (messageStreamUrl) {
      // Set event source
      eventSource = new EventSource(messageStreamUrl);

      // Error handling
      eventSource.onerror = (event) => {
        setErrors(event.error);
        console.error(event.error);
      };

      // Message on stream open
      eventSource.onopen = () => console.log(`Stream open at ${getLongTime(new Date())}`);

      // Message handler
      eventSource.onmessage = (event) => {
        console.log(`Stream ping at ${getLongTime(new Date())}`);
        getMessages();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, messageStreamUrl, navigate]);

  locationId != null ? (document.title = `Tågläge ${locationId} `) : (document.title = `Tågläge`);

  return (
    <div>
      {errors && <div className="content">{errors}</div>}
      {locationId && (
        <>
          <AdComponent />
          {arrivalsData !== null && departuresData !== null && (
            <div className="flex flex-col 2xl:flex-row gap-2">
              <div className="w-full 2xl:w-1/2">
                {departuresData !== null ? (
                  <StationBoard locationName={locationName} data={departuresData} type="departures" />
                ) : (
                  <></>
                )}
              </div>
              <div className="w-full 2xl:w-1/2">
                {arrivalsData !== null ? (
                  <StationBoard locationName={locationName} data={arrivalsData} type="arrivals" />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
          {messages?.TrainMessage?.length !== 0 && (
            <>
              {messages?.TrainMessage?.map((msg) => (
                  <TrainMessageCard msg={msg} key={msg.EventId} />
              ))}
            </>
          )}
          <div className="text-center">
            <LastUpdateInfo dateTime={new Date()} />
          </div>
          {isLoading && (
            <div>
              <div className="loading">
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
                <div className="loading-dot"></div>
              </div>
            </div>
          )}
        </>
      )}
      <AdComponent />
      <section className="card" id="stationSearchForm">
        <h2 className="m-0 text-lg sm:text-xl">Sök stationsinformation</h2>
        <form onSubmit={submitHandler} id="search-box" className="flex flex-col">
          <datalist id="stationIds">
            {trainStations?.TrainStation?.map((station) => (
              <option key={station?.LocationSignature} value={station?.LocationSignature}>{station?.OfficialLocationName}</option>
            ))}
          </datalist>
          <label className="block font-semibold" htmlFor="tpl">
            Stationssignatur
          </label>
          <input
            type="text"
            list="stationIds"
            id="tpl"
            name="tpl"
            placeholder="Stationssignatur (Xxx)"
            defaultValue={locationId}
            ref={locationRef}
            autoCapitalize="on"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            required
          />
          <button type="submit" className="" disabled={isLoading}>
            Sök
          </button>
        </form>
      </section>
    </div>
  );
}
