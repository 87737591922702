import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Clock from "../Components/Clock";
import TrainMap from "../Components/TrainMap";
import TrainNextStop from "../Components/TrainNextStop";
import TrainScheduleTable from "../Components/TrainScheduleTable";
import TrainStatus from "../Components/TrainStatus";
import { calcTrainStatus } from "../Services/calcTrainStatus";
import { fetchJsonResponse } from "../Services/fetchJsonResponse";
import { getTrainStationGeoData } from "../Services/getTrainStationGeoData";
import { getTrainStationName } from "../Services/getTrainStationName";
import { getLocationsForTrainQuery } from "../Services/queries/getLocationsForTrainQuery";
import { trainNextStopQuery } from "../Services/queries/trainNextStopQuery";
import { trainScheduleQuery } from "../Services/queries/trainScheduleQuery";
import { trainStatusQuery } from "../Services/queries/trainStatusQuery";
import { trainStreamQuery } from "../Services/queries/trainStreamQuery";
import { scheduleCleaner } from "../Services/scheduleCleaner";
import { getDateFormat, getLongTime } from "../Utils/Common";
import { convertWgs84, getBearing, getMiddlePoint, getThemeMode } from "../Utils/Common";
import AdComponent from "../Components/AdComponent";


export default function TrainPage() {
  let { trainId, searchDate } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [trainData, setTrainData] = useState({ ident: trainId, date: searchDate });
  const [trainSchedule, setTrainSchedule] = useState(null);
  const [trainStatus, setTrainStatus] = useState(null);
  const [trainStatusStreamUrl, setTrainStatusStreamUrl] = useState(null);
  const [nextStop, setNextStop] = useState({ data: {} });
  // Map
  const [isInitialRender, setInitialRender] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [pathCoordinates, setPathCoordinates] = useState([]);
  const [theme] = useState(getThemeMode());
  const [trainMarker, setTrainMarker] = useState(null);
  const [trainBearing, setTrainBearing] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const trainIdentRef = useRef();
  const dateRef = useRef();

  const submitHandler = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    searchDate = dateRef.current.value ? dateRef.current.value : new Date().toLocaleDateString("sv-SE");
    trainId = trainIdentRef.current.value;

    if (trainId && searchDate) {
      navigate(`/train/${trainId}/${searchDate}`);
    } else {
      navigate(`/train/${trainId}`);
    }
    setNextStop({});
    setTrainData({ ident: trainId, date: searchDate });
    setInitialRender(true);
  };

  useEffect(() => {

    let eventSource;

    async function getTrainData() {
      setLoading(true);
      // Fetch data
      const trainScheduleResponse = await fetchJsonResponse(
        trainScheduleQuery(trainData.ident, trainData.date !== undefined ? trainData.date : getDateFormat(new Date()))
      );
      const trainStatusResponse = await fetchJsonResponse(
        trainStatusQuery(trainData.ident, trainData.date !== undefined ? trainData.date : getDateFormat(new Date()))
      );
      const trainNextStopResponse = await fetchJsonResponse(
        trainNextStopQuery(trainData.ident, trainData.date !== undefined ? trainData.date : getDateFormat(new Date()))
      );
      const trainStreamResponse = await fetchJsonResponse(
        trainStreamQuery(trainData.ident, trainData.date !== undefined ? trainData.date : getDateFormat(new Date()))
      );

      // Fetch names
      const schedule = await Promise.all(
        trainScheduleResponse?.TrainAnnouncement?.map(async (item) => {
          // Fetching full names and location
          const fromLocation = item?.FromLocation
            ? await getTrainStationName(item?.FromLocation[0]?.LocationName)
            : null;
          const geoData = (await getTrainStationGeoData(item?.LocationSignature)) ?? null;
          const toLocation = item?.ToLocation ? await getTrainStationName(item?.ToLocation[0]?.LocationName) : null;
          const station = await getTrainStationName(item?.LocationSignature);
          // Insert full names and output item
          item.FromLocationName = fromLocation;
          item.GeoData = geoData?.properties;
          item.ToLocationName = toLocation;
          item.LocationName = station;
          return item;
        })
      );

      const status = await Promise.all(
        trainStatusResponse?.TrainAnnouncement?.map(async (item) => {
          item.LocationName = await getTrainStationName(item.LocationSignature);
          return item;
        })
      );

      const trainNextStop = await Promise.all(
        trainNextStopResponse?.TrainAnnouncement?.map(async (item) => {
          item.LocationName = await getTrainStationName(item.LocationSignature);
          return item;
        })
      );

      for (var i in trainNextStop?.reverse()) {
        if (trainNextStop[i]?.TimeAtLocation) {
          setNextStop({ data: trainNextStop[i - 1] });
          break;
        }
      }

      // Get locations for train ident
      const trainLocations = await fetchJsonResponse(
        getLocationsForTrainQuery(trainData.ident, trainData.date !== undefined ? trainData.date : getDateFormat(new Date()))
      );
      const locationString = trainLocations?.INFO?.EVALRESULT[0]?.OrderedLocations;
      // Get geodata for locations
      const geodata = await getStationGeoData(locationString);
      // Get geodata for train
      const trainLocation = await getStationGeoData(trainStatusResponse?.TrainAnnouncement[0]?.LocationSignature);
      // TODO: Fix alternate location here.
      const trainPosition =
        trainLocation !== null ? convertWgs84(trainLocation[0]?.Geometry?.WGS84) : { lat: 0.0, lng: 0.0 };
      
      // Set point of train
      if (trainStatusResponse?.TrainAnnouncement[0]?.ActivityType === "Ankomst") {
        setTrainMarker(trainPosition);
        setMapCenter(trainPosition);
      }
      if (trainStatusResponse?.TrainAnnouncement[0]?.ActivityType === "Avgang") {
        // Get index of current position
        const index = geodata?.findIndex(
          (x) => x.LocationSignature === trainStatusResponse?.TrainAnnouncement[0]?.LocationSignature
        );
        const currentLatLng = convertWgs84(geodata[index]?.Geometry.WGS84);
        const nextLatLng = convertWgs84(geodata[index + 1]?.Geometry.WGS84);
        // const halflingLatLng = getMiddlePoint(currentLatLng, nextLatLng);
        const markerBearing = getBearing(currentLatLng, nextLatLng);
        if (markerBearing) setTrainBearing(markerBearing);

        // setTrainMarker(halflingLatLng);
        setTrainMarker(trainPosition);
        if (!isInitialRender) {
          // setMapCenter(halflingLatLng);
          setMapCenter(trainPosition);
        }
      }
      // Center map on train position
      const startLatLng = convertWgs84(geodata[0]?.Geometry?.WGS84);
      const endLatLng = convertWgs84(geodata[geodata?.length - 1]?.Geometry.WGS84);
      if (startLatLng && endLatLng) {
        const centerLatLng = getMiddlePoint(startLatLng, endLatLng);
        if (isInitialRender) {
          setMapBounds([startLatLng, endLatLng]);
          setMapCenter(centerLatLng);
          // const distance = getDistance(startLatLng, endLatLng);
          // const bearing = getBearing(startLatLng, endLatLng);
          // console.log(
          //   `From start to end\nDistance: ${(distance / 1000).toFixed(1)} km\nBearing: ${bearing.toFixed(0)} deg`
          // );
          setInitialRender(false);
        }
      }

      // Set path coordinates
      let output = [];
      geodata?.map((data) => {
        const position = convertWgs84(data?.Geometry?.WGS84);

        const geo = {
          locationName: data?.AdvertisedLocationName,
          locationSignature: data?.LocationSignature,
          lat: position.lat,
          lng: position.lng,
        };
        output.push(geo);
        return null;
      });
      setPathCoordinates(output);

      // Set data
      setTrainSchedule(await scheduleCleaner(schedule));
      setTrainStatus(await calcTrainStatus(status[0]));

      setLoading(false);

      if (trainStatusStreamUrl === null) {
        // console.log(`Updated at ${getLongTime(new Date())}`);
        setTrainStatusStreamUrl(trainStreamResponse?.INFO?.SSEURL);
      }
    }

    // Get station geodata
    async function getStationGeoData(locationString) {
      const locations = locationString?.split(",");
      if (locations !== undefined) {
        let output = [];
        locations.forEach(async (location) => {
          const station = await getTrainStationName(location);
          output.push(station);
        });
        return output;
      }
      return null;
    }
    if (trainData.ident) {
      try {
        getTrainData();
      } catch (error) {
        setErrors(error);
      }
    }

    if (trainStatusStreamUrl) {
      // Set event source
      eventSource = new EventSource(trainStatusStreamUrl);

      // Error handling
      eventSource.onerror = (event) => {
        console.error(event.error);
        setErrors(event.error);
      };

      // Message on stream open
      eventSource.onopen = () => console.log(`Stream open at ${getLongTime(new Date())}`);

      // Message handler
      eventSource.onmessage = () => {
        console.log(`Stream ping at ${getLongTime(new Date())}`);
        getTrainData();
        setErrors(null);
      };
    }

    return () => {
      if (eventSource) {
        eventSource.close();
        console.log("Stream closed.");
      }
    };
  }, [isInitialRender, trainData, trainStatusStreamUrl, theme]);

  // Set doc title
  if (trainData.ident !== undefined && trainStatus?.activity !== undefined) {
    document.title = `Tåg ${trainData.ident}: ${trainStatus.activity === "Ankomst" ? "*" : ""}${trainStatus.location} ${
      trainStatus.prefix
    }${trainStatus.minutes}`;
  } else if (trainData.ident) {
    document.title = `Tåg ${trainData.ident}`;
  }

  return (
    <div>
      {errors && <div className="content">{errors}</div>}
      {trainSchedule?.length > 0 && (
        <>
          <section className="card my-3">
            <div className="flex content-center">
              <div className="grow content-start">
                <h2 className="m-0 p-0">
                  <a href="#trainSearchForm" className="no-underline">
                    Tåg&nbsp;{trainData.ident}
                  </a>
                </h2>
              </div>
              <div className="grow-0 content-center text-left">
                <Clock />
              </div>
            </div>
            <div className="trainInfo">
              {Object.keys(trainSchedule[0]?.DepartureData).length > 0 && (
                <>
                  {trainSchedule[0].DepartureData.FromLocation && (
                    <>
                      <small>
                        {trainSchedule[0]?.DepartureData?.FromLocationName?.AdvertisedLocationName ?? ""} -{" "}
                        {trainSchedule[0]?.DepartureData?.ToLocationName?.AdvertisedLocationName ?? ""}
                      </small>
                    </>
                  )}
                  {trainSchedule[0]?.DepartureData?.ProductInformation && (
                    <>
                      <small>
                        ,&nbsp;
                        {trainSchedule[0]?.DepartureData?.ProductInformation[0]?.Description}
                        {trainSchedule[0]?.DepartureData?.InformationOwner?.toLowerCase() !==
                          trainSchedule[0]?.DepartureData?.Operator?.toLowerCase() && (
                          <>
                            {" "}
                            ({trainSchedule[0]?.DepartureData?.InformationOwner}
                            {trainSchedule[0]?.DepartureData?.Operator ? ", " : ""}
                            {trainSchedule[0]?.DepartureData?.Operator})
                          </>
                        )}
                        {trainSchedule[0]?.DepartureData?.InformationOwner?.toLowerCase() ===
                          trainSchedule[0]?.DepartureData?.Operator?.toLowerCase() && (
                          <> ({trainSchedule[0]?.DepartureData.Operator})</>
                        )}
                      </small>
                      <br />
                    </>
                  )}
                </>
              )}
            </div>
          </section>
          <section className="card my-3 text-center">
            <h3 className="m-0">Status tåg {trainData.ident}</h3>
            <div className="">{trainStatus && <TrainStatus trainStatus={trainStatus} />}</div>
            <TrainNextStop nextStopData={nextStop} />
          </section>
          <AdComponent />
          <section className="map-card">
          {mapBounds && (
            <TrainMap
              mapBounds={mapBounds}
              mapCenter={mapCenter}
              pathCoordinates={pathCoordinates}
              theme={theme}
              trainBearing={trainBearing}
              trainIdent={trainData.ident}
              trainMarker={trainMarker}
              trainStatus={trainStatus}
            />
            )}
          </section>
          <AdComponent />
          {trainSchedule && <TrainScheduleTable trainId={trainData.ident} trainSchedule={trainSchedule} />}
        </>
      )}
      {isLoading && (
        <div>
          <div className="loading">
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
          </div>
        </div>
      )}
      <AdComponent />
      <section className="card" id="trainSearchForm">
        <h2 className="m-0 text-lg sm:text-xl">Sök information om tåg</h2>
        <form onSubmit={submitHandler} id="search-box" className="flex flex-col">
          <label className="block font-semibold text-sm sm:text-base" htmlFor="trainIdent">
            Tågnummer
          </label>
          <input
            type="tel"
            id="trainIdent"
            name="trainIdent"
            placeholder="Tågnummer (00000)"
            defaultValue={trainData.ident}
            ref={trainIdentRef}
            required
          />
          <label className="block font-semibold text-sm sm:text-base" htmlFor="date">
            Datum
          </label>
          <input
            type="date"
            id="date"
            name="date"
            placeholder="Datum (YYYY-MM-DD)"
            defaultValue={trainData.date !== undefined ? trainData.date : new Date().toLocaleDateString("sv-SE")}
            ref={dateRef}
            required
          />
          <button type="submit" className="" disabled={isLoading}>
            Sök tågstatus
          </button>
        </form>
      </section>
    </div>
  );
}
