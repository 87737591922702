export const trainNextStopQuery = (trainIdent, searchDate) => `
    <REQUEST>
        <LOGIN authenticationkey="${process.env.REACT_APP_TRV_APIKEY}" />
        <QUERY objecttype="TrainAnnouncement" schemaversion="1.6" orderby="AdvertisedTimeAtLocation asc, ActivityType asc" sseurl="true">
            <FILTER>
                <EQ name="AdvertisedTrainIdent" value="${trainIdent}" />
                <EQ name="ScheduledDepartureDateTime" value="${searchDate}" />
                <AND>
                    <EQ name="Advertised" value="true" />
                    <EXISTS name="TrackAtLocation" value="true" />
                    <NOT>
                        <OR>
                            <EQ name="Canceled" value="true" />
                            <EQ name="TrackAtLocation" value="-" />
                            <EQ name="TrackAtLocation" value="x" />
                        </OR>
                    </NOT>
                </AND>
            </FILTER>
            <INCLUDE>ActivityType</INCLUDE>
            <INCLUDE>AdvertisedTimeAtLocation</INCLUDE>
            <INCLUDE>AdvertisedTrainIdent</INCLUDE>
            <INCLUDE>Canceled</INCLUDE>
            <INCLUDE>EstimatedTimeAtLocation</INCLUDE>
            <INCLUDE>LocationSignature</INCLUDE>
            <INCLUDE>TechnicalDateTime</INCLUDE>
            <INCLUDE>TimeAtLocation</INCLUDE>
            <INCLUDE>TimeAtLocationWithSeconds</INCLUDE>
            <INCLUDE>TrackAtLocation</INCLUDE>
            <INCLUDE>TrainComposition</INCLUDE>
        </QUERY>
    </REQUEST>`;
