import React, { useRef, useState } from "react";
import Results from "../Components/Results";
import AdComponent from "../Components/AdComponent";

export default function BrpSth() {
  const [userInput, setUserInput] = useState({});
  const [isSubmitted, setSubmitted] = useState(false);

  const brakepercentRef = useRef();
  const lengthRef = useRef();
  const epBrakeRef = useRef();

  async function submitHandler(event) {
    event.preventDefault();
    const input = {
      dataType: "brpsth",
      brakepercent: Number(brakepercentRef.current.value),
      trainLength: Number(lengthRef.current.value),
      epBrake: Boolean(epBrakeRef.current.checked),
    };
    setUserInput(input);
    setSubmitted(true);
    window.scrollTo(0, 0);
  }

  // Set document title
  document.title = `Bromsprocent till STH`;
  return (
    <>
      {isSubmitted && (<><Results data={userInput} /><AdComponent /></>)}
      <section className="card">
        <h1 className="mb-0">Bromsprocent till STH</h1>
        <p className="text-xs mt-1 mb-3">Skriv in dina tåguppgifter nedanför, endast rena siffror.</p>

        <form onSubmit={submitHandler} className="flex flex-col">
          <label className="block font-semibold" htmlFor="">
            Bromsprocent:
          </label>
          <input
            type="number"
            name="brakepercent"
            id="brakepercent"
            placeholder="Bromsprocent (endast siffror)"
            ref={brakepercentRef}
            required
          />
          <label className="block font-semibold" htmlFor="">
            Tåglängd:
          </label>
          <input
            type="number"
            name="trainlength"
            id="trainlength"
            placeholder="Tåglängd i meter (endast siffror)"
            ref={lengthRef}
            required
          />
          <div className="flex items-center mx-3 mt-3 w-full">
            <input type="checkbox" name="epBrake" id="epBrake" ref={epBrakeRef} />
            <label htmlFor="epBrake" className="ml-2 font-semibold">
              EP-broms eller motsvarande
            </label>
          </div>
          <button type="submit" className="">
            Räkna ut!
          </button>
        </form>
      </section>
      <AdComponent />
    </>
  );
}
