import React from "react";

export default function BrakeData({ res }) {
  return (
    <section className="card">
      <h2 className="mb-0">Bromsdata</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0 md:gap-4 text-center">
          <div>
            <h3 className="text-lg md:text-xl font-semibold">Retardationstal</h3>
            <p>
              {(res.deceleration / 100).toLocaleString("sv-SE", {
                minimumFractionDigits: 2,
              })}{" "}
              m/s<sup>2</sup>
            </p>
          </div>
          <div>
            <h3 className="text-lg md:text-xl font-semibold">Bromsprocent</h3>
            <p>{res.brakepercent} %</p>
          </div>
          <div>
            <h3 className="text-lg md:text-xl font-semibold">Längd</h3>
            <p>{res.trainlength} m</p>
          </div>
          <div>
            <h3 className="text-lg md:text-xl font-semibold">EP-broms</h3>
            <p>{res.epBrake ? "Ja" : "Nej"}</p>
          </div>
        </div>
    </section>
  );
}
