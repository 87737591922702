// Set base URL for the API
export const BASEURL = "https://utf.nu/api";
export const TRV_APIURL = "https://api.trafikinfo.trafikverket.se/v2/data.json";

export const datetimeOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export function fetchLocationName(locations, signature) {
  let name;
  if (locations) {
    try {
      name = locations.find((loc) => loc.locationSignature === signature);
    } catch (error) {
      console.error(error);
    }
  }

  if (name !== undefined) {
    return name.advertisedLocationName;
  } else {
    return signature;
  }
}

export function getCurrentTrainState(
  advertisedTimeAtLocation,
  timeAtLocation,
  technicalTimeAtLocation = null
) {
  let advertised;
  let actual = new Date(timeAtLocation);
  let prefix = "";

  if (
    technicalTimeAtLocation !== null ||
    advertisedTimeAtLocation !== technicalTimeAtLocation
  ) {
    advertised = new Date(advertisedTimeAtLocation);
  } else {
    advertised = new Date(technicalTimeAtLocation);
  }
  let minutes = Math.floor((actual - advertised) / 1000 / 60);
  let textColor;

  if (minutes > 0 && minutes < 5) {
    textColor = "orange";
  } else if (minutes >= 5) {
    textColor = "red";
  } else if (minutes < -3) {
    textColor = "#2291FF";
  } else {
    textColor = "#008041";
  }

  if (minutes > 0) {
    prefix = "+";
  }

  let output = {
    minutes: minutes,
    isDelayed: minutes > 0 ? true : false,
    prefix: prefix,
    textColor: textColor,
  };

  return output;
}

export function makeId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function saveLocationList(locations) {
  try {
    localStorage.setItem("locationList", JSON.stringify(locations));
    localStorage.setItem("locationListAge", new Date().getTime());
  } catch (error) {
    console.error(error);
  }
}

export function getLocationList() {
  try {
    return localStorage.getItem("locationList");
  } catch (error) {
    console.error(error);
  }
}

export function saveFilter(show) {
  try {
    localStorage.setItem("showAllTrainMessages", show);
  } catch (error) {
    console.error(error);
  }
}

export function getFilter() {
  try {
    return localStorage.getItem("showAllTrainMessages");
  } catch (error) {
    console.error(error);
  }
}

export function saveBetaMessageStatus(show) {
  try {
    localStorage.setItem("showBetaMessage", show);
  } catch (error) {
    console.error(error);
  }
}

export function getBetaMessageStatus() {
  try {
    return localStorage.getItem("showBetaMessage");
  } catch (error) {
    console.error(error);
  }
}

export function saveThemeMode(mode) {
  try {
    window.localStorage.setItem("theme",mode);
  } catch (error) {
    console.error(error);
  }
}

export function getThemeMode() {
  try {
    return window.localStorage.getItem("theme")
  } catch (error) {
    console.error(error);
  }
}

export const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

export const fullDateTimeOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export const shortDateTimeOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}

export const fullTimeOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export const timeOptions = {
  hour: "numeric",
  minute: "numeric",
};

export function getShortTime(dateTimeString) {
  return new Intl.DateTimeFormat("sv-SE", timeOptions).format(new Date(dateTimeString));
}

export function getLongTime(dateTimeString) {
  return new Intl.DateTimeFormat("sv-SE", fullTimeOptions).format(new Date(dateTimeString));
}

export function getDateFormat(dateTimeString) {
  return new Intl.DateTimeFormat("sv-SE", dateOptions).format(new Date(dateTimeString));
}

export function getLongDateFormat(dateTimeString) {
  return new Intl.DateTimeFormat("sv-SE", fullDateTimeOptions).format(new Date(dateTimeString));
}

export function getShortDateFormat(dateTimeString) {
  return new Intl.DateTimeFormat("sv-SE", shortDateTimeOptions).format(new Date(dateTimeString));
}

// Converting POINT string to latitude/longitude
export function convertWgs84(input) {
  if (input !== undefined) {
    const point = input?.split(" ");
    const lng = parseFloat(point[1].substring(1));
    const lat = parseFloat(point[2]);

    return { lat: lat, lng: lng };
  }
  return null;
}

// Getting middle of two lat/lng points
export function getMiddlePoint(fromLatLng, toLatLng) {
  const middleLat = (fromLatLng.lat + toLatLng.lat) / 2;
  const middleLng = (fromLatLng.lng + toLatLng.lng) / 2;
  return { lat: middleLat, lng: middleLng };
}

// Calculating distance in meters
// Source: https://www.movable-type.co.uk/scripts/latlong.html
export function getDistance(fromLatLng, toLatLng) {
  const R = 6371e3; // metres
  const fi1 = (fromLatLng.lat * Math.PI) / 180; // fi, lambda in radians
  const fi2 = (toLatLng.lat * Math.PI) / 180;
  const deltaFi = ((toLatLng.lat - fromLatLng.lat) * Math.PI) / 180;
  const deltaLambda = ((toLatLng.lng - fromLatLng.lng) * Math.PI) / 180;

  const a = Math.sin(deltaFi / 2) * Math.sin(deltaFi / 2) + Math.cos(fi1) * Math.cos(fi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres

  //console.log(d);
  return d;
}

// Converts from degrees to radians.
function toRadians(degrees) {
  return degrees * Math.PI / 180;
};
 
// Converts from radians to degrees.
function toDegrees(radians) {
  return radians * 180 / Math.PI;
}


export function getBearing(fromLatLng, toLatLng){
  if (!fromLatLng || !toLatLng) {
    return null;
  }
  const startLat = toRadians(fromLatLng.lat);
  const startLng = toRadians(fromLatLng.lng);
  const destLat = toRadians(toLatLng.lat);
  const destLng = toRadians(toLatLng.lng);

  const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  const x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  let brng = Math.atan2(y, x);
  brng = toDegrees(brng);
  return (brng + 360) % 360;
}
