import React from "react";
import { getLongTime } from "../Utils/Common";

export default function TrainStatus({ trainStatus }) {
  return (
    <>
      {trainStatus?.activity && (
        <>
          <p className="m-2 text-sm sm:text-base font-semibold" style={{ color: trainStatus?.textColor }}>
            {trainStatus?.activity === "Ankomst" ? "Ankom " : "Avgick "}
            {trainStatus?.locationName ? trainStatus?.locationName : trainStatus?.location}{" "}
            {trainStatus?.locationName && `(${trainStatus?.location})`} kl {getLongTime(trainStatus.timeAtLocation)} (
            {trainStatus?.prefix}
            {trainStatus?.minutes})
          </p>
        </>
      )}
    </>
  );
}
