import { getCurrentTrainState } from "./Common";

export class TrainSchedule {
  constructor() {
    this.AdvertisedTrainIdent = null;
    this.FromLocation = null;
    this.LocationSignature = null;
    this.ToLocation = null;
    this.ArrivalData = {};
    this.DepartureData = {};
    this.ArrivalState = {};
    this.DepartureState = {};
  }
}

export function scheduleCleanup(json) {
  let data = json["RESPONSE"]["RESULT"][0]["TrainAnnouncement"];
  // Initiera variabler och lista över slutprodukt
  let previousLocation;
  let output = [];

  // Iterera input och placera i object
  let ts;
  for (let i in data) {
    if (previousLocation !== data[i].LocationSignature) {
      ts = new TrainSchedule();
      ts.AdvertisedTrainIdent = data[i].AdvertisedTrainIdent;
      ts.LocationSignature = data[i].LocationSignature;
      if (data[i].FromLocation || data[i].ToLocation) {
        ts.FromLocation = data[i].FromLocation[0].LocationName;
        ts.ToLocation = data[i].ToLocation[0].LocationName;
      }
      previousLocation = data[i].LocationSignature;

      if (data[i].ActivityType === "Ankomst") {
        ts.ArrivalData = data[i];
        if (data[i].TimeAtLocation) {
          ts.ArrivalState = getCurrentTrainState(
            data[i].AdvertisedTimeAtLocation,
            data[i].TimeAtLocation,
            data[i].TechnicalDateTime
          );
        }
      } else {
        ts.DepartureData = data[i];
        if (data[i].TimeAtLocation) {
          ts.DepartureState = getCurrentTrainState(
            data[i].AdvertisedTimeAtLocation,
            data[i].TimeAtLocation,
            data[i].TechnicalDateTime
          );
        }
      }
      output.push(ts);
    } else {
      ts.AdvertisedTrainIdent = data[i].AdvertisedTrainIdent;
      ts.LocationSignature = data[i].LocationSignature;
      if (data[i].FromLocation || data[i].ToLocation) {
        ts.FromLocation = data[i].FromLocation[0].LocationName;
        ts.ToLocation = data[i].ToLocation[0].LocationName;
      }
      previousLocation = data[i].LocationSignature;
      if (data[i].ActivityType === "Avgang") {
        ts.DepartureData = data[i];
        if (data[i].TimeAtLocation) {
          ts.DepartureState = getCurrentTrainState(
            data[i].AdvertisedTimeAtLocation,
            data[i].TimeAtLocation,
            data[i].TechnicalDateTime
          );
        }
        continue;
      }
      output.push(ts);
    }
  }

  // Returnera listan
  return output;
}
