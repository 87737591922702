function BrakePercent(trainWeight, brakeWeight) {
    // Formel för uträkning av bromsprocent = bromsvikt*100 / tågvikt
    let calcBrakePercent = (brakeWeight * 100) / trainWeight;

    // Konvertera resultatet utav uträkningen till enkel int.
    let brakePercent = Math.floor(calcBrakePercent);

    return brakePercent;
}

export default BrakePercent;