import React from "react";
import { Link } from "react-router-dom";
import { getLongTime } from "../Utils/Common";
import LastUpdateInfo from "./LastUpdateInfo";

export default function TrainScheduleTable({ trainId, trainSchedule }) {
  return (
    <section className="card overflow-auto">
      <h3 className="mt-0 mb-3">Tidtabell {trainId ? " tåg " + trainId : ""}</h3>
      <table className="table-auto text-xs md:text-sm xl:text-base">
        <thead>
          <tr className="">
            <th className="p-1">Plats</th>
            <th className="p-1">Spår</th>
            <th className="p-1">Ank.</th>
            <th className="p-1">Avg.</th>
            <th className="p-1">Avvikelse</th>
          </tr>
        </thead>
        <tbody>
          {trainSchedule?.map((row, index) => (
            <tr className="text-xs md:text-base" key={index}>
              <td className="p-1 whitespace-nowrap">
                <div>
                  {row.LocationName !== null && row.LocationName !== undefined ? (
                    <>
                      <Link to={`/station/${row.LocationSignature}`}>
                        <span
                          style={{
                            fontWeight: row.ArrivalData.Advertised || row.DepartureData.Advertised ? "600" : "normal",
                          }}
                        >
                          {row.LocationName}
                        </span>
                      </Link>
                      <br />
                      <small className="text-muted text-gray-400 dark:text-gray-500">{row.LocationSignature}</small>
                    </>
                  ) : (
                    <>
                      <Link to={`/station/${row.LocationSignature}`}>{row.LocationSignature}</Link>
                    </>
                  )}
                </div>
              </td>
              <td className="p-1">
                <div style={{ fontWeight: "bold" }}>
                  {row.DepartureData?.TrackAtLocation &&
                  row.DepartureData?.TrackAtLocation !== "x" &&
                  row.DepartureData?.TrackAtLocation !== "-"
                    ? row.DepartureData?.TrackAtLocation
                    : row.ArrivalData?.TrackAtLocation}
                </div>
              </td>
              <td className="p-1 whitespace-nowrap">
                <div
                  style={{
                    fontWeight: row.ArrivalData?.Advertised ? "600" : "normal",
                    textDecoration: row.ArrivalData?.Canceled || row.ArrivalData?.EstimatedTimeAtLocation ? "line-through" : "none",
                  }}
                >
                  {row.ArrivalData?.AdvertisedTimeAtLocation && (
                    <>{getLongTime(row.ArrivalData?.AdvertisedTimeAtLocation)}</>
                  )}
                </div>
                <div style={{ fontWeight: "600", fontStyle: "italic" }}>
                  {row.ArrivalData?.EstimatedTimeAtLocation && (
                    <>{getLongTime(row.ArrivalData?.EstimatedTimeAtLocation)}</>
                  )}
                </div>
                <div style={{ fontWeight: "100", fontStyle: "italic" }}>
                  {row.ArrivalData?.TimeAtLocationWithSeconds
                    ? getLongTime(row.ArrivalData?.TimeAtLocationWithSeconds)
                    : ""}
                  {row.ArrivalState?.activity && (
                    <>
                      {" "}
                      <span style={{ color: row.ArrivalState?.textColor }}>
                        ({row.ArrivalState?.prefix}
                        {row.ArrivalState?.minutes})
                      </span>
                    </>
                  )}
                </div>
              </td>
              <td className="p-1 whitespace-nowrap">
                <div
                  style={{
                    fontWeight: row.DepartureData?.Advertised ? "600" : "normal",
                    textDecoration: row.DepartureData?.Canceled || row.DepartureData?.EstimatedTimeAtLocation ? "line-through" : "none",
                  }}
                >
                  {row.DepartureData?.AdvertisedTimeAtLocation && (
                    <>{getLongTime(row.DepartureData?.AdvertisedTimeAtLocation)}</>
                  )}
                </div>
                <div style={{ fontWeight: "600", fontStyle: "italic" }}>
                  {row.DepartureData?.EstimatedTimeAtLocation && (
                    <>{getLongTime(row.DepartureData?.EstimatedTimeAtLocation)}</>
                  )}
                </div>
                <div style={{ fontWeight: "100", fontStyle: "italic" }}>
                  {row.DepartureData?.TimeAtLocationWithSeconds ? (
                    <>{getLongTime(row.DepartureData?.TimeAtLocationWithSeconds)}</>
                  ) : (
                    ""
                  )}
                  {row.DepartureState?.activity && (
                    <>
                      {" "}
                      <span style={{ color: row.DepartureState?.textColor }}>
                        ({row.DepartureState?.prefix}
                        {row.DepartureState?.minutes})
                      </span>
                    </>
                  )}
                </div>
              </td>
              <td className="p-1">
                {Array.from(row?.Deviations).map((deviation) => (
                  <div key={Math.random()}>{deviation}</div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <LastUpdateInfo dateTime={new Date()} />
    </section>
  );
}
