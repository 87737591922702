import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <p className="text-center text-tiny">
        Sammanställt och kodat utav&nbsp;
        <a href="https://ewenson.se">Joakim Ewenson</a> 2008-2024
      </p>
    </footer>
  );
}
